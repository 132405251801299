import { btnColor, btnSize, btnType, btnVariant } from '../utils/constant'

export const btnConstantContainer = () => {
  const { outlined, contained } = btnVariant

  const { cancel, button, submit, tooltipBtn, move } = btnType

  const { large, small, medium } = btnSize

  const { primary, secondary } = btnColor

  return {
    outlined,
    contained,
    cancel,
    button,
    large,
    medium,
    small,
    submit,
    primary,
    secondary,
    tooltipBtn,
    move,
  }
}
