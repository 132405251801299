import React from 'react'

import { userManualContainer } from '../container/userManual.container'
import PageTitle from '../shared/PageTitle'
import RPCTypography from '../shared/RPCTypography'

const UserManual = () => {
  userManualContainer()
  return (
    <>
      <PageTitle title="User manual" />
      <RPCTypography>There is not any manual added yet</RPCTypography>
    </>
  )
}

export default UserManual
