import { SET_TAB_DESCRIPTION } from '../constants'

export default (state = {}, { type, payload }) => {
  switch (type) {
    case SET_TAB_DESCRIPTION:
      return { ...state, ...payload }

    default:
      return state
  }
}
