import { useDispatch as RCPDispatch } from 'react-redux'

export const useDispatch = () => {
  const dispatchAction = RCPDispatch()

  const dispatch = (params) => {
    dispatchAction(params)
  }
  return { dispatch }
}
