import { useState } from 'react'
import { useSelector } from 'react-redux'

import { downloadLoaderPath as downloadPath } from '../../description/workOpportunities/workOpportunities.description'
import { useDispatch } from '../../hooks/use-dispatch'
import { downloadAction } from '../../redux/actions/api.action'
import { downloadData } from '../../redux/description'
import {
  equal,
  getCurrentDate,
  keys,
  length,
  takeSubString,
  ternary,
} from '../../utils/javascript'
import { showToast } from '../../utils/toastService'

export const exportButtonContainer = ({
  downloadLoaderPath = { ...downloadPath, childObject: 'bookings' },
  active,
  endPoint,
  filename,
  showDropdown,
}) => {
  const { parent, child, childObject } = downloadLoaderPath
  const { dispatch } = useDispatch()
  const [isOpen, toggle] = useState(null)

  const activeTab = useSelector((state) => state.tab?.[parent]?.[child]?.active)

  const getChild = {
    bookings: 'detailedChange',
    plannings: 'plannings',
  }

  const filterChip = useSelector(
    (state) =>
      state.table?.filterData?.[parent]?.filterChip?.[getChild[activeTab]] ||
      state.table?.filterData?.[parent]?.filterChip?.[activeTab] ||
      state.table?.filterData?.[parent]?.filterChip,
  )
  const downloadLoading = useSelector(
    (state) => state.api?.loader?.[parent]?.download,
  )

  const searchValues = useSelector(
    (state) => state?.table?.search?.[parent]?.[child]?.searchValue,
  )
  const TOAST_DURATION = 10000
  const uuid = useSelector(
    (state) => state?.form?.formData?.optimization?.optimization?.optimization,
  )
  const handleDownload = (
    value,
    exportBy = '',
    showStartedToast = false,
    acceptJson = false,
  ) => {
    let suffix = ''
    if (exportBy === 'booking_period') {
      suffix = '_detailed'
    }
    if (exportBy === 'booking') {
      suffix = '_aggregate'
    }
    if (showStartedToast) {
      showToast('Download started', 'success', TOAST_DURATION)
    }

    let payload = {
      query: JSON.stringify(
        ternary(length(keys(filterChip)), { ...filterChip }, {}),
      ),
    }

    let type = `text/${value}`
    let customAccept
    let isDownloadJSON
    let fileType
    if (acceptJson) {
      payload = {
        ...ternary(length(keys(filterChip)), { ...filterChip }),
      }

      type = 'application/json'
      customAccept = 'application/json'
      isDownloadJSON = false
      fileType = `text/${value}`
    }

    toggle()
    dispatch(
      downloadAction({
        apiDetails: downloadData(
          value,
          searchValues,
          showDropdown && !equal(uuid, 'livePlan') && uuid,
          toggle,
          endPoint || childObject,
          active,
          exportBy,
          acceptJson,
        ),
        isReport: true,
        loaderPath: { ...downloadLoaderPath, childObject: value },
        filename: `${ternary(
          showDropdown && !equal(uuid, 'livePlan'),
          `${takeSubString(uuid, uuid?.lastIndexOf('-') + 1)}_`,
          ``,
        )}${filename || active || endPoint || 'All'}${suffix}-${getCurrentDate(
          'DD-MM-YYYY-HH-mm',
        )}`,
        type,
        customAccept,
        ...(equal(value, 'xlsx') && { responseType: 'arraybuffer' }),
        payload,
        isDownloadJSON,
        fileType,
      }),
    )
  }
  return {
    isOpen,
    toggle,
    handleDownload,
    downloadLoading,
  }
}
