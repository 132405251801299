import classNames from 'classnames'
import React from 'react'

import HeaderSwitch from '../presentation/launchPad/HeaderSwitch'
import { BASE_EXPORT_OPTIONS } from '../utils/constant'
import useStyles from './css/pageTitle.style'
import RPCTypography from './RPCTypography'
import ExportButton from './table/ExportButton'

const PageTitle = ({
  children,
  title,
  removeMargin = false,
  downloadLoaderPath,
  exportBtn = false,
  exportOptions = BASE_EXPORT_OPTIONS,
  active,
  endPoint,
  filename,
  showSwitch,
  exportBtnDisable,
  exportBtnTooltip,
  showDropdown,
  showDownloadToast = false,
  acceptJson = false,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <div
      className={classNames(classes.pageTitleContainer, {
        [classes?.removeMargin]: removeMargin,
      })}
    >
      <RPCTypography variant="h1" component="h1" className={classes.typo}>
        {title}
        {exportBtn && (
          <ExportButton
            {...{
              downloadLoaderPath,
              active,
              endPoint,
              filename,
              exportBtnDisable,
              exportBtnTooltip,
              showDropdown,
              exportOptions,
              showToast: showDownloadToast,
              acceptJson,
            }}
          />
        )}
      </RPCTypography>
      {showSwitch && (
        <HeaderSwitch
          {...{
            ...rest,
          }}
        />
      )}
      {children}
    </div>
  )
}

export default React.memo(PageTitle)
