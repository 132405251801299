import classNames from 'classnames'
import React from 'react'

import { dotContainer } from '../container/dot.container'

const RPCDot = ({ color, size }) => {
  const { classes, theme } = dotContainer()
  return (
    <div
      className={classNames(classes.dotBase, {
        [classes.dotLarge]: size === 'large',
        [classes.dotSmall]: size === 'small',
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
      }}
    />
  )
}

export default RPCDot
