import { Button, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  btnStyle: {
    fontSize: '0.9rem',
    borderRadius: 100,
    transition:
      'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    textTransform: 'none',
    '&:hover': {
      transform: 'translateY(-3px)',
      backgroundColor: theme.palette.primary.main,
      boxShadow:
        '0 0.313rem 0.8rem rgb(122 123 151 / 50%), 0 0.126rem 0.225rem rgb(122 123 151 / 30%)',
    },
    '&:not(.Mui-disabled) .MuiButton-label': {
      color: 'white',
      transition: 'all .3s ease-in-out',
    },
    '&.MuiButton-outlined': {
      '&:not(.Mui-disabled) .MuiButton-label': {
        color: theme.palette.primary.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: '#fff',
        },
      },
    },
    '&.MuiButton-colorInherit': {
      '& .MuiButton-label': {
        color: '#000',
      },
      '&:hover .MuiButton-label': {
        color: '#fff',
      },
    },
    '& .MuiCircularProgress-root': {
      opacity: 0,
      position: 'absolute !important',
      transition:
        'all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    },
  },
  isLoader: {
    paddingRight: 40,
    '& .MuiCircularProgress-root': {
      opacity: 1,
      right: 15,
    },
  },
}))

const RPCButton = ({
  children,
  className,
  applyClass = true,
  isLoading,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Button
      {...(applyClass && {
        className: classNames(classes.btnStyle, className, {
          [classes.isLoader]: isLoading,
        }),
      })}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default React.memo(RPCButton)
