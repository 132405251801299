import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React from 'react'

import { ternary } from '../utils/javascript'
const useStyles = makeStyles(() => ({
  a: {
    textDecoration: 'none',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}))

const RPCLink = ({
  href,
  linkName,
  className,
  children,
  isBlank = false,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <a
      href={href}
      target={ternary(isBlank, '_blank', '_self')}
      className={classNames(classes.a, classes.cursorPointer, className)}
      {...rest}
    >
      {linkName && <span className="align-middle mr-2">{linkName}</span>}
      {children}
    </a>
  )
}

export default RPCLink
