import classNames from 'classnames'
import React from 'react'

import RPCSwitch from '../../shared/RPCSwitch'
import { counts, hours } from '../../utils/constant'
import useStyles from '../datasets/css/launchpad.style'

const HeaderSwitch = ({ isLoading, dataLength, isHourFlag, handleFlag }) => {
  const classes = useStyles()
  return (
    <span className={classNames(classes.countSwitch, classes.textRight)}>
      {counts}
      <RPCSwitch
        disabled={isLoading || !dataLength}
        checked={isHourFlag || false}
        onChange={handleFlag}
        classes={classes}
      />
      {hours}
    </span>
  )
}
export default HeaderSwitch
