import { options } from './options.description'

export const actions = {
  name: 'actions',
  label: 'Actions',
  ...options({
    filter: false,
    sort: false,
    fixColumns: true,
    display: true,
    viewColumns: false,
    hasSuggestions: false,
  }),
}

export const acceptanceStateColumn = {
  name: 'acceptanceState',
  label: 'Acceptance State',
  filterName: 'Acceptance state is',
  viewNotConfigurable: true,
  type: 'multiselect',
  ...options({
    filterType: 'multiselect',
    filter: true,
    hasFilterOptions: false,
    customRender: true,
    display: true,
    viewColumns: false,
    hasSuggestions: true,
    sort: false,
    fixColumns: true,
  }),
}
