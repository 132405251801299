import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  formWrap: {
    [theme.breakpoints.down('1280')]: {
      padding: [[0, 0]],
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 950,
    margin: '0 auto',
  },
  labelTable: {
    width: '100%',
    '& .MuiTableCell-root': {
      padding: 8,
      fontSize: 14,
      wordBreak: 'break-all',
    },
    '& .ScrollbarsCustom-Content': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiGrid-root': {
      padding: 0,
      margin: 0,
      width: '100%',
    },
    '& .MuiInput-underline:before': {
      content: 'normal',
      width: '100%',
    },
    '& .MuiInput-underline:after': {
      width: '100%',
    },
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        '& .MuiTableCell-root': {
          position: 'sticky',
          top: -1,
          backgroundColor: '#fff',
          zIndex: 9,
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiFormControl-root': {
            paddingRight: '20px !important',
            maxWidth: 450,
          },
        },
      },
    },
  },
  colorPicker: {
    height: '100%',
    '& > div': {
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      '& .ColorPicker-MuiButton-root,& .MuiButtonBase-root': {
        margin: 0,
        width: '100%',
        height: '100%',
        minWidth: 'auto',
        '& div': {
          width: '100%',
          height: '100%',
          minWidth: 'auto',
        },
      },
    },
  },
  formButtons: {
    '& button + button': {
      marginLeft: 10,
    },
  },
  modalMainTitle: {
    fontSize: 26,
    marginBottom: 0,
    lineHeight: 'normal',
    '& button': {
      position: 'absolute',
      right: 5,
      top: 5,
    },
  },
  tableContainer: {
    boxShadow: 'unset',
    '& .MuiTable-root': {
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            width: '15%',
            '&:first-child': {
              width: '20%',
            },
            '&:nth-child(2)': {
              paddingLeft: '8px !important',
              paddingRight: '8px !important',
            },
            '&:last-child': {
              textAlign: 'right',
              '& .MuiButtonBase-root': {
                [theme.breakpoints.down('1199')]: {
                  padding: [[8, 10]],
                  fontSize: 12,
                },
              },
            },
          },
        },
      },
    },
  },
  labelsSearch: {
    paddingTop: 10,
    '& button:hover': {
      color: theme.palette.primary.main,
    },
  },
  searchWrapper: {
    position: 'relative',
  },
  searchBox: {
    position: 'absolute',
    left: 0,
    top: -22,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    transition: 'all 0.1s ease-in-out',
    transform: 'scale(0.8)',
    opacity: 0,
  },
  searchVisible: {
    opacity: 1,
    transform: 'scale(1)',
  },
  dialogClass: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.17)',
    },
  },
  colorBlock: {
    width: 20,
    height: 20,
    display: 'inline-block',
    marginRight: 10,
  },
  searchAnimation: {
    width: '70%',
    transition:
      'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  searchIcon: {
    verticalAlign: 'middle',
    color: 'rgb(128 128 128)',
  },
  tableTitle: {
    fontSize: '1.142rem',
    fontWeight: 500,
    lineHeight: 1.6,
  },
  addFormButton: {
    '& .Mui-expanded': {
      justifyContent: 'space-between',
      marginTop: 12,
      marginBottom: 12,
    },
  },
  chipStyle: {
    '& .MuiButtonBase-root': {
      width: 20,
      height: 20,
      borderRadius: 5,
      minWidth: 'auto',
      margin: [[4, 5]],
    },
  },
  labelPoppver: {
    maxWidth: 350,
    width: '100%',
    marginTop: 40,
    marginLeft: -20,
    '& .MuiPopover-paper': {
      maxWidth: '100%',
      width: '100%',
      '& .MuiMenu-list': {
        padding: 10,
      },
    },
  },
  marginTop: {
    maxWidth: '540px',
    marginTop: 35,
  },
  colorButtonStyle: {
    minWidth: 'auto !important',
    borderRadius: '5px !important',
  },
  chipSize: {
    height: '20px',
    width: '20px',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  colorPickerMenu: {
    maxWidth: 250,
    width: '100%',
    '& .MuiList-root': {
      padding: 5,
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      '& span': {
        width: '12.5%',
        float: 'left',
        fontWeight: 600,
        fontSize: 14,
      },
    },
  },
  linkAction: {
    '& span': {
      opacity: 0,
      visibility: 'hidden',
      marginLeft: -10,
      transition: 'all 0.2s ease-in-out',
      '& svg': {
        width: 12,
        height: 12,
      },
    },
    '&:hover': {
      '& span': {
        opacity: 1,
        visibility: 'visible',
        marginLeft: 10,
      },
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  nonClickable: {
    cursor: 'not-allowed',
  },
  lastRow: {
    paddingBottom: 0,
    border: 'transparent',
  },
}))
