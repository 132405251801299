import React from 'react'

import RPCTable from '../../shared/RPCTable'
import RPCTableBody from '../../shared/RPCTableBody'
import RPCTableCell from '../../shared/RPCTableCell'
import RPCTableRow from '../../shared/RPCTableRow'
import { useStyles } from './css/tooltipTable.style'

const TooltipTable = ({ data }) => {
  const classes = useStyles()
  return (
    <RPCTable>
      <RPCTableBody>
        {data?.map(({ type, description }, i) => (
          <RPCTableRow className={classes.tooltipRow} key={i}>
            {type && (
              <RPCTableCell className={classes.tooltipCell}>
                {type}
              </RPCTableCell>
            )}
            <RPCTableCell className={classes.tooltipCell}>
              {description}
            </RPCTableCell>
          </RPCTableRow>
        ))}
      </RPCTableBody>
    </RPCTable>
  )
}
export default TooltipTable
