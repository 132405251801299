import { numberPostFix, startYear } from './constant'
import { equal, isEmptyString, lowerCase, ternary } from './javascript'

export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(lowerCase(email))
}

export const confirmPassword = (confirmPass, pass) => equal(confirmPass, pass)

export const alphabeticStringValidation = (val) => {
  const regex = /^[a-zA-Z0-9@äëïöüÿÄËÏÖÜŸ)/(._-\s]+$/
  return regex.test(val)
}

export const oneCharacterAlphabeticStringValidation = (val) => {
  if (!notEmpty(val)) {
    return false
  }
  if (val?.length !== 1) {
    return false
  }
  const regex = /^[a-z]+$/i
  return regex.test(val)
}

export const positiveNumber = (val, min, max) => {
  if (
    (!isEmptyString(min) || !isEmptyString(max)) &&
    (+val < min || +val > max)
  ) {
    return false
  }
  if (isEmptyString(val)) {
    return false
  }
  return val >= 0
}

export const alphaNumericValidation = (val) => {
  const regex = /^[A-Z0-9]{6}$/
  return regex.test(val)
}

export const number = (val) => {
  const regex = /^[0-9]+$/
  return regex.test(val)
}

export const mobileNumber = (val) => {
  const regex = /^[0-9]+$/
  return regex.test(val) && val.toString().length === 10
}

export const notEmpty = (val) => {
  const regex = /[^\s]$/
  return ternary(val, regex.test(val), false)
}
export const checkNotEmptyWithSpace = (val) => {
  const regex = /[^\s]$/
  return val ? regex.test(val.replace(/^\s+|\s+$/gm, '')) : false
}

export const checkNotEmptyWithSpaceMaxLength = (val, min = 0, max = 30) => {
  if (val?.length < min || val?.length > max) {
    return false
  }
  const regex = /[^\s]$/
  return val ? regex.test(val.replace(/^\s+|\s+$/gm, '')) : false
}

export const validateYearString = (value) =>
  alphaNumericValidation(value) ? value.includes(startYear) : false

export const isDate = (date) => {
  const regExp = [
    /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+$/,
  ]
  return regExp[0].test(date) || regExp[1].test(date)
}

export const zipCodeValidation = (value) => {
  const regExp = /^[0-9]+$/
  return regExp.test(value)
}

export const isNumber = (val) => /^\d+$/.test(val)

export const checkNumberWithPostfix = (val) =>
  ternary(number(val), val.includes(numberPostFix), false)
