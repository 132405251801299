import { ReactComponent as Aspaara } from '../assets/svg/aspaaraIcon.svg'
import { ReactComponent as LinkedIn } from '../assets/svg/linkedin.svg'
import { ReactComponent as Twitter } from '../assets/svg/twitter.svg'

export const footerData = [
  {
    link: 'https://twitter.com/aspaara',
    name: 'twitter',
    icon: <Twitter />,
  },
  {
    link: 'https://www.linkedin.com/company/aspaara-ag',
    name: 'linkedin',
    icon: <LinkedIn />,
  },
  {
    link: 'https://www.aspaara.com',
    name: 'website',
    icon: <Aspaara />,
  },
]
