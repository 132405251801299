export default {
  palette: {
    primary: {
      main: '#ff7400',
      // light: '#ffb972',
      // dark: '#c65915',
      // contrastText: '#424242',
    },
    secondary: {
      main: '#434342',
      // light: '#79797a',
      // dark: '#252526',
      // contrastText: '#FFFFFF',
    },
    error: {
      main: '#dd3b4e',
    },
    warning: {
      main: '#ff7400',
    },
    info: {
      main: '#4d83b5',
    },
    success: {
      main: '#59c8b1',
      dark: '#388e3d',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
}
