import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import { ACCEPTED, REJECTED } from '../utils/constant'
import RPCButton from './RPCButton'
import RPCLoader from './RPCLoader'

const RPCConfirm = ({
  open,
  title,
  description,
  onClick,
  isLoading = false,
  handleClose,
  dialogClass,
  rejectBtnTitle = 'No',
  acceptBtnTitle = 'Yes',
  handleAcceptReject = null,
}) => {
  const Loader = () => <RPCLoader size={15} isBtnLoader />
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classNames(dialogClass)}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <RPCButton
          variant="contained"
          onClick={() =>
            handleAcceptReject ? handleAcceptReject(REJECTED) : handleClose()
          }
          color="inherit"
          disableElevation
        >
          {rejectBtnTitle}
        </RPCButton>
        <RPCButton
          variant="contained"
          isLoading={isLoading}
          autoFocus
          onClick={() =>
            handleAcceptReject ? handleAcceptReject(ACCEPTED) : onClick()
          }
          color="primary"
          disableElevation
          disabled={isLoading}
        >
          {acceptBtnTitle}
          <Loader />
        </RPCButton>
      </DialogActions>
    </Dialog>
  )
}

export default RPCConfirm
