import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  checkOptimizationStatus,
  launchpadGrade,
  launchpadLabels,
  missionFormPath,
  missionOperatingUnit,
  missionTemplateFormPath,
} from '../../description/formPath.description'
import { useDispatch } from '../../hooks/use-dispatch'
import { getApiDataAction } from '../../redux/actions/api.action'
import { clearForm } from '../../redux/actions/form.action'
import {
  checkOptimizationUpload,
  filterDetail,
  getGrades,
  getMinMaxDates,
  labelApiDetails,
  operatingUnit,
  optimizationTemplates,
} from '../../redux/description'
import { length } from '../../utils/javascript'

export const apiCallsContainer = (localKey) => {
  const { dispatch } = useDispatch()
  const templates = useSelector((state) => state.api?.mission?.templates)
  const labels = useSelector((state) => state.api?.mission?.labels)
  const minMaxDate = useSelector((state) => state.api?.mission?.minMaxDate)
  const grades = useSelector((state) => state.api?.mission?.grades)
  const operatingUnitSelector = useSelector(
    (state) => state.api?.mission?.operatingUnit,
  )

  useEffect(() => {
    let formPath = {
      parent: missionFormPath,
      child: missionTemplateFormPath,
    }

    const labelFormPath = {
      parent: missionFormPath,
      child: launchpadLabels,
    }
    dispatch(clearForm({ parent: 'mission' }))

    dispatch(
      getApiDataAction({
        formPath: { ...formPath, child: checkOptimizationStatus },
        apiDetails: checkOptimizationUpload,
      }),
    )

    if (!templates) {
      dispatch(
        getApiDataAction({ formPath, apiDetails: optimizationTemplates }),
      )
    }
    if (!labels) {
      dispatch(
        getApiDataAction({
          formPath: labelFormPath,
          apiDetails: labelApiDetails,
        }),
      )
    }
    if (!length(operatingUnitSelector)) {
      formPath = { ...formPath, child: missionOperatingUnit }
      dispatch(getApiDataAction({ formPath, apiDetails: operatingUnit }))
    }
    if (!length(grades)) {
      formPath = {
        ...formPath,
        child: launchpadGrade,
      }
      dispatch(getApiDataAction({ formPath, apiDetails: getGrades }))
    }
    if (!minMaxDate) {
      dispatch(
        getApiDataAction({
          formPath: {
            parent: missionFormPath,
            child: 'minMaxDate',
          },
          apiDetails: getMinMaxDates(localKey),
        }),
      )
    }

    dispatch(
      getApiDataAction({
        formPath: { ...formPath, child: 'talentCriteria' },
        apiDetails: filterDetail({
          active: 'employees',
          endPoint: 'datasets',
        }),
      }),
    )
    dispatch(
      getApiDataAction({
        formPath: { ...formPath, child: 'woCriteria' },
        apiDetails: filterDetail({
          active: 'bookings',
          endPoint: 'datasets',
        }),
      }),
    )
  }, [])
}
