import {
  SET_DETAIL_GRAPH_DATA,
  SET_GRAPH_ADD_MODAL_OPEN,
  SET_GRAPH_CHECK_FLAGS,
  SET_GRAPH_DELETE_BOOKING_MODAL_OPEN,
  SET_GRAPH_DELETE_MODAL_OPEN,
  SET_GRAPH_EDIT_HOURS_MODAL_OPEN,
  SET_GRAPH_EDIT_MODAL_OPEN,
  SET_GRAPH_MOVE_MODAL_OPEN,
  SET_GRAPH_PERIOD,
  SET_GRAPH_VIEW_FLAGS,
  SET_IS_MOVE_MODE,
} from '../constants'

const jobDetails = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_DETAIL_GRAPH_DATA:
      return { ...state, ...payload }
    case SET_GRAPH_CHECK_FLAGS:
      return { ...state, checkFlags: payload }

    case SET_GRAPH_PERIOD:
      return { ...state, period: payload }

    case SET_GRAPH_VIEW_FLAGS:
      return { ...state, viewFlags: payload }

    case SET_GRAPH_EDIT_MODAL_OPEN:
      return { ...state, editMode: payload }
    case SET_GRAPH_DELETE_MODAL_OPEN:
      return { ...state, isDeleteModalOpen: payload }
    case SET_GRAPH_ADD_MODAL_OPEN:
      return { ...state, isAddModalOpen: payload }
    case SET_GRAPH_EDIT_HOURS_MODAL_OPEN:
      return { ...state, isEditHoursModalOpen: payload }
    case SET_GRAPH_MOVE_MODAL_OPEN:
      return { ...state, isMoveModalOpen: payload }
    case SET_IS_MOVE_MODE:
      return { ...state, isMoveMode: payload }
    case SET_GRAPH_DELETE_BOOKING_MODAL_OPEN:
      return { ...state, isDeleteBookingModalOpen: payload }

    default:
      return state
  }
}
export default jobDetails
