import { Box, Paper } from '@material-ui/core'
import React from 'react'

import { ternary } from '../../utils/javascript'
import RPCLoader from '../RPCLoader'
import RPCTable from '../RPCTable'
import RPCTableBody from '../RPCTableBody'
import RPCTableCell from '../RPCTableCell'
import RPCTableContainer from '../RPCTableContainer'
import RPCTableRow from '../RPCTableRow'
import MuiTableRow from './MuiTableRow'

const MuiCommonTable = ({ rows, isLoading, handleSubmit, classes, errors }) => (
  <>
    <RPCTableContainer
      component={Paper}
      elevation={4}
      className={classes.settingTable}
    >
      <RPCTable aria-label="simple table">
        {rows &&
          ternary(
            isLoading,
            <Box m={2}>
              <RPCLoader {...{ isCenterLoader: true }} />
            </Box>,
            <>
              <RPCTableBody>
                {rows?.map((row, index) => (
                  <RPCTableRow key={index}>
                    <RPCTableCell>{row?.key}</RPCTableCell>
                    {ternary(
                      row?.component,
                      <RPCTableCell>{row?.component}</RPCTableCell>,
                      <RPCTableCell>{row?.value}</RPCTableCell>,
                    )}
                  </RPCTableRow>
                ))}
                {!!handleSubmit && (
                  <MuiTableRow {...{ handleSubmit, classes, errors }} />
                )}
              </RPCTableBody>
            </>,
          )}
      </RPCTable>
    </RPCTableContainer>
  </>
)
export default React.memo(MuiCommonTable)
