import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from '../hooks/use-dispatch'
import { sessionAction } from '../redux/actions/app.action'

export const layoutContainer = () => {
  const { dispatch } = useDispatch()
  const { sidebarOpened, isSessionChecked, isAuth, hasGroup } = useSelector(
    (state) => state.app,
  )
  useEffect(() => {
    dispatch(sessionAction())
  }, [])

  return { sidebarOpened, isSessionChecked, isAuth, hasGroup }
}
