import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  pageTitleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 25,
    justifyContent: 'space-between',
  },
  typo: {
    color: '#000000c2',
    fontSize: '1.6rem',
    fontWeight: '700',
    fontFamily: 'jura',
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: 'none',
    '&:active': {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
  removeMargin: {
    marginBottom: 'unset',
  },
}))
