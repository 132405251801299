import { SET_TAB_DESCRIPTION } from '../constants'

export const setActiveTabAction =
  (formPath, active) => async (dispatch, getState) => {
    const { parent, child } = formPath
    dispatch({
      type: SET_TAB_DESCRIPTION,
      payload: {
        ...getState().tab,
        [parent]: {
          ...getState().tab?.[parent],
          [child]: {
            ...active,
          },
        },
      },
    })
  }
