import {
  BASELINE_BOOKINGS,
  BASELINE_EMPLOYEES,
  defaultTrueValidation,
  FILTERED_BOOKINGS,
  FILTERED_EMPLOYEES,
  fullWidth,
  hasValue,
  KPI_OVERVIEW,
  MATCHING_CORE,
  MAX_LENGTH,
  NOT_EMPTY_WITH_SPACE_MAX_LENGTH,
  notEmptyOrNull,
  notEmptyWithSpace,
  success,
} from '../../utils/constant'
import { ternary } from '../../utils/javascript'
import {
  jobManagers,
  kpiCumulativeDatasetPath,
  kpiDatasetPath,
  kpiOptimizationDatasetPath,
  missionDetails,
  missionDetailsPath,
  missionFormPath,
  missionParamsPath,
  optimizationFormPath,
} from '../formPath.description'
import { actions } from '../table/action.description'
import { options } from '../table/options.description'
export const columns = [
  {
    name: 'name',
    label: 'Optimization Name',
    filterName: 'Optimization Name is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'uuid',
    label: 'Optimization ID',
    filterName: 'Optimization ID is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'description',
    label: 'Description',
    ...options({ filter: false, customRender: true }),
  },
  {
    name: 'optimizationTemplateDescription',
    label: 'Optimization Profile',
    filterName: 'Optimization Profile is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
    }),
  },
  {
    name: 'optimizationFinished',
    label: 'Optimization Finished',
    filterName: 'Optimization Finished is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      display: false,
    }),
  },
  {
    name: 'optimizationSuccessfull',
    label: 'Optimization Successful',
    filterName: 'Optimization Successful is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      display: false,
    }),
  },
  {
    name: 'optimizationStart',
    label: 'Date Created',
    filterName: 'Date Created is',
    ...options({ customRender: true, hasFilterOptions: true }),
  },
  {
    name: 'optimizationFinish',
    label: 'Date Finished',
    filterName: 'Date Finished is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
    }),
  },
  {
    name: 'optimizationStatus',
    label: 'Status',
    filterName: 'Status is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'optimizationStep',
    label: 'Optimization Step',
    filterName: 'Optimization Step is',
    ...options({
      display: false,
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      customBodyRender: (value) =>
        uploadSteps[ternary(value > 0, value - 1, value)],
    }),
  },
  {
    name: 'optimizationLive',
    label: 'Writeback Finish',
    ...options({
      filter: false,
      customRender: true,
      hasFilterOptions: true,
    }),
  },
  {
    name: 'optimizationLiveStatus',
    label: 'Writeback Status',
    filterName: 'Writeback Status is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'createdByUser',
    label: 'Created By',
    filterName: 'Created By is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customBodyRender: (value) =>
        value
          ? `${value?.firstName ?? ''} ${value?.lastName ?? ''}`
          : MATCHING_CORE,
    }),
  },
  { ...actions },
]

export const baselinesColumns = [
  {
    name: 'name',
    label: 'Baseline Name',
    filterName: 'Baseline Name is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'uuid',
    label: 'Baseline ID',
    filterName: 'Baseline ID is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'description',
    label: 'Description',
    ...options({ filter: false, customRender: true }),
  },
  {
    name: 'baselineStart',
    label: 'Date Created',
    filterName: 'Date Created is',
    ...options({ customRender: true, hasFilterOptions: true }),
  },
  {
    name: 'baselineFinish',
    label: 'Date Finished',
    filterName: 'Date Finished is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
    }),
  },
  {
    name: 'baselineStep',
    label: 'Baseline Step',
    filterName: 'Baseline Step is',
    ...options({
      display: false,
      customRender: true,
      hasFilterOptions: false,
      customRenderValue: true,
      customBodyRender: (value) => BASELINE_PROGRESS_STEP[value],
    }),
  },
  {
    name: 'baselineStatus',
    label: 'Status',
    filterName: 'Status is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'createdByUser',
    label: 'Created By',
    filterName: 'Created By is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customBodyRender: (value, metaData) =>
        metaData?.rowData[
          baselinesColumns?.findIndex(
            (col) => col?.name === 'isManuallyCreated',
          )
        ] === true
          ? `${value?.firstName} ${value?.lastName}`
          : MATCHING_CORE,
    }),
  },
  {
    name: 'isManuallyCreated', // This column will not visible on table.
    label: ' ',
    ...options({
      display: false,
      viewColumns: false,
    }),
  },
  { ...actions },
]

export const dCols = JSON.stringify([...columns])

export const columnSorting = {
  name: 'optimizationStart',
  direction: 'desc',
}

const talentCriteria = {
  row2: {
    rowFormMd: 6,
    groupTitle: 'Talent Criteria',
    minHeight: true,
    formPath: {
      parent: missionFormPath,
      child: 'talentCriteria',
    },
    formAttributes: {
      operatingUnit: {
        label: 'Operating Unit',
        type: 'multiSelect',
        isRequired: false,
        fullWidth,
        error: false,
        pattern: hasValue,
        parentPath: missionFormPath,
        selectArray: 'talentCriteria',
        selectArrChild: 'operatingUnit',
        multiple: true,
        defaultValueFromApi: true,
        gridLg: 12,
        gridSm: 12,
        rowFormMd: 6,
      },
      grade: {
        label: 'Grade',
        type: 'multiSelect',
        isRequired: false,
        fullWidth,
        error: false,
        pattern: hasValue,
        multiple: true,
        parentPath: missionFormPath,
        selectArray: 'talentCriteria',
        selectArrChild: 'grade',
        gridLg: 12,
        defaultValueFromApi: true,
        rowFormMd: 6,
        gridSm: 12,
      },
      labels: {
        label: 'Labels',
        type: 'autoComplete',
        isRequired: false,
        fullWidth,
        error: 'false',
        pattern: defaultTrueValidation,
        parentPath: missionFormPath,
        selectArray: 'talentCriteria',
        childObject: 'labels',
        gridLg: 12,
        rowFormMd: 6,
        placeholder: 'Labels',
        limitTags: 3,
        needClass: true,
        arrangeByGroup: true,
        groupBy: 'labelCategory',
        sortValues: true,
        sortKey: 'labelCategory',
        primaryKey: 'labelCategory',
        secondaryKey: 'name',
        renderTag: true,
        gridSm: 12,
        isDisabledWithoutData: true,
        disabledText: 'No labels available',
      },
      officeCity: {
        label: 'Office City',
        type: 'multiSelect',
        isRequired: false,
        fullWidth,
        error: false,
        pattern: notEmptyOrNull,
        parentPath: missionFormPath,
        selectArray: 'talentCriteria',
        selectArrChild: 'officeCity',
        multiple: true,
        gridLg: 12,
        rowFormMd: 6,
        gridSm: 12,
      },
    },
  },
}
export const woCriteria = {
  row3: {
    rowFormMd: 6,
    minHeight: true,
    groupTitle: 'Workopportunity Criteria',
    formPath: {
      parent: missionFormPath,
      child: 'woCriteria',
    },
    formAttributes: {
      operatingUnit: {
        label: 'Operating Unit',
        type: 'multiSelect',
        isRequired: false,
        fullWidth,
        error: false,
        pattern: hasValue,
        parentPath: missionFormPath,
        selectArray: 'woCriteria',
        selectArrChild: 'operatingUnit',
        multiple: true,
        defaultValueFromApi: true,
        gridLg: 12,
        rowFormMd: 6,
        gridSm: 12,
      },
      grade: {
        label: 'Grade',
        type: 'multiSelect',
        isRequired: false,
        fullWidth,
        error: false,
        pattern: hasValue,
        multiple: true,
        parentPath: missionFormPath,
        selectArray: 'woCriteria',
        selectArrChild: 'bookingGrade',
        gridLg: 12,
        defaultValueFromApi: true,
        rowFormMd: 6,
        gridSm: 12,
      },
      labels: {
        label: 'Labels',
        type: 'autoComplete',
        isRequired: false,
        fullWidth,
        error: 'false',
        pattern: defaultTrueValidation,
        parentPath: missionFormPath,
        selectArray: 'woCriteria',
        childObject: 'bookingLabels',
        altChildObject: 'jobLabels',
        uniqueBy: 'id',
        gridLg: 12,
        rowFormMd: 6,
        placeholder: 'Labels',
        limitTags: 3,
        needClass: true,
        arrangeByGroup: true,
        groupBy: 'labelCategory',
        sortValues: true,
        sortKey: 'labelCategory',
        primaryKey: 'labelCategory',
        secondaryKey: 'name',
        renderTag: true,
        gridSm: 12,
        isDisabledWithoutData: true,
        disabledText: 'No labels available',
        tooltipTitle:
          'The selected labels are applied on Job and Work Opportunity level.',
      },
      jobCity: {
        label: 'Job City',
        type: 'multiSelect',
        isRequired: false,
        fullWidth,
        error: false,
        pattern: notEmptyOrNull,
        parentPath: missionFormPath,
        selectArray: 'woCriteria',
        selectArrChild: 'jobCity',
        multiple: true,
        gridLg: 12,
        rowFormMd: 6,
        gridSm: 12,
      },
      bookingStartDate: {
        label: 'Work Opportunity Start Date From',
        type: 'date',
        fullWidth,
        isRequired: false,
        error: [],
        pattern: defaultTrueValidation,
        parentPath: missionFormPath,
        selectVal: 'minMaxDate',
        selectValChild: 'min_date',
        needMinMax: true,
        periodName: 'bookingStartDate',
        gridLg: 6,
        gridSm: 12,
      },
      bookingEndDate: {
        label: 'Work Opportunity Start Date To',
        type: 'date',
        fullWidth,
        isRequired: false,
        error: [],
        pattern: defaultTrueValidation,
        selectValChild: 'max_date',
        parentPath: missionFormPath,
        selectVal: 'minMaxDate',
        needMinMax: true,
        gridLg: 6,
        gridSm: 12,
      },
      onlyUnassigned: {
        label: 'Only Unassigned',
        type: 'switch',
        fullWidth,
        value: false,
        isRequired: false,
        error: [],
        pattern: notEmptyOrNull,
        gridLg: 12,
        rowFormMd: 6,
        gridSm: 12,
      },
    },
  },
}

const optimizationParams = {
  row4: {
    groupTitle: 'Optimization Profile',
    rowFormMd: 12,
    formPath: {
      parent: missionFormPath,
      child: missionParamsPath,
    },
    formAttributes: {
      optimizationTrack: {
        type: 'select',
        isRequired: true,
        fullWidth,
        value: '',
        error: false,
        pattern: notEmptyOrNull,
        label: 'Choose a Profile *',
        selectArray: 'templates',
        parentPath: missionFormPath,
        gridLg: 6,
      },
    },
  },
}

const formAttributes = {
  row1: {
    groupTitle: 'Details',
    rowFormMd: 12,
    formPath: {
      parent: missionFormPath,
      child: missionDetailsPath,
    },
    formAttributes: {
      name: {
        label: 'Name of optimization (max. 30 characters) *',
        type: 'text',
        isRequired: true,
        fullWidth,
        error: [
          'Name is required',
          'Blank space not allowed and name must not be longer than 30 characters',
        ],
        pattern: NOT_EMPTY_WITH_SPACE_MAX_LENGTH,
        min: 0,
        max: 30,
        gridLg: 4,
        gridSm: 6,
      },
      description: {
        label: 'Description (max. 100 characters)',
        type: 'text',
        isRequired: false,
        fullWidth,
        pattern: MAX_LENGTH,
        error: [
          'Description is required',
          'Blank space not allowed and description must not be longer than 100 characters',
        ],
        gridLg: 4,
        gridSm: 6,
      },
      labels: {
        type: 'hidden',
        isRequired: false,
        value: [],
        parentPath: missionFormPath,
        pattern: defaultTrueValidation,
      },
    },
  },
}
const baselineFormAttributes = {
  row1: {
    // groupTitle: 'Details',
    rowFormMd: 12,
    formPath: {
      parent: missionFormPath,
      child: missionDetailsPath,
    },
    formAttributes: {
      name: {
        label: 'Name of baseline (max. 30 characters) *',
        type: 'text',
        isRequired: true,
        fullWidth,
        error: [
          'Name is required',
          'Blank space not allowed and name must not be longer than 30 characters',
        ],
        pattern: NOT_EMPTY_WITH_SPACE_MAX_LENGTH,
        gridLg: 4,
        gridSm: 6,
      },
      description: {
        label: 'Description (max. 100 characters)',
        type: 'text',
        isRequired: false,
        fullWidth,
        pattern: MAX_LENGTH,
        error: [
          'Description is required',
          'Blank space not allowed and description must not be longer than 100 characters',
        ],
        gridLg: 4,
        gridSm: 6,
      },
    },
  },
}
const baselineEditFormAttributes = {
  row1: {
    rowFormMd: 12,
    formPath: {
      parent: missionFormPath,
      child: missionDetailsPath,
    },
    formAttributes: {
      uuid: {
        label: 'Baseline ID',
        type: 'text',
        isRequired: true,
        fullWidth,
        pattern: notEmptyWithSpace,
        gridLg: 4,
        gridSm: 6,
        fieldReadOnly: true,
      },
      name: {
        label: 'Name of baseline *',
        type: 'text',
        isRequired: true,
        fullWidth,
        error: ['Name must required', 'Blank space not allowed'],
        pattern: notEmptyWithSpace,
        gridLg: 4,
        gridSm: 6,
      },
      description: {
        label: 'Description',
        type: 'text',
        isRequired: false,
        fullWidth,
        pattern: defaultTrueValidation,
        error: ['Description must required', 'Blank space not allowed'],
        gridLg: 4,
        gridSm: 6,
      },
    },
  },
}

export const form = [
  {
    type: 'fieldset',
    fields: [formAttributes, talentCriteria, woCriteria, optimizationParams],
  },
]

export const baselineForm = [
  {
    type: 'fieldset',
    fields: [baselineFormAttributes],
  },
]
export const baselineEditForm = [
  {
    type: 'fieldset',
    fields: [baselineEditFormAttributes],
  },
]

export const modalKeys = [
  'name',
  'description',
  'optimizationFinished',
  'optimizationSuccessfull',
  'optimizationStart',
  'optimizationFinish',
  'optimizationTemplateDescription',
  'optimizationStatus',
  'optimizationStep',
]
export const baselineModalKeys = [
  'name',
  'description',
  'baselineStart',
  'baselineFinish',
  'baselineStatus',
  'baselineStep',
]

export const missionPath = {
  parent: missionFormPath,
  child: missionDetails,
}

export const dropDownFormAttributes = (user, group) => [
  {
    type: null,
    fields: [
      {
        default1: {
          formPath: {
            parent: optimizationFormPath,
            child: optimizationFormPath,
          },
          formAttributes: {
            optimization: {
              type: 'select',
              isRequired: false,
              fullWidth: true,
              label: 'Select Plan',
              parentPath: missionFormPath,
              selectArray: missionDetails,
              filterKey: 'optimizationStatus',
              filterValue: success,
              pattern: null,
              defaultValueFromApi: true,
              setFormValues: true,
              tick: true,
              showTickCondition: {
                key: 'optimizationLiveStatus',
                value: success,
              },
              fromLocalStorage: true,
              storageParam: `${user}-${group}-optimization`,
              reverseFilter: true,
              reverseFilterKey: 'optimizationLiveStatus',
              reverseFilterValue: success,
              value: 'livePlan',
            },
          },
        },
      },
    ],
  },
]

export const downloadTitle = 'Download Error logs'

export const uploadSteps = [
  'Submit data to Engine',
  'Analysis of dataset',
  'Phase 1 optimization',
  'Data collection for reports (1/2)',
  'Phase 2 optimization',
  'Data collection for reports (2/2)',
  'Preparation of result',
  'Fetch result from Engine',
]

export const BASELINE_PROGRESS_STEP = [
  'Starting baseline creation',
  'Creating baseline for Jobs',
  'Mapping Job labels to baseline',
  'Creating baseline for Work Opportunities',
  'Mapping Work Opportunity labels to baseline',
  'Creating baseline for Work Opportunity hours',
  'Baseline successfully created',
]

export const staticDatasetCum = {
  included_talents: {
    label: 'Included Talents',
    description: 'About Included Talents',
  },
  included_plannings: {
    label: 'Included Job Hours',
    description: 'About Included Job Hours',
  },
  included_absences: {
    label: 'Included Absence Hours',
    description: 'About Absence Hours',
  },
  included_clients: {
    label: 'Included Clients',
    description: 'About Included Clients',
  },
  included_additional_flags: {
    label: 'Included Additional Flags',
    description: 'About Additional Flags',
  },
  included_fte: {
    label: 'Included FTE (100%)',
    description: 'Total of 100% Included FTE',
  },
}

export const staticCum = {
  ...staticDatasetCum,
  assigned_hours: {
    label: 'Assigned Hours',
    description: 'About maximum utilize',
  },
  generic_hours: {
    label: 'Unassigned Hours',
    description: 'About unassigned hours',
  },
  conflict_hours: {
    label: 'Conflict Hours',
    description: 'About conflict hours',
  },
  mean_travel_time: {
    label: 'Mean Travel Time',
    description: 'About mean travel time',
  },
  median_travel_time: {
    label: 'Median Travel Time',
    description: 'About median travel time',
  },
  new_joiners_portfolios: {
    label: 'New Joiners Portfolios',
    description: 'About new joiner flags',
  },
  avg_hours_new_joiners: {
    label: 'Average Hours',
    description: 'About average hours of new joiners',
  },
  pref_1: { label: 'Preference 1', description: 'About preference 1' },
  pref_2: { label: 'Preference 2', description: 'About preference 2' },
  pref_3: { label: 'Preference 3', description: 'About preference 3' },
  client_continuity: {
    label: 'Client Continuity',
    description: 'About client continuity',
  },
  client_continuity_below_60: {
    label: 'Client Continuity Below 60',
    description: 'About client continuity below 60',
  },
}

export const selectRedux = {
  dataset: kpiDatasetPath,
  optimization: kpiOptimizationDatasetPath,
  job_managers: jobManagers,
}

export const formPath = {
  ...missionPath,
  parent: missionFormPath,
  child: kpiDatasetPath,
}

export const optimizationPath = {
  ...missionPath,
  parent: missionFormPath,
  child: kpiOptimizationDatasetPath,
}

export const cumulativePath = {
  ...missionPath,
  parent: missionFormPath,
  child: kpiCumulativeDatasetPath,
}

export const jobManagePath = {
  ...missionPath,
  parent: missionFormPath,
  child: jobManagers,
}

export const overviewEmployeesPath = {
  ...missionPath,
  parent: KPI_OVERVIEW,
  child: 'employees',
}

export const overviewBookingsPath = {
  ...missionPath,
  parent: KPI_OVERVIEW,
  child: 'bookings',
}

const overviewBaselineEmployeesPath = {
  ...missionPath,
  parent: KPI_OVERVIEW,
  child: BASELINE_EMPLOYEES,
}
const overviewBaselineBookingsPath = {
  ...missionPath,
  parent: KPI_OVERVIEW,
  child: BASELINE_BOOKINGS,
}

const overviewFilteredEmployeesPath = {
  ...missionPath,
  parent: KPI_OVERVIEW,
  child: FILTERED_EMPLOYEES,
}
const overviewFilteredBookingsPath = {
  ...missionPath,
  parent: KPI_OVERVIEW,
  child: FILTERED_BOOKINGS,
}
export const selectFormPath = {
  dataset: formPath,
  optimization: optimizationPath,
  compare: cumulativePath,
  job_managers: jobManagePath,
  overviewEmployees: overviewEmployeesPath,
  overviewBookings: overviewBookingsPath,
  overviewBaselineEmployees: overviewBaselineEmployeesPath,
  overviewBaselineBookings: overviewBaselineBookingsPath,
  overviewFilteredBookings: overviewFilteredBookingsPath,
  overviewFilteredEmployees: overviewFilteredEmployeesPath,
}
export const deleteOptimizationMessage =
  'Are you sure you want to delete optimization?'

export const deleteBaselineMessage = 'Are you sure you want to delete baseline?'

export const confirmMessageTitle =
  'Do you want to accept these optimization results?'

export const confirmMessageDescription =
  'Note: All other pending optimizations will be removed'

export const alreadyMarkedMsg = 'Accepted'

export const markLive = 'Pending'

export const liveBtnTitle = 'Pending'

export const livedBtnTitle = 'Accepted'

export const markingLiveBtnTitle = 'In Progress'

export const setLiveData = 'setLiveData'

export const statusColor = {
  pending: '#3C678E',
  inprogress: '#E6E6E6',
  accepted: '#50AC9A',
  partiallySuccessful: '#FFA366',
}

export const modStatus = {
  null: 'pending',
  INPROGRESS: 'inprogress',
  SUCCESSFUL: 'accepted',
  PARTIALLY_SUCCESSFUL: 'partiallySuccessful',
}

export const tooltipDesc = {
  pending: 'This plan is not yet accepted',
  accepted: 'Changes of this plan are accepted and written to live data',
}

export const FILTER_KPIS = 'Filter KPIs'

export const BASELINES = 'baselineDetails'

export const tabs = [
  { label: 'Pending optimizations', value: 'pending' },
  { label: 'Submitted optimizations', value: 'accepted' },
  { label: 'Baselines', value: BASELINES },
]

export const formAttr = {
  default: {
    formPath: {
      parent: missionFormPath,
      child: kpiCumulativeDatasetPath,
    },
  },
}

export const tableTitle = 'Optimization List'

export const baselineTableTitle = 'Baseline List'

export const baselineAutoDeleteWarning =
  'Automatic baselines are deleted after one month'

export const getCustomFilterNames = [
  {
    name: 'optimizationStart',
    type: 'dateRange',
  },
  {
    name: 'optimizationFinish',
    type: 'dateRange',
  },
  {
    name: 'optimizationLiveStatus',
    type: 'dropdown',
    takeFromData: true,
  },
]

export const defaultColumns = [
  'optimizationFinished',
  'optimizationSuccessfull',
]

export const bodyText = 'No optimization found'
export const baselineBodyText = 'No baseline found'

export const bodyTextWithTip =
  'No optimization found. Tip: Hidden columns are not searched, show them to include them in search.'

export const baselineBodyTextWithTip =
  'No baseline found. Tip: Hidden columns are not searched, show them to include them in search.'

export const notFormFields = ['description']

export const sameValueKeys = ['pending', 'accepted']
export const boolKeys = ['optimizationFinished', 'optimizationSuccessfull']

export const localKey = 'mission-control'
export const baselineLocalKey = 'baseline-mission-control'

export const textForAllowMaxOptLimit =
  'You have reached the maximum number of in-progress optimizations. Please wait until one finishes to start another one.'

export const textForAllowMaxPendingOptLimit =
  'You have reached the maximum number of pending optimizations. Please submit or delete a pending optimization to start another one.'

export const textForAllowMaxBaselineLimit =
  'You have reached the maximum of 10 manual baselines. Please delete older baselines to create another one.'
