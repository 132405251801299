import { useEffect } from 'react'

import { formPath } from '../description/userManual.description'
import { useDispatch } from '../hooks/use-dispatch'
import { getApiDataAction } from '../redux/actions/api.action'
import { userManualDetails } from '../redux/description'

export const userManualContainer = () => {
  const { dispatch } = useDispatch()
  useEffect(() => {
    dispatch(
      getApiDataAction({
        formPath,
        apiDetails: userManualDetails,
        loaderFormPath: formPath,
      }),
    )
  }, [])
}
