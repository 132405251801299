import React from 'react'

import navigationLogo from '../../../assets/svg/matchingcore_logo_negative.svg'
import RPCImage from '../../../shared/RPCImage'
import RPCTypography from '../../../shared/RPCTypography'
import HeaderMenuIcon from './HeaderMenuIcon'

const Header = ({ classes, hasGroup }) => (
  <>
    {hasGroup && <HeaderMenuIcon {...{ classes }} />}
    <RPCImage
      src={navigationLogo}
      alt="logo"
      extraClassName={classes.headerLogo}
    />
    <RPCTypography variant="h6" weight="medium" className={classes.logotype}>
      Control
    </RPCTypography>
    <div className={classes.grow} />
  </>
)

export default React.memo(Header)
