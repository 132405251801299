import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  tooltipRow: {
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
  tooltipCell: {
    color: '#fff',
    fontSize: 12,
    padding: 10,
  },
}))
