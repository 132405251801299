import { getBeforeBookings } from '../../redux/description'
import {
  doveGray,
  downy,
  error,
  flamenco,
  steelBlue,
  white,
} from '../../utils/constant'
import { customRoundForHeatmap, equal } from '../../utils/javascript'
import { launchpadTalents, missionFormPath } from '../formPath.description'
import { options } from '../table/options.description'

export const columns = [
  {
    name: 'id',
    label: 'ID',
    filterName: 'ID: ',
    ...options({
      filter: false,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'originalId',
    label: 'Original ID',
    filterName: 'Original ID is',
    ...options({
      display: false,
      customRender: true,
      isConvert: false,
    }),
  },
  {
    name: 'name',
    label: 'Name',
    filterName: 'Name is',
    ...options({
      customRender: true,
      isConvert: false,
    }),
  },
  {
    name: 'grade',
    label: 'Grade',
    filterName: 'Grade is',
    ...options({
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isConvert: false,
    }),
  },

  {
    name: 'operatingUnit',
    label: 'Operating Unit',
    ...options({
      display: true,
      filter: true,
    }),
  },
  {
    name: 'officeCity',
    label: 'Office City',
    filterName: 'Office City is',
    ...options({
      filterType: 'multiselect',
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'officeZIP',
    label: 'Office Postal Code',
    filterName: 'Office Postal Code is',
    ...options({
      filterType: 'multiselect',
      display: false,
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      isConvert: false,
    }),
  },
  {
    name: 'homeCity',
    label: 'Home City',
    filterName: 'Home City is',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      filterType: 'multiselect',
    }),
  },
  {
    name: 'homeZIP',
    label: 'Home Postal Code',
    filterName: 'Home Postal Code is',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      isConvert: false,
      display: false,
    }),
  },
  {
    name: 'timeFte',
    label: 'FTE (%)',
    filterName: 'FTE (%) ',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      isConvert: false,
    }),
  },
  {
    name: 'timeModel',
    label: 'Time Model',
    filterName: 'Time Model is',
    ...options({
      hasFilterOptions: true,
      filter: false,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'entryDate',
    label: 'Start Date',
    filterName: 'Start Date ',
    type: 'dateRange',
    ...options({
      hasFilterOptions: true,
      display: false,
    }),
  },
  {
    name: 'leaveDate',
    label: 'Leave Date',
    filterName: 'Leave Date ',
    type: 'dateRange',
    ...options({
      hasFilterOptions: true,
      display: false,
    }),
  },
  {
    name: 'originalHours',
    label: 'Original Hours',
    type: 'range',
    filterName: 'Original Hours ',
    ...options({
      customRender: true,
    }),
  },
  {
    name: 'optimizedHours',
    label: 'Suggested Hours',
    filterName: 'Suggested Hours ',
    type: 'range',
    ...options({
      customRender: true,
    }),
  },
  {
    name: 'originalJobCount',
    label: 'Current Job Count',
    filterName: 'Original Job Count ',
    type: 'range',
    ...options({
      customRender: true,
    }),
  },
  {
    name: 'optimizedJobCount',
    label: 'Optimized Job Count',
    filterName: 'Optimized Job Count ',
    type: 'range',
    ...options({
      customRender: true,
    }),
  },
  {
    name: 'newJoiner',
    label: 'Is New Joiner',
    filterName: 'Is New Joiner is',
    ...options({
      hasFilterOptions: true,
      customRender: true,
    }),
  },
  {
    name: 'isGeneric',
    label: 'Is Generic',
    filterName: 'Is Generic = ',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'isManuallyAdded',
    label: 'Is Manually Added',
    filterName: 'Is Manually Added is',
    ...options({
      display: false,
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'exclude',
    label: 'Is Excluded',
    filterName: 'Is Excluded = ',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'labels',
    label: 'Labels',
    filterName: 'Label is',
    ...options({
      sort: false,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isTag: true,
    }),
  },
  {
    name: 'reservePool',
    label: 'Reserve Pool',
    filterName: 'Reserve Pool = ',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      display: false,
    }),
  },
]

export const columnSorting = {
  name: 'name',
  direction: 'asc',
}
const indexToCalendarWeek = (index) => index

export const graphOptions = (key, total) => ({
  chart: {
    type: 'heatmap',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 50,
    },
  },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 0,
            name: ' ',
            color: white,
          },
          {
            from: 0.1,
            to: 30.99,
            name: '1-30h',
            color: steelBlue,
          },
          {
            from: 31,
            to: 40.99,
            name: '31-40h',
            color: downy,
          },
          {
            from: 41,
            to: 50.99,
            name: '41-50h',
            color: flamenco,
          },
          {
            from: 51,
            to: 70.99,
            name: '51-70h',
            color: doveGray,
          },
          {
            from: 71,
            to: total,
            name: '71h+',
            color: error,
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
  title: {
    text: ` ${
      key === 'before' ? 'Current' : 'Suggested'
    } Workload by Calendar Week`,
  },
  xaxis: {
    labels: {
      formatter: indexToCalendarWeek,
    },
  },
  legend: {
    showForSingleSeries: true,
  },
  yaxis: {
    labels: {
      minWidth: 300,
      style: {
        cssClass: 'cursorPointer',
      },
    },
    axisTicks: {
      show: true,
    },
  },
  tooltip: {
    y: {
      formatter: (val) => `${customRoundForHeatmap(val.toFixed(2))}h`,
      title: {
        formatter: (_, data) => {
          const labelData =
            data?.w?.config?.series[data?.seriesIndex]?.labelData
          const name = data?.w?.config?.series[data?.seriesIndex]?.name
          const tooltipTitle =
            labelData?.isGeneric && !equal(name?.toString(), 'Total')
              ? [
                  labelData?.isGeneric ? 'Generic' : null,
                  labelData?.grade?.name,
                  labelData?.operatingUnit,
                ]
                  .filter(Boolean)
                  .join(', ')
              : name
          return `${tooltipTitle}:`
        },
      },
    },
  },
})

export const expandRowDescription = {
  graphSelectorPath: {
    parent: missionFormPath,
    child: 'missionBooking',
  },
  urlRedirection: launchpadTalents,
  rowChild: 'talent',
  apiDetails: getBeforeBookings,
  graphOptions,
  rowSelectorPath: {
    parent: 'mission',
    child: 'missionTalents',
  },
  callAfter: true,
  graphPath: { parent: 'mission', child: 'missionBooking' },
}

export const getCustomFilterNames = [
  {
    name: 'operatingUnit',
    type: 'multiselect',
  },
  {
    name: 'originalHours',
    type: 'range',
  },
  {
    name: 'optimizedJobCount',
    type: 'range',
  },
  {
    name: 'optimizedHours',
    type: 'range',
  },
  {
    name: 'originalJobCount',
    type: 'range',
  },
  {
    name: 'timeFte',
    type: 'range',
  },
  {
    name: 'entryDate',
    type: 'dateRange',
  },
  {
    name: 'leaveDate',
    type: 'dateRange',
  },
  {
    name: 'officeZIP',
    type: 'multiselect',
  },
  {
    name: 'grade',
    type: 'multiselect',
  },
  {
    name: 'officeCity',
    type: 'multiselect',
  },
  {
    name: 'name',
    type: 'multiselect',
  },
  {
    name: 'labels',
    type: 'autoComplete',
  },
  {
    name: 'homeZIP',
    type: 'multiselect',
  },
  {
    name: 'homeCity',
    type: 'multiselect',
  },
  {
    name: 'originalId',
    type: 'multiselect',
  },
]

export const pieMockData = [
  {
    title: 'Operating Unit',
    key: 'operatingUnit',
    color: '#f56e00',
    hasInfo: false,
    needChart: true,
    lg: 3,
    loaderKey: 'operating_units',
  },
  {
    title: 'Grade',
    key: 'grade',
    color: '#f56e00',
    hasInfo: false,
    needChart: true,
    lg: 3,
    loaderKey: 'grades',
  },
  {
    title: 'FTE (%)',
    key: 'timeFte',
    color: '#f56e00',
    hasInfo: false,
    description: `Talent's office city`,
    needChart: true,
    lg: 3,
    loaderKey: 'fte',
  },
  {
    title: 'Label Count',
    key: 'labels',
    needDropdown: true,
    needChart: false,
    group: true,
    groupingKey: 'label_category',
    lg: 3,
    selectArr: 'talent',
    formField: 'category',
    isClear: false,
    formPath: {
      parent: 'mission',
      child: 'talentLabel',
    },
    formAttributes: {
      label: 'Category',
      isRequired: true,
      fullWidth: true,
      pattern: 'notEmptyOrNull',
      type: 'select',
      parentPath: 'mission',
      selectArray: 'missionTalents',
      nestingChild: 'labels',
      mapKey: 'label_category',
      needUnique: true,
    },
    loaderKey: 'labels',
  },
]

export const flagCountTitle = {
  count: 'Total Count',
  hours: 'Total Hours',
}

export const totalTitle = 'Included'

export const tableTitle = 'Talent Reporting View'

export const defaultColumns = [
  'isGeneric',
  'exclude',
  'isManuallyAdded',
  'newJoiner',
  'reservePool',
]
