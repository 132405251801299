import { TableCell } from '@material-ui/core'
import React from 'react'

import { OBJECT } from '../utils/constant'
import { typeOf } from '../utils/javascript'

const oldRender = TableCell.render

TableCell.render = (...args) => {
  const [props, ...otherArgs] = args
  if (typeOf(props, OBJECT) && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
  }
  return oldRender.apply(this, args)
}
const RPCTableCell = ({ children, ...props }) => (
  <TableCell {...{ ...props }}>{children}</TableCell>
)

export default RPCTableCell
