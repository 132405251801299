import React from 'react'

import NotFound from './NotFound'

const PermissionDenied = () => {
  const url = window.location.search

  const params = new URLSearchParams(url)

  const errorMsg = params.get('error')

  const messageContent =
    errorMsg || `Please contact support@aspaara.com to access the page.`
  return (
    <NotFound
      {...{
        notFoundMessage: 'Permission denied',
        subMessage: [`You don't have permission.`, messageContent],
        needButton: false,
        fontBold: true,
      }}
    />
  )
}

export default PermissionDenied
