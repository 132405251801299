import { Box, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import { settingsPath } from '../../description/formPath.description'
import { buttonAttributes } from '../../description/localization.description'
import { useDispatch } from '../../hooks/use-dispatch'
import { setDisableAction } from '../../redux/actions/form.action'
import ButtonMapping from '../ButtonMapping'

const MuiTableRow = ({ handleSubmit, classes, errors }) => {
  const { dispatch } = useDispatch()

  const isEnable = useSelector(
    (state) => state?.form?.fieldDisabled?.[settingsPath]?.locale,
  )
  const handleClick = () => {
    dispatch(
      setDisableAction({
        [settingsPath]: { locale: !isEnable, optimizationSettings: !isEnable },
      }),
    )
  }
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Box textAlign="right">
          <ButtonMapping
            buttonAttributes={buttonAttributes(
              handleClick,
              handleSubmit,
              !isEnable,
              classes,
              errors,
            )}
          />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default MuiTableRow
