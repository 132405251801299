import { useSelector } from 'react-redux'

import { useDispatch } from '../../hooks/use-dispatch'
import { sidebarOpenedAction } from '../../redux/actions/app.action'

export const headerContainer = () => {
  const sidebarOpened = useSelector((state) => state.app.sidebarOpened)
  const { dispatch } = useDispatch()

  const changeSidebarOpened = () => dispatch(sidebarOpenedAction())

  return {
    changeSidebarOpened,
    sidebarOpened,
  }
}
