import { Chip, Menu } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'

import { palette } from '../description/labels/label.description'
import { uid } from '../utils/constant'
import { equal, gt, length, ternary } from '../utils/javascript'
import useStyles from './css/labelChip.style'
import RPCTooltip from './elements/RPCTooltip'
import RPCButton from './RPCButton'

const RenderLabels = ({
  value,
  classes,
  chipCounts = 2,
  showTooltip = false,
}) => {
  const labelClasses = useStyles()
  const [toggleOpen, setToggleOpen] = useState(null)

  const handleClick = (event) => {
    setToggleOpen(event.currentTarget)
  }

  const chip = ({ name, color, labelCategory }) => (
    <span key={uid()}>
      <Chip
        className={classNames(classes?.chip, {
          [classes?.chipMargin]: gt(length(value), 1),
        })}
        style={{
          background: color || labelCategory?.color || '#ffe2ca',
          color:
            palette?.find(({ bgColor }) =>
              equal(bgColor, color || labelCategory?.color),
            )?.color || '#fff',
        }}
        label={name}
      />
    </span>
  )

  const chipDetail = (data) =>
    data?.map(({ name, color, labelCategory }) =>
      ternary(
        showTooltip,
        <RPCTooltip
          title={name}
          options={{ placement: 'bottom' }}
          classes={{ popper: classes.tooltipPaper }}
        >
          {chip({ name, color, labelCategory })}
        </RPCTooltip>,
        chip({ name, color, labelCategory }),
      ),
    )

  return (
    <>
      {chipDetail(value?.slice(0, chipCounts))}
      {gt(length(value), chipCounts) && (
        <RPCButton className={labelClasses.blueColor} onClick={handleClick}>
          {`+${length(value) - chipCounts}`}
        </RPCButton>
      )}

      <Menu
        anchorEl={toggleOpen}
        keepMounted
        open={Boolean(toggleOpen)}
        onClose={() => setToggleOpen(null)}
        className={labelClasses.labelPoppver}
        classes={{
          paper: labelClasses.colorPickerMenu,
        }}
      >
        {chipDetail(value?.slice(chipCounts))}
      </Menu>
    </>
  )
}
export default RenderLabels
