import { Helmet } from 'react-helmet'

const Title = ({ title, content }) => (
  <Helmet>
    <title>{title} - MatchingCore Console</title>
    <meta name="description" content={content} />
  </Helmet>
)

export default Title
