import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  mainContent: {
    width: '100%',
    minHeight: '100vh',
    '& > .ScrollbarsCustom': {
      '& > .ScrollbarsCustom-Track': {
        top: '64px !important',
      },
    },
    '& .ScrollbarsCustom-Track': {
      width: '4px !important',
      backgroundColor: 'rgba(0,0,0,0) !important',
      '& .ScrollbarsCustom-Thumb': {
        backgroundColor: '#f98e35 !important',
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  contentShift: {
    width: `100%`,
    minHeight: '100vh',
    overflowX: 'auto',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    minHeight: '30px',
  },
  copyright: {
    marginBottom: 0,
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  vfill: {
    flexGrow: 1,
  },
}))
