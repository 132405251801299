import { failed, partiallySuccess } from '../../utils/constant'
import {
  launchpadFormPath,
  launchpadHistoryTableFormPath,
  launchpadImportFormPath,
  launchpadImportHistoryTableChildFormPath,
  launchpadImportHistoryTableFormPath,
  talentDatasets,
} from '../formPath.description'
import { actions } from '../table/action.description'
import { options } from '../table/options.description'
import { TALENT, WORKOPPORTUNITY } from './launchpad.description'

export const columns = [
  {
    name: 'id',
    label: 'ID',
    ...options({ filter: false, display: false }),
  },
  {
    name: 'uuid',
    label: 'UUID',
    ...options({ filter: false, display: false, sort: false }),
  },
  {
    name: 'type',
    label: 'Name',
    ...options({
      sort: false,
      filter: false,
      customRender: true,
    }),
  },
  {
    name: 'importFinish',
    label: 'Last Successful Import',
    ...options({
      sort: false,
      filter: false,
      customRender: true,
    }),
  },
  {
    name: 'importStatus',
    label: 'Last Import Status',
    ...options({
      sort: false,
      filter: false,
      customRender: true,
    }),
  },
  {
    name: 'progressLog',
    label: 'Progress',
    showProgress: true,
    ...options({
      sort: false,
      filter: false,
    }),
  },
  {
    name: 'progressLog',
    label: 'Current Progress Message',
    showSteps: true,
    ...options({
      sort: false,
      filter: false,
      customRender: true,
      isConvert: false,
      needSteps: true,
    }),
  },
  {
    name: 'createdByUser',
    label: 'Imported by',
    ...options({
      sort: false,
      filter: false,
      customRender: true,
      customRenderValue: true,
      customBodyRender: (value) =>
        value?.firstName && value?.lastName
          ? `${value?.firstName} ${value?.lastName}`
          : '-',
    }),
  },
  { ...actions },
]

export const integrationColumns = [
  {
    name: 'id',
    label: 'ID',
    ...options({ filter: false, display: false }),
  },
  {
    name: 'uuid',
    label: 'UUID',
    ...options({ filter: false, display: false, sort: false }),
  },
  {
    name: 'type',
    label: 'Name',
    ...options({
      sort: false,
      filter: false,
      customRender: true,
    }),
  },
  {
    name: 'importFinish',
    label: 'Last Successful Sync',
    ...options({
      sort: false,
      filter: false,
      customRender: true,
    }),
  },
  {
    name: 'importStatus',
    label: 'Last Sync Status',
    ...options({
      sort: false,
      filter: false,
      customRender: true,
    }),
  },
  { ...actions },
]

export const staticData = [
  {
    id: null,
    type: TALENT,
    importStart: null,
    importFinish: null,
    importStatus: null,
  },
  {
    id: null,
    type: WORKOPPORTUNITY,
    importStart: null,
    importFinish: null,
    importStatus: null,
  },
]

export const steps = [
  'Upload data',
  'Map fields',
  'Map labels',
  'Validate & import',
]

export const removeElements = ['file', 'type']

export const datasetsPath = {
  parent: launchpadFormPath,
  child: 'datasets',
}

export const datasetListPath = {
  parent: launchpadFormPath,
  child: 'datasetList',
}

export const stepPath = {
  parent: launchpadFormPath,
  child: 'step',
}

export const datasetWizardPath = { parent: 'launchpad', child: 'datasetWizard' }

export const ddFormPath = {
  parent: 'launchpad',
  child: 'column_mappings',
}

export const defaultFormPath = {
  parent: 'launchpad',
  child: 'column_defaults',
}

export const validationFormPath = {
  parent: 'launchpad',
  child: 'datasetValidation',
}

export const stepTwoPath = {
  parent: 'launchpad',
  child: 'stepTwo',
}
export const stepThreePath = {
  parent: 'launchpad',
  child: 'stepThree',
}

export const DEFAULTS = 'Defaults'

export const VALIDATING = 'Validating...'

export const IMPORTING = 'Importing...'

export const ValidationTitle = 'Validation'

export const recordSize = 3

export const formPath = {
  parent: launchpadFormPath,
  child: launchpadFormPath,
}

export const importHistoryPath = {
  parent: launchpadFormPath,
  child: launchpadImportFormPath,
}

export const importHistoryTablePath = {
  parent: launchpadHistoryTableFormPath,
  child: launchpadImportHistoryTableFormPath,
  childObject: launchpadImportHistoryTableChildFormPath,
}

export const modalTitle = 'Data import details'

export const importStatusConstant = 'importStatus'

export const importStepConstant = 'importStep'

export const titleType = {
  WORKOPPORTUNITY: 'Work Opportunity',
  TALENT: 'Talent',
}

export const datasetFormPath = {
  parent: launchpadFormPath,
  child: talentDatasets,
}

export const dsTypes = [TALENT, WORKOPPORTUNITY]

export const tooltipMsg = {
  [failed]: 'Download error logs',
  [partiallySuccess]: 'Download ignored bookings',
}
