import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  started: {
    color: '#a4a4a4',
  },
  successful: {
    color: theme.palette.success.main,
  },
  inprogress: {
    color: theme.palette.warning.main,
    whiteSpace: 'nowrap',
  },
  failed: {
    color: theme.palette.error.main,
  },
  chipMargin: {
    marginRight: 5,
    marginBottom: 5,
  },
  chip: {
    borderRadius: '3px !important',
  },
  partially_successful: {
    color: '#FFA366',
    margin: 'auto 0',
  },
  originalId: {},
}))
export default useStyles
