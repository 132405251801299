import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SET_BULK_DATA } from '../../redux/constants'
import { ACCEPTANCE_STATE_ENUM } from '../../utils/constant'
import { equal, length } from '../../utils/javascript'
import RPCButton from '../RPCButton'
import RPCTableCell from '../RPCTableCell'
import SuggestionHeader from '../SuggestionHeader'
const useStyle = makeStyles(() => ({
  customTableHeader: {
    top: 0,
    zIndex: 101,
    position: 'sticky',
    backgroundColor: '#fff',
    '& button': {
      textTransform: 'capitalize',
    },
    left: '0px',
    right: '0px',
    padding: 10,
  },
}))

const TableHeaderWithSwitch = ({ index, sortColumn, sort }) => {
  const classes = useStyle()

  const viewedRowsAcceptanceState =
    useSelector(
      (state) => state?.api?.mission?.workOpportunities?.detailedChange?.items,
    ) || []
  const checkAcceptanceState = (array) => {
    const trueCount = array.filter((item) =>
      equal(item.acceptanceState, true),
    ).length
    const falseCount = array.filter((item) =>
      equal(item.acceptanceState, false),
    ).length

    if (equal(trueCount, length(array))) {
      return true
    }
    if (equal(falseCount, length(array))) {
      return false
    }
    return null
  }

  const setBulkData = (formPath, bulkData) => async (dispatch, getState) => {
    const { parent, childObject } = formPath
    dispatch({
      type: SET_BULK_DATA,
      payload: {
        ...getState().table?.bulkData,
        [parent]: childObject
          ? {
              ...getState().table?.bulkData?.[parent],
              [childObject]: bulkData,
            }
          : bulkData,
      },
    })
  }
  let startInterval = false
  let storeBulkObject = {}
  const dispatch = useDispatch()
  useEffect(() => {
    let interval = null
    interval = setInterval(() => {
      if (startInterval) {
        dispatch(setBulkData({ parent: 'acceptanceState' }, storeBulkObject))
        startInterval = !startInterval
      }
    }, 100)
    return () => {
      clearInterval(interval)
    }
  }, [storeBulkObject])

  const SuggestionHeaderCom = ({
    checkAcceptanceState,
    viewedRowsAcceptanceState,
  }) => (
    <SuggestionHeader
      acceptanceState={checkAcceptanceState(viewedRowsAcceptanceState)}
      onChange={(value) => {
        storeBulkObject = {
          acceptanceState: ACCEPTANCE_STATE_ENUM[value],

          isModal: false,
        }
        startInterval = !startInterval
      }}
    />
  )

  return (
    <RPCTableCell key={index} className={classNames(classes.customTableHeader)}>
      {sort ? (
        <RPCButton
          {...{ ...(sort && { onClick: () => sortColumn(index) }) }}
          applyClass={false}
        >
          <SuggestionHeaderCom
            {...{
              checkAcceptanceState,
              viewedRowsAcceptanceState,
              storeBulkObject,
              startInterval,
            }}
          />
        </RPCButton>
      ) : (
        <SuggestionHeaderCom
          {...{
            checkAcceptanceState,
            viewedRowsAcceptanceState,
          }}
        />
      )}
    </RPCTableCell>
  )
}

export default TableHeaderWithSwitch
