import './App.css'

import { makeStyles } from '@material-ui/core'
import { memo } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router } from 'react-router-dom'

import Layout from './presentation/layout'
import { appContainer } from './shared/container/app.container'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#fafafa',
    minHeight: '100vh',
  },
}))

const App = () => {
  const classes = useStyles()
  appContainer()
  return (
    <Router>
      <div className={classes.root}>
        <Layout />
        <Toaster position="top-right" />
      </div>
    </Router>
  )
}

export default memo(App)
