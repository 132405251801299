import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { acceptanceStateLabels } from '../utils/constant'
import { suggestionHeaderContainer } from './container/suggestionHeader.container'
import AcceptanceDots from './elements/AcceptanceDots'
import RPCConfirm from './RPCConfirm'

const useSuggestionHeaderStyles = (thumbColor) =>
  makeStyles(() => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    input: {
      '-webkit-appearance': 'none',
      padding: '0 2px',
      width: '100%',
      minWidth: '35px',
      maxWidth: '35px',
      height: '18px',
      outline: 'none',
      borderRadius: '12px',
      background: thumbColor,
      '&::-webkit-slider-thumb': {
        '-webkit-appearance': 'none',
        width: '12px',
        height: '12px',
        marginTop: '0',
        background: 'white',
        borderRadius: '50%',
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      },
      '&::-moz-range-thumb': {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      },
      '&::-moz-range-track': {
        borderRadius: '4px',
      },
    },
  }))

const SuggestionHeader = ({ acceptanceState = null, onChange }) => {
  const {
    mapRowValueToPosition,
    colors,
    handleConfirmClick,
    openConfirmModal,
    setConfirm,
    open,
    modalActionIndex,
  } = suggestionHeaderContainer(onChange)
  const classes = useSuggestionHeaderStyles(
    colors[mapRowValueToPosition(acceptanceState)],
  )()
  const confirmMessageTitle = `Bulk Action:  ${acceptanceStateLabels[modalActionIndex]}`

  const confirmMessageDescription = `This action will apply to all items included in the current filter`
  return (
    <>
      <div className={classes.wrapper}>
        <AcceptanceDots {...{ classes, openConfirmModal }} isHeader />
      </div>
      <RPCConfirm
        {...{
          open,
          handleClose: setConfirm,
          onClick: handleConfirmClick,
          title: confirmMessageTitle,
          description: confirmMessageDescription,
        }}
      />
    </>
  )
}
export default SuggestionHeader
