import {
  assignedHours,
  assignedTitle,
  emptyValue,
  grade,
  included,
  nullEmptyValues,
  operatingUnit,
  reqHrs,
  topLocation,
  TOTAL_HOURS,
  unassignedHours,
} from '../utils/constant'
import { head, ternary } from '../utils/javascript'
import { minusSymbol } from '../utils/symbols'

const tooltipWithPercentage = ({
  color,
  label,
  data,
  percentage,
}) => `<div style="width:100%; padding: 6px 8px 6px 6px;">
  <div style="height: 13px; width:13px; border-radius: 100%; margin: 0 6px; display: inline-block; background-color:${color};" >
  </div>
  <span>${ternary(
    nullEmptyValues.includes(label),
    emptyValue,
    label,
  )} : ${data} (${percentage} %)
  </span>
</div>`

export const graphColors = [
  '#ff7400',
  '#f18b37',
  '#e5a672',
  '#e5c0a1',
  '#ebd8c9',
  '#d5cec8',
  '#bfbbb9',
  '#a1a1a1',
  '#8b8b8b',
  '#7a7979',
]

export const graphOptions = {
  colors: graphColors,
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  legend: {
    show: false,
  },
  theme: {
    monochrome: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: false,
        },
      },
    },
  ],
  tooltip: {
    fillSeriesColor: false,
    custom: ({
      series,
      seriesIndex,
      w: {
        globals: { seriesPercent, labels, colors },
      },
    }) =>
      tooltipWithPercentage({
        color: colors[seriesIndex],
        label: newKeys[labels[seriesIndex]] || labels[seriesIndex],
        data: series[seriesIndex],
        percentage: head(seriesPercent?.[seriesIndex])?.toFixed(1),
      }),
  },
}

export const disableRowFilter = [
  'Unassigned Hours',
  'Assigned hours',
  'UnassignedHours',
  'AssignedHours',
  'Assigned',
  'Unassigned',
  'assigned',
  'unassigned',
  'distribution',
  'Other',
]

const cityKeys = ['employee_city', 'city', 'office_city']

export const locationKeys = ['location', ...cityKeys]

const operatingUnitKeys = ['operatingUnit', 'costCenter', 'operating_unit']

export const clientKeys = ['clientId', 'client_id', 'client']

const hrsKey = [reqHrs, TOTAL_HOURS]

export const newKeys = {
  exclude: included,
  location: topLocation,
  timeFte: 'FTE (%)',
  grade,
  generic_hours: unassignedHours,
  unassignedHours: 'unassigned',
  assignedHours: 'assigned',
  ...Object.fromEntries(hrsKey.map((k1) => [k1, assignedTitle])),
  ...Object.fromEntries(cityKeys.map((k1) => [k1, topLocation])),
  ...Object.fromEntries(operatingUnitKeys.map((k1) => [k1, operatingUnit])),
}

export const changeKey = {
  requested_hours: assignedHours,
  included: 'exclude',
}

export const dateDefaultFormat = minusSymbol

export const flagTitle = 'Flags'
