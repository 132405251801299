import api from '../../utils/api'
import { apiEndPoints, method as apiMethod } from '../../utils/constant'
import {
  checkUndefined,
  entries,
  equal,
  handleName,
  isArray,
  keys,
  ternary,
} from '../../utils/javascript'
import { loadStateFn, saveStateFn } from '../../utils/localStorage'
import {
  SET_BULK_DATA,
  SET_CHECK_FLAGS,
  SET_COL_DATA,
  SET_COUNT_FLAG,
  SET_FILTER_DATA,
  SET_FILTER_VALUES,
  SET_GRAPH_DATA,
  SET_PAGINATION,
  SET_PERIOD,
  SET_ROW_DATA,
  SET_SCROLL_VIEW,
  SET_SEARCH_VALUE,
  SET_SORT_ORDER,
  SET_VIEW_FLAGS,
} from '../constants'
import { changeLoadingStatus, setApiDataAction } from './api.action'

export const setFilteredDataAction =
  (formPath, data) => async (dispatch, getState) => {
    const { parent, child, childObject } = formPath
    dispatch({
      type: SET_FILTER_DATA,
      payload: {
        ...getState().table?.filterData,
        [parent]: {
          ...getState().table?.filterData?.[parent],
          [child]: childObject
            ? {
                ...getState().table?.filterData?.[parent]?.[child],
                [childObject]: data,
              }
            : data,
        },
      },
    })
  }

export const setRowData = (formPath, rowData) => async (dispatch, getState) => {
  const { parent, child, childObject } = formPath
  dispatch({
    type: SET_ROW_DATA,
    payload: {
      ...getState().table?.rowData,
      [parent]: {
        ...getState().table?.rowData?.[parent],
        [child]: childObject
          ? {
              ...getState().table?.rowData?.[parent]?.[child],
              [childObject]: rowData,
            }
          : rowData,
      },
    },
  })
}
export const setBulkData =
  (formPath, bulkData) => async (dispatch, getState) => {
    const { parent, childObject } = formPath
    dispatch({
      type: SET_BULK_DATA,
      payload: {
        ...getState().table?.bulkData,
        [parent]: childObject
          ? {
              ...getState().table?.bulkData?.[parent],
              [childObject]: bulkData,
            }
          : bulkData,
      },
    })
  }

export const setFilterKeys = (formPath, data) => async (dispatch, getState) => {
  const { parent, child } = formPath
  dispatch({
    type: SET_FILTER_VALUES,
    payload: {
      ...getState().table?.filterValues,
      [parent]: {
        ...getState().table?.filterKeys?.[parent],
        [child]: data,
      },
    },
  })
}

export const setGraphData =
  (formPath, data, isCheck = false, isView = false, index = null, localKey) =>
  async (dispatch, getState) => {
    const { parent, child, childObject, rowIndex } = formPath
    const userName = getState().app?.user?.username
    const groupName = getState().app?.group?.name
    const settingConfig = JSON.parse(
      loadStateFn(`${userName}-${groupName}-setting-configuration`),
    )

    let mod = {}
    if (isArray(data)) {
      data?.forEach((obj) => {
        mod = {
          ...mod,
          before: {
            name: handleName(
              obj?.assignedTalent?.name ??
                obj?.suggestedTalent?.name ??
                obj?.name,
            ),
            labelData: {
              isGeneric:
                obj?.assignedTalent?.isGeneric ??
                obj?.suggestedTalent?.isGeneric,
              operatingUnit:
                obj?.operatingUnit ??
                obj?.assignedTalent?.operatingUnit ??
                obj?.suggestedTalent?.operatingUnit,
              grade: obj?.grade,
              coreTeam: obj?.coreTeam || false,
            },
            data: entries(obj?.weeks || []).map(([ky, vl]) => ({
              x: ky,
              y: vl?.hours ?? vl,
            })),
          },
        }
      })
    } else {
      keys(data).forEach((k) => {
        mod = {
          ...mod,
          [k]: data[k]?.map((obj) => ({
            name: handleName(
              obj?.assignedTalent?.name ??
                obj?.suggestedTalent?.name ??
                obj?.name,
            ),
            labelData: {
              isGeneric:
                obj?.assignedTalent?.isGeneric ??
                obj?.suggestedTalent?.isGeneric,
              operatingUnit:
                obj?.operatingUnit ??
                obj?.assignedTalent?.operatingUnit ??
                obj?.suggestedTalent?.operatingUnit,
              grade: obj?.grade,
              coreTeam: obj?.coreTeam || false,
            },
            id: obj?.id,
            data: entries(obj?.weeks || []).map(([ky, vl]) => ({
              x: ky,
              y: vl?.hours ?? vl,
            })),
            ...(obj?.fitScore && { fitScore: obj?.fitScore }),
          })),
        }
      })
    }

    dispatch({
      type: SET_GRAPH_DATA,
      payload: {
        [parent]: {
          ...getState().table?.graphData?.[parent],
          [child]: childObject
            ? {
                ...getState().table?.graphData?.[parent]?.[child],
                [childObject]: {
                  ...getState().table?.graphData?.[parent]?.[child]?.[
                    childObject
                  ],
                  [index || rowIndex]: {
                    ...getState().table?.graphData?.[parent]?.[child]?.[
                      childObject
                    ]?.[index || rowIndex],
                    ...mod,
                  },
                },
              }
            : {
                ...getState().table?.graphData?.[parent]?.[child],
                [index || rowIndex]: {
                  ...getState().table?.graphData?.[parent]?.[child]?.[
                    index || rowIndex
                  ],
                  ...mod,
                },
              },
        },
      },
    })

    if (isCheck) {
      const beforeState =
        getState()?.table?.checkFlags?.[parent]?.[child]?.before
      const afterState = getState()?.table?.checkFlags?.[parent]?.[child]?.after

      dispatch(
        setGraphCheckFlagsAction(formPath, {
          before: ternary(checkUndefined(beforeState), true, beforeState),
          after: ternary(checkUndefined(afterState), true, afterState),
        }),
      )
    }
    if (isView) {
      const updownState = getState()?.table?.viewFlags?.[parent]?.[child]?.view
      let clonedSettingConfiguration = { ...settingConfig }
      clonedSettingConfiguration = {
        ...clonedSettingConfiguration,
        [localKey]: {
          ...clonedSettingConfiguration[localKey],
          view: ternary(
            checkUndefined(settingConfig?.[localKey]?.view),
            'upDown',
            settingConfig?.[localKey]?.view,
          ),
        },
      }
      saveStateFn(
        `${userName}-${groupName}-setting-configuration`,
        JSON.stringify(clonedSettingConfiguration),
      )
      dispatch(
        setGraphViewFlagsAction(formPath, {
          ...getState().table?.viewFlags?.[parent]?.[child],
          view: ternary(checkUndefined(updownState), 'upDown', updownState),
        }),
      )
    }
  }

export const setGraphCheckFlagsAction =
  (formPath, data) => async (dispatch, getState) => {
    const { parent, child, childObject } = formPath
    dispatch({
      type: SET_CHECK_FLAGS,
      payload: {
        [parent]: {
          ...getState().table?.checkFlags?.[parent],
          [child]: childObject
            ? {
                ...getState().table?.checkFlags?.[parent]?.[child],
                [childObject]: {
                  ...getState().table?.checkFlags?.[parent]?.[child]?.[
                    childObject
                  ],
                  ...data,
                },
              }
            : {
                ...getState().table?.checkFlags?.[parent]?.[child],
                ...data,
              },
        },
      },
    })
  }

export const setGraphPeriodAction =
  (formPath, data) => async (dispatch, getState) => {
    const { parent, child, childObject } = formPath
    dispatch({
      type: SET_PERIOD,
      payload: {
        [parent]: {
          ...getState().table?.checkFlags?.[parent],
          [child]: childObject
            ? {
                ...getState().table?.checkFlags?.[parent]?.[child],
                [childObject]: {
                  ...getState().table?.checkFlags?.[parent]?.[child]?.[
                    childObject
                  ],
                  ...data,
                },
              }
            : {
                ...getState().table?.checkFlags?.[parent]?.[child],
                ...data,
              },
        },
      },
    })
  }
export const setGraphViewFlagsAction =
  (formPath, data) => async (dispatch, getState) => {
    const { parent, child, childObject } = formPath
    dispatch({
      type: SET_VIEW_FLAGS,
      payload: {
        [parent]: {
          ...getState().table?.viewFlags?.[parent],
          [child]: childObject
            ? {
                ...getState().table?.viewFlags?.[parent]?.[child],
                [childObject]: {
                  ...getState().table?.viewFlags?.[parent]?.[child]?.[
                    childObject
                  ],
                  ...data,
                },
              }
            : {
                ...getState().table?.viewFlags?.[parent]?.[child],
                ...data,
              },
        },
      },
    })
  }

export const setCountFlag =
  ({ parent, child }, data) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_COUNT_FLAG,
      payload: {
        countFlag: {
          ...getState().table?.countFlag,
          [parent]: {
            ...getState().table?.countFlag?.[parent],
            [child]: data,
          },
        },
      },
    })
  }

export const setSortOrder =
  ({ parent, child }, data) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_SORT_ORDER,
      payload: {
        sortOrder: {
          ...getState().table?.sortOrder,
          [parent]: {
            ...getState().table?.sortOrder?.[parent],
            [child]: { ...data },
          },
        },
      },
    })
  }

export const setSearchValue =
  ({ parent, child, childObject }, data) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_SEARCH_VALUE,
      payload: {
        search: {
          ...getState().table?.search,
          [parent]: {
            ...getState().table?.search?.[parent],
            [child]: ternary(
              childObject,
              {
                ...getState().table?.search?.[parent]?.[child],
                [childObject]: data,
              },
              data,
            ),
          },
        },
      },
    })
  }
export const setScrollTableView = (data) => async (dispatch) => {
  dispatch({
    type: SET_SCROLL_VIEW,
    payload: {
      scrollTableView: data,
    },
  })
}

export const setPagination =
  (formPath, data, page) => async (dispatch, getState) => {
    const { parent, child, childObject, rowIndex } = formPath
    const state = getState()?.table
    dispatch({
      type: SET_PAGINATION,
      payload: {
        pagination: {
          ...state?.pagination,
          [parent]: child
            ? {
                ...state?.pagination?.[parent],
                [child]: childObject
                  ? {
                      ...state?.pagination?.[parent]?.[child],
                      [childObject]: rowIndex
                        ? {
                            ...state?.pagination?.[parent]?.[child]?.[
                              childObject
                            ],
                            [page]: {
                              ...state?.pagination?.[parent]?.[child]?.[
                                childObject
                              ]?.[page],
                              [rowIndex]: {
                                ...state?.pagination?.[parent]?.[child]?.[
                                  childObject
                                ]?.[page]?.[rowIndex],
                                ...data,
                              },
                            },
                          }
                        : {
                            ...state?.pagination?.[parent]?.[child]?.[
                              childObject
                            ],
                            ...data,
                          },
                    }
                  : rowIndex
                  ? {
                      ...state?.pagination?.[parent]?.[child],
                      [page]: {
                        ...state?.pagination?.[parent]?.[child]?.[page],
                        [rowIndex]: {
                          ...state?.pagination?.[parent]?.[child]?.[page]?.[
                            rowIndex
                          ],
                          ...data,
                        },
                      },
                    }
                  : { ...state?.pagination?.[parent]?.[child], ...data },
              }
            : { ...state?.pagination?.[parent], ...data },
        },
      },
    })
  }

export const setReOrderColumns = (localKey, colData) => (dispatch) => {
  const payload = {
    [localKey]: { ...colData },
  }
  dispatch({
    type: SET_COL_DATA,
    payload: { ...payload },
  })
}

export const bookingGraphAction =
  ({
    graphPath,
    rowSelectorPath,
    rowChild,
    payload,
    pageEndPoint = apiEndPoints.employees,
    localKey,
    page,
    onlyNonzeroBookingsInPeriod = true,
  }) =>
  async (dispatch, getState) => {
    const tableState = getState()?.table
    const apiData =
      getState()?.api?.[rowSelectorPath?.parent]?.[rowSelectorPath?.child]?.[
        rowChild
      ]?.items
    const pagination =
      tableState?.pagination?.[graphPath?.parent]?.[graphPath?.child]?.[page]

    const uuid =
      getState()?.form?.formData?.optimization?.optimization?.optimization
    const method = apiMethod.post

    const hydrateHeatmapData = ({ data }) => {
      let reversedData = [...data?.items]?.reverse() || []
      if (graphPath?.child === 'missionBooking') {
        reversedData = [
          ...((reversedData || [])?.toSpliced(0, 1) || []),
          reversedData[0],
        ]
      }
      const modifiedData = {
        ...data,
        items: [
          ...(reversedData || []), // Use an empty array if data?.items is undefined
          ...(data?.totalHours ? [{ name: 'Total', ...data.totalHours }] : []),
        ],
      }
      delete modifiedData?.totalHours
      return modifiedData
    }

    tableState?.expandedRows?.forEach(async (rowIndex) => {
      dispatch(
        changeLoadingStatus(
          {
            ...graphPath,
            childObject: rowIndex?.toString(),
            childObjectIndex: 'before',
          },
          true,
        ),
      )
      const { data: beforeData } = await api({
        method,
        endPoint: `${apiEndPoints.datasets}/paginate/${pageEndPoint}/${
          apiData?.[rowIndex]?.id
        }/${apiEndPoints.workOpportunities}/before?page=${
          pagination?.[rowIndex]?.page || 1
        }&size=${
          pagination?.[rowIndex]?.before?.size || 10
        }&only_nonzero_bookings_in_period=${onlyNonzeroBookingsInPeriod}`,
        data: payload,
      })
      if (!checkUndefined(uuid) && !equal(uuid, 'livePlan')) {
        const { data: afterData } = await api({
          method,
          endPoint: `${
            apiEndPoints.optimizations
          }/paginate/${uuid}/${pageEndPoint}/${apiData?.[rowIndex]?.id}/${
            apiEndPoints.workOpportunities
          }/after?page=${pagination?.[rowIndex]?.page || 1}&size=${
            pagination?.[rowIndex]?.after?.size || 10
          }&only_nonzero_bookings_in_period=${true}`,
          data: payload,
        })
        dispatch(
          setApiDataAction(graphPath?.parent, {
            ...getState()?.api?.[graphPath?.parent],
            [graphPath?.child]: {
              ...getState()?.api?.[graphPath?.parent]?.[graphPath?.child],
              [rowIndex]: {
                ...getState()?.api?.[graphPath?.parent]?.[graphPath?.child]?.[
                  rowIndex
                ],
                after: hydrateHeatmapData({ data: afterData }),
              },
            },
          }),
        )
        if (afterData) {
          dispatch(
            setGraphData(
              { ...graphPath, rowIndex },
              { after: hydrateHeatmapData({ data: afterData })?.items },
              true,
              true,
              undefined,
              1,
              `${localKey}`,
            ),
          )
          dispatch(
            setPagination(
              { ...graphPath, rowIndex },
              {
                after: {
                  page,
                  size: pagination?.[rowIndex]?.after?.size,
                  totalRec: hydrateHeatmapData({ data: afterData })?.size,
                },
              },
              page,
            ),
          )
        }
      }
      dispatch(
        setApiDataAction(graphPath?.parent, {
          ...getState()?.api?.[graphPath?.parent],
          [graphPath?.child]: {
            ...getState()?.api?.[graphPath?.parent]?.[graphPath?.child],
            [rowIndex]: { before: hydrateHeatmapData({ data: beforeData }) },
          },
        }),
      )
      if (beforeData) {
        dispatch(
          setGraphData(
            { ...graphPath, rowIndex },
            { before: hydrateHeatmapData({ data: beforeData })?.items },
            true,
            true,
            undefined,
            1,
            `${localKey}`,
          ),
        )
        dispatch(
          changeLoadingStatus(
            {
              ...graphPath,
              childObject: rowIndex?.toString(),
              childObjectIndex: 'before',
            },
            false,
          ),
        )
        dispatch(
          setPagination(
            { ...graphPath, rowIndex },
            {
              before: {
                page,
                size: pagination?.[rowIndex]?.before?.size,
                totalRec: hydrateHeatmapData({ data: beforeData })?.size,
              },
            },
            page,
          ),
        )
      }
    })
  }
