import { ACCEPTED, REJECTED, UNDECIDED } from '../../utils/constant'

export const dots = [
  {
    className: 'redDot',
    type: 'red',
    tooltipTitle: 'Reject',
    value: REJECTED,
  },
  {
    className: 'greyDot',
    type: 'grey',
    tooltipTitle: 'Undecided',
    value: UNDECIDED,
  },
  {
    className: 'greenDot',
    type: 'green',
    tooltipTitle: 'Accept',
    value: ACCEPTED,
  },
]

export const activeDot = {
  ACCEPTED: 'green',
  UNDECIDED: 'grey',
  REJECTED: 'red',
}
