import React from 'react'

import RPCTooltip from './elements/RPCTooltip'
import RPCButton from './RPCButton'
import RPCLoader from './RPCLoader'

const ButtonMapping = ({ buttonAttributes, id, disabled, isLoading }) => {
  const Loader = () => <RPCLoader size={15} isBtnLoader />
  const isButtonDisabled = isLoading || disabled
  return Array.isArray(buttonAttributes) ? (
    buttonAttributes.map(
      ({ value, type, onClick, disabled, tooltipTitle, ...rest }, index) => {
        switch (type) {
          case 'edit':
          case 'delete':
            return (
              <RPCButton
                key={index}
                onClick={() => onClick()}
                disabled={disabled}
                isLoading={isLoading}
                {...rest}
              >
                Delete
                <Loader />
              </RPCButton>
            )
          case 'add':
            return (
              <RPCButton
                onClick={() => onClick(id)}
                isLoading={isLoading}
                disabled={disabled || isButtonDisabled}
                {...rest}
                key={index}
              >
                {value}
                <Loader />
              </RPCButton>
            )
          case 'clear':
          case 'cancel':
          case 'back':
            return (
              <RPCButton
                color="inherit"
                onClick={onClick}
                disabled={isLoading || disabled}
                {...rest}
                key={index}
              >
                {value}
              </RPCButton>
            )
          case 'button':
            return (
              <RPCButton
                color="inherit"
                onClick={onClick}
                disabled={isLoading || disabled}
                isLoading={isLoading}
                {...rest}
                key={index}
              >
                {value}
                <Loader />
              </RPCButton>
            )
          case 'submit':
            return (
              <RPCButton
                key={index}
                type={type}
                disabled={disabled || isButtonDisabled}
                isLoading={isLoading}
                {...rest}
              >
                {value}
                <Loader />
              </RPCButton>
            )
          case 'move':
            return (
              <RPCButton
                key={index}
                type="button"
                onClick={() => onClick()}
                disabled={disabled || isButtonDisabled}
                isLoading={isLoading}
                {...rest}
              >
                {value}
                <Loader />
              </RPCButton>
            )
          case 'tooltipBtn':
            return (
              <RPCTooltip title={tooltipTitle}>
                <span>
                  <RPCButton
                    color="inherit"
                    onClick={onClick}
                    disabled={isLoading || disabled}
                    isLoading={isLoading}
                    {...rest}
                    key={index}
                  >
                    {value}
                    <Loader />
                  </RPCButton>
                </span>
              </RPCTooltip>
            )
          default:
            return 'No Button Type Available'
        }
      },
    )
  ) : (
    <RPCButton
      type={buttonAttributes.type}
      onClick={buttonAttributes.onClick}
      className={buttonAttributes.className}
      {...buttonAttributes}
      disabled={isButtonDisabled}
      isLoading={isLoading}
    >
      {buttonAttributes.value}
      <Loader />
    </RPCButton>
  )
}

export default React.memo(ButtonMapping)
