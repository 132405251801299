import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from '../../hooks/use-dispatch'
import { apiAction } from '../../redux/actions/api.action'
import {
  clearSessionAction,
  sidebarOpenedAction,
} from '../../redux/actions/app.action'
import { removeRegisteredDevicesDetail } from '../../redux/description'
import {
  CLOSE_NAVBAR_WIDTH,
  REGISTERED_DEVICES_PATH,
} from '../../utils/constant'
import { equal } from '../../utils/javascript'
import { clearStateFn, loadStateFn } from '../../utils/localStorage'

export const headerProfileContainer = () => {
  const { dispatch } = useDispatch()
  const [isOpen, toggle] = useState(null)

  const user = useSelector((state) => state.app.user)
  const group = useSelector((state) => state.app.group)
  const isSidebarOpen = useSelector((state) => state.app?.sidebarOpened)
  const registeredDevices = useSelector(
    (state) =>
      state?.api?.[REGISTERED_DEVICES_PATH?.parent]?.[
        REGISTERED_DEVICES_PATH?.child
      ],
  )
  const isMobile = window.innerWidth <= CLOSE_NAVBAR_WIDTH

  const clearFcmToken = async () => {
    const currentMachineId = loadStateFn('MachineId')
    const deviceId = registeredDevices?.find((el) =>
      equal(el?.deviceId, currentMachineId),
    )?.id
    await dispatch(
      apiAction({
        apiDetails: removeRegisteredDevicesDetail(deviceId),
        formPath: REGISTERED_DEVICES_PATH,
      }),
    )
  }

  const handleLogoutSession = () => {
    clearFcmToken()
    dispatch(clearSessionAction())
    clearStateFn()
  }
  const responsiveToggle = () => {
    toggle(null)
    if (isMobile && isSidebarOpen) {
      dispatch(sidebarOpenedAction())
    }
  }
  return {
    isOpen,
    toggle,
    user,
    group,
    handleLogoutSession,
    responsiveToggle,
  }
}
