import { useSelector } from 'react-redux'

import { checkIncludes, keys } from '../utils/javascript'

export const permissionPages = (pagePermission) => {
  const permissions = useSelector((state) => state?.app?.permissions)
  return checkIncludes(pagePermission, keys(permissions))
}

/* non-admin page permissions */
export const DATA_IMPORT = 'data-import'
export const TALENT = 'talents'
export const WORK_OPPORTUNITY = 'work-opportunities'
export const LABELS = 'labels'
export const MISSION_CONTROL = 'mission-control'
export const KPI_GRAPH_REPORTS = 'kpi-graph-reports'
export const KPI_OVERVIEW = 'kpi-overview'
export const TALENT_PROFILE = 'talent-profile'
export const SETTINGS = 'settings'
export const DATA_GATHERING_TALENT = 'data-gathering-talent'
export const DATA_GATHERING_JOBS = 'data-gathering-job'
export const JOB_MANAGER = 'job-manager'

/* admin page permissions */
export const ADMIN_REPORT = 'admin-report'
export const ADMIN_DELETE = 'admin-delete'
export const ADMIN_CONFIGURATION = 'admin-configure'
