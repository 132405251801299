import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  tabs: {
    marginBottom: '30px',
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& h5': {
      textTransform: 'capitalize',
    },
  },
  kpiCardContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  kpiCardPercentage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  kpiCardPercentagePlaceHolder: {
    height: '50px',
  },
  resultCardPrevious: {
    marginTop: theme.spacing(1),
  },
  resultCardDescription: {
    marginTop: theme.spacing(2),
  },
  importDate: {
    fontSize: 13,
    marginTop: 5,
  },
  cardBody: {
    fontWeight: 500,
    fontSize: 'calc(28px) !important',
  },
  graphContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  totalValue: {
    width: '100%',
  },
  formControl: {
    minWidth: 300,
  },
  positive: {
    color: `${theme.palette.success.dark} !important`,
  },
  negative: {
    color: `${theme.palette.error.main} !important`,
  },
  zero: {
    color: `${theme.palette.text.hint} !important`,
  },
  customizeHeader: {
    '& .MuiBox-root': {
      margin: 'auto',
    },
    '& span': {
      display: 'block',
      minWidth: 120,
    },
    '& .MuiButtonBase-root': {
      '& span': {
        minWidth: 'auto',
      },
    },
  },
  table: {
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        transition: 'all 0.5s ease-in-out',
        '& .MuiTableCell-root': {
          lineHeight: 0.8,
        },
      },
    },
  },
  tableBorder: {
    overflow: 'hidden',
    '& .MuiTableCell-body': {
      borderRight: 'solid 1px #e0e0e0',
      fontSize: 16,
      [theme.breakpoints.down('1600')]: {
        fontSize: 14,
      },
      '&:first-child': {
        width: '16%',
        textAlign: 'left',
        [theme.breakpoints.up('lg')]: {
          width: '20%',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 14,
        },
      },
    },
    '& .MuiTableCell-head': {
      borderRight: 'solid 1px #e0e0e0',
      fontSize: 16,
      '&:first-child': {
        width: '16%',
      },
    },
  },
  displayDatasetBtn: {
    height: 'fit-content',
    '& span': {
      pointerEvents: 'none !important',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  highLightCell: {
    background: theme.palette.success.main,
  },
  upWard: {
    color: theme.palette.success.main,
    float: 'right',
  },
  iconStyle: {
    verticalAlign: 'middle',
    marginLeft: 5,
    float: 'right',
    '& svg': {
      color: theme.palette.success.main,
    },
  },
  greenArrow: {
    '& svg': {
      color: theme.palette.success.main,
    },
  },
  redArrow: {
    '& svg': {
      color: theme.palette.error.main,
    },
  },
  neutralColor: {
    '& svg': {
      color: 'black',
    },
  },
  rowGrid: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  rowFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  downWard: {
    color: theme.palette.error.main,
  },
  slantedDiagonal: {
    display: 'flex',
    backgroundImage:
      'linear-gradient( to top right, white calc(50% - 1px), #e0e0e0, white calc(50% + 1px) )',
    margin: '-16px',
    '& span': {
      width: '50%',
      minWidth: 120,
      '&:first-child': {
        paddingTop: '15%',
      },
      '&:last-child': {
        paddingBottom: '15%',
      },
    },
  },
  btnSave: {
    marginLeft: 10,
  },
  settingTable: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        width: '50%',
      },
    },
  },
  percentage: {
    fontSize: 13,
    color: '#808080',
    marginLeft: 3,
  },
  recommended: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      height: 20,
      width: 'auto',
      margin: [[5, 0, 0, 8]],
    },
  },
  gradientColor: {
    background: '#8FE7D4',
  },
  gradientColor2: {
    background: '#a9f5e5',
  },
  gradientColor3: {
    background: '#d8f5ef',
  },
  gradientColor4: {
    background: '#d3fbf3',
  },
  infoIconStyle: {
    '& svg': {
      verticalAlign: 'middle',
      marginRight: 5,
      width: 15,
      height: 15,
    },
  },
  infoIconLabel: {
    verticalAlign: 'middle',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cardHover: {
    opacity: 0.5,
  },
  datasetRows: {
    background: '#E6E6E6',
  },
  actionBtn: {
    justifyContent: 'flex-end',
  },
  fullHeight: {
    height: '100%',
    minHeight: 200,
  },
  fullWidth: {
    width: '100%',
  },
  optimizationCard: {
    minHeight: '200px !important',
  },
  bodyClass: {
    margin: [[10, 0, 0]],
  },
  removeMargin: {
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  showRecord: {
    display: 'none !important',
  },
  divider: {
    margin: [[0, 5]],
  },
  floatRight: { float: 'right' },
  setBackground: {
    background: '#E3E3E3',
  },
  formButtonsRight: {
    textAlign: 'right',
  },
  seriesSelectionBox: {
    backgroundColor: '#fff',
    padding: 10,
    position: 'relative',
    minHeight: 54,
  },
  seriesItem: {
    width: 30,
    height: 5,
    padding: 3,
    marginRight: '10px',
  },
  seriesConfigButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'pointer',
  },
  folderConfigButton: {
    position: 'absolute',
    top: 5,
    right: 170,
    cursor: 'pointer',
  },
  saveConfigButton: {
    position: 'absolute',
    top: 5,
    right: 130,
    cursor: 'pointer',
  },
  saveDirectConfigButton: {
    position: 'absolute',
    top: 5,
    right: 90,
    cursor: 'pointer',
  },
  saveReportSection: {
    width: '300px',
    padding: '10px 4px',
  },
  savedReportTitle: {
    paddingLeft: 16,
  },
  deleteReportBtn: {
    padding: 8,
  },
  saveReportBtn: {
    marginRight: 20,
    width: 82,
  },
  btnHover: {
    '&:hover': {
      color: '#ff7400 !important',
    },
  },
  reportDeleteIcon: {
    width: '14px !important',
    height: '14px !important',
  },
  savedReportParent: {
    overflowY: 'scroll',
    maxHeight: 300,
    width: '100%',
    '&::-webkit-scrollbar-track': {
      top: '64px !important',
    },
    '&::-webkit-scrollbar': {
      width: '4px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#f98e35 !important',
    },
  },
  timeUnitLabel: {
    fontSize: 20,
    fontWeight: 400,
  },
  timeUnitDropdown: {
    padding: 10,
  },

  xAxisSelect: {
    marginRight: 16,
    marginLeft: 10,
    width: 150,
  },
  error: {
    color: 'red',
  },
  formButtons: {
    textAlign: 'right',
    '& button': {
      marginRight: 10,
    },
  },
  savedReportItem: {
    paddingLeft: 16,
    paddingBlock: 4,
    '&:hover': {
      backgroundColor: '#0000000a',
      color: '#ff7400 !important',
      cursor: 'pointer',
    },
  },
  savedReportItemActive: {
    paddingLeft: 16,
    paddingBlock: 4,
    backgroundColor: '#00000017',
    color: '#ff7400 !important',
    cursor: 'pointer',
  },
  formWrap: {
    width: '100%',
  },
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    maxWidth: 500,
    margin: '0 auto',
    '& .MuiPaper-root, & .MuiDialog-container': {
      width: '100%',
    },
    '& .MuiStepper-root': {
      padding: [[24, 0, 0, 0]],
    },
  },
  modalTitle: {
    fontWeight: 500,
    paddingRight: 5,
  },
  modalMainTitle: {
    fontSize: 26,
    marginBottom: 0,
    lineHeight: 'normal',
    '& button': {
      position: 'absolute',
      right: 5,
      top: 5,
    },
  },
  modalDetailContent: {
    paddingTop: 10,
  },
  downloadIconStyle: {
    pointerEvents: 'none',
    '& img': {
      width: '22px !important',
      height: '22px !important',
    },
  },
  formTitle: {
    borderBottom: '1px solid #ddd',
    marginBottom: 0,
    fontWeight: 500,
    color: '#000',
    display: 'inline-block',
    fontFamily: 'jura',
  },
  disableBtnTooltip: {
    '&.MuiButtonBase-root.Mui-disabled': {
      cursor: 'default',
      pointerEvents: 'visible',
    },
    '&:hover': {
      transform: 'none',
      boxShadow: 'none',
    },
  },
  KPIGrid: {
    width: '33.33%',
    [theme.breakpoints.down(1920)]: {
      width: '50%',
    },
    [theme.breakpoints.down(1380)]: {
      width: '100%',
    },
    [theme.breakpoints.down(767)]: {
      minWidth: 630,
    },
  },
  comparePlan: {
    '& .MuiBox-root:not(:last-child)': {
      marginBottom: 12,
    },
  },
  integrationConfiguration: {
    '& h5.MuiTypography-h6': {
      fontWeight: 'bold',
      padding: 15,
      borderBottom: '1px solid  #e0e0e0',
      marginBottom: 0,
    },
    '& .MuiBox-root': {
      '&:not(last-child)': {
        borderBottom: '1px solid  #e0e0e0',
      },
    },
    '& .MuiTypography-h6': {
      fontWeight: '500',
      marginBottom: 10,
    },
  },
}))
