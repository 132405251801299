import {
  confirmPasswordConst,
  fullWidth,
  notEmptyOrNull,
  password,
} from '../utils/constant'
import { settingsPath } from './formPath.description'
import { options } from './table/options.description'

export const formPath = {
  parent: settingsPath,
  child: settingsPath,
}

export const changePasswordPath = {
  parent: 'settings',
  child: 'change-password',
}

export const userSettingsPath = {
  parent: 'settings',
  child: 'optimizationSettings',
}

export const columns = [
  {
    name: 'key',
    label: 'Columns',
    ...options({
      filter: false,
      sort: false,
      hideColumnHeader: true,
    }),
  },
  {
    name: 'value',
    label: 'Values of Columns',
    ...options({
      filter: false,
      sort: false,
      hideColumnHeader: true,
    }),
  },
]

export const tabs = [
  { label: 'Optimization Settings', value: 'optimization-settings' },
  { label: 'Defaults', value: 'defaults' },
  { label: 'Change password', value: 'change-password' },
  { label: 'Integration Configuration', value: 'integration-configuration' },
]
export const optimizationSettingsText =
  'Configure the elements that factor into the fit score. \nThese settings will also be used when you run an optimization'
const formAttributes = {
  default: {
    formPath: changePasswordPath,
    formAttributes: {
      currentPassword: {
        type: 'password',
        label: 'Current password *',
        isRequired: true,
        fullWidth,
        error: ['current password required'],
        pattern: notEmptyOrNull,
      },
      newPassword: {
        type: 'password',
        label: 'New password *',
        isRequired: true,
        fullWidth,
        error: ['new password required'],
        pattern: password,
      },
      confirmPassword: {
        type: 'password',
        label: 'Confirm password *',
        isRequired: true,
        fullWidth,
        error: ['confirm password is required', 'password not match'],
        pattern: confirmPasswordConst,
      },
    },
  },
}

export const form = [{ type: null, fields: [formAttributes] }]

export const settingPath = { parent: 'app', child: 'settings' }
