import { useCallback, useEffect, useState } from 'react'

const useToggle = (initialState = true) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(initialState)
  }, [initialState])

  const toggle = useCallback(() => setIsOpen((state) => !state), [])

  return [isOpen, toggle]
}

export default useToggle
