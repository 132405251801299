import { Switch } from '@material-ui/core'
import React from 'react'

import RPCTooltip from './elements/RPCTooltip'
import InfoIcon from './icons/InfoIcon'

const RPCSwitch = ({
  value,
  name,
  title,
  checked,
  onChange,
  error,
  disabled,
  Icon,
  classes,
  readOnly = false,
  tooltipIconText,
}) => (
  <>
    {title && <label htmlFor={name}>{title}</label>}
    {tooltipIconText && (
      <RPCTooltip title={tooltipIconText}>
        <span>
          <InfoIcon
            style={{
              width: 15,
              height: 15,
              marginLeft: 7,
              verticalAlign: 'middle',
            }}
          />
        </span>
      </RPCTooltip>
    )}
    <Switch
      name={name}
      onChange={(e) => onChange(e)}
      checked={checked}
      color="primary"
      error={error}
      value={value}
      disabled={disabled || readOnly}
      classes={{
        root: classes?.root,
        switchBase: classes?.switchBase,
        thumb: classes?.thumb,
        track: classes?.track,
        checked: classes?.checked,
      }}
      {...(Icon && { checkedIcon: <Icon />, icon: <Icon /> })}
    />
  </>
)

export default RPCSwitch
