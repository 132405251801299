import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { formPath, settingPath } from '../../description/settings.description'
import { useDispatch } from '../../hooks/use-dispatch'
import {
  changeLoadingStatus,
  getApiDataAction,
} from '../../redux/actions/api.action'
import { setActiveTabAction } from '../../redux/actions/tab.action'
import { details } from '../../redux/description'
import {
  capitalizeString,
  entries,
  replaceChar,
  spaceBetween,
} from '../../utils/javascript'

// FIXME : update redux state according to admin / settings
export const adminSettingsContainer = () => {
  const { dispatch } = useDispatch()
  const settings = useSelector((state) => state.api?.settings?.settings)
  const [rows, setRows] = useState([])
  const isLoading = useSelector(
    (state) => state?.api?.loader?.settings?.settings,
  )

  useEffect(() => {
    if (!settings) {
      dispatch(changeLoadingStatus(formPath, true))
      dispatch(
        getApiDataAction({
          formPath,
          apiDetails: details,
          loaderFormPath: formPath,
        }),
      )
    }
    if (settings) {
      const arrObj = []

      entries(settings).map(([key, value]) =>
        arrObj.push({
          key: capitalizeString(replaceChar(spaceBetween(key))),
          value,
        }),
      )

      setRows(arrObj)
    }
  }, [settings])

  useEffect(() => {
    dispatch(setActiveTabAction(settingPath, { active: 'default-settings' }))
  }, [])

  return useMemo(
    () => ({
      rows,
      isLoading,
    }),
    [rows],
  )
}
