import { useTheme } from '@material-ui/styles'

import useStyles from '../shared/css/dot.style'

export const dotContainer = () => {
  const classes = useStyles()
  const theme = useTheme()

  return { classes, theme }
}
