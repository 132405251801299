import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  labelPoppver: {
    maxWidth: 350,
    width: '100%',
    marginTop: 40,
    marginLeft: -20,
    '& .MuiPopover-paper': {
      maxWidth: '100%',
      width: '100%',
      '& .MuiMenu-list': {
        padding: 10,
      },
    },
  },
  blueColor: {
    width: 'fit-content',
    cursor: 'pointer',
    padding: 0,
    minWidth: 'auto',
    '& .MuiButton-label': {
      color: '#1e14dbc7 !important',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
    },
    '& .MuiTouchRipple-root': {
      display: 'none !important',
    },
  },
}))

export default useStyles
