import { makeStyles } from '@material-ui/core/styles'

import { CLOSE_NAVBAR_WIDTH } from '../../../utils/constant'

const drawerWidth = 240

export default makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& .MuiPaper-root': {
      '& > .MuiList-root': {
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
      },
    },
  },
  drawerOpen: {
    position: 'static',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&.MuiDrawer-paperAnchorLeft': {
      [theme.breakpoints.down(CLOSE_NAVBAR_WIDTH)]: {
        width: '100%',
      },
      width: drawerWidth,
    },
    [theme.breakpoints.down(CLOSE_NAVBAR_WIDTH)]: {
      '&.MuiDrawer-docked': {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 1000,
        top: 0,
        bottom: 0,
        width: '100%',
      },
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 47,

    '& .MuiList-root': {
      '& .MuiButtonBase-root': {
        '& sup': {
          marginLeft: -16,
          marginTop: -20,
        },
      },
    },
    [theme.breakpoints.down(CLOSE_NAVBAR_WIDTH)]: {
      width: 0,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))
