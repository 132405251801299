import { Drawer, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { sidebarContainer } from '../../../container/layout/sidebar.container'
import {
  manualStructure as defaultStructure,
  retainStructure,
} from '../../../description/sidebar.description'
import RPCList from '../../../shared/RPCList'
import { ternary } from '../../../utils/javascript'
import SidebarLink from './SidebarLink'

const Sidebar = ({
  compClasses,
  needToolbar = true,
  compClick = null,
  deleteModalToggleFunc,
  needToHideText = true,
  checkPermission = true,
  customStructure = null,
  isMainSidebar = true,
}) => {
  const { classes, isPermanent, sidebarOpened, isIntegration } =
    sidebarContainer()

  const [structure, setStructure] = useState(defaultStructure)

  useEffect(() => {
    if (isIntegration) {
      setStructure(retainStructure)
    } else {
      setStructure(defaultStructure)
    }
  }, [isIntegration])

  const structureToRender = customStructure || structure

  return (
    <Drawer
      variant={ternary(isPermanent, 'permanent', 'temporary')}
      className={classNames(compClasses?.drawer || classes.drawer, {
        [compClasses?.drawerOpen || classes.drawerOpen]: sidebarOpened,
        [compClasses?.drawerClose || classes.drawerClose]: !sidebarOpened,
      })}
      classes={{
        paper: classNames({
          [compClasses?.drawerOpen || classes.drawerOpen]: sidebarOpened,
          [compClasses?.drawerClose || classes.drawerClose]: !sidebarOpened,
        }),
      }}
      open={sidebarOpened}
    >
      {needToolbar && <div className={classes.toolbar} />}
      <RPCList className={classes.sidebarList}>
        {structureToRender?.map((link, index) => (
          <div
            {...{ ...(link?.needDelete && { style: { display: 'flex' } }) }}
            key={`sidebar-${index}`}
          >
            <SidebarLink
              key={index}
              {...{ ...(compClick && { compClick }) }}
              {...link}
              needToHideText={needToHideText}
              checkPermission={checkPermission}
              isMainSidebar={isMainSidebar}
            />
            {link?.needDelete && (
              <IconButton
                onClick={() => deleteModalToggleFunc(link?.id)}
                style={{ margin: 'auto', height: 48 }}
              >
                <Delete />
              </IconButton>
            )}
          </div>
        ))}
      </RPCList>
    </Drawer>
  )
}

export default React.memo(Sidebar)
