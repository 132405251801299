import { makeStyles } from '@material-ui/core'

import { CLOSE_NAVBAR_WIDTH } from '../../../utils/constant'

export default makeStyles((theme) => ({
  logotype: {
    color: 'white',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.5),
    },
  },
  appBar: {
    width: '100vw',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.secondary.main,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(1.5),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down(CLOSE_NAVBAR_WIDTH)]: {
      marginLeft: 0,
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.5),
    },
  },
  headerIcon: {
    fontSize: 28,
    color: 'rgba(255, 255, 255, 0.35)',
  },
  headerIconCollapse: {
    color: 'white',
  },
  headerLogo: {
    height: '58px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
    },
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    color: `${theme.palette.text.hint} !important`,
    verticalAlign: 'middle',
    minWidth: '35px !important',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  profileMenuLink: {
    fontSize: 16,
    color: theme.palette.text.hint,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '& $profileMenuIcon': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  contactLink: {
    fontSize: 16,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))
