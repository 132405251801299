import { SET_KPI_FILTER_DATA, SET_KPI_FILTER_OPEN } from '../constants'

const kpi = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_KPI_FILTER_DATA:
      return { ...state, ...payload }

    case SET_KPI_FILTER_OPEN:
      return { ...state, ...payload }

    default:
      return state
  }
}
export default kpi
