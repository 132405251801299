import { CircularProgress, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
    zIndex: 999,
    margin: [[0, 'auto']],
  },
  btnLoader: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginLeft: 10,
    transition: 'all .3s ease-in-out',
  },
}))

const RPCLoader = ({
  size,
  className,
  isCenterLoader,
  isBtnLoader = false,
  ...props
}) => {
  const classes = useStyles()
  return (
    <CircularProgress
      className={classNames(
        {
          [classes.loader]: isCenterLoader,
          [classes.btnLoader]: isBtnLoader,
        },
        className,
      )}
      {...{ size, ...props }}
    />
  )
}

export default RPCLoader
