import SettingIcon from '../shared/icons/SettingIcon'

export const structure = [
  {
    link: '/settings',
    label: 'Settings',
    icon: <SettingIcon />,
    pagePermission: 'settings',
  },
]

export const signOut = 'Sign Out'

export const contactSupport = 'Contact support'

export const privacyPolicy = 'Privacy Policy'
