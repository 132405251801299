/**
 * App constants
 */
export const APP_AUTH = 'app/AUTH'
export const LOADING_CHANGE = 'LOADING_CHANGE'
export const CLEAR_LOADING_STATE = 'CLEAR_LOADING_STATE'
export const EDIT_CHANGE = 'EDIT_CHANGE'
export const CLEAR_SESSION = 'CLEAR_SESSION'
export const CHECK_MATOMO_STATUS = 'CHECK_MATOMO_STATUS'
export const CHECK_SENTRY_STATUS = 'CHECK_SENTRY_STATUS'
export const ADD_PIWIK_INSTANCE = 'ADD_PIWIK_INSTANCE'
export const SET_FIREBASE_CONFIG = 'SET_FIREBASE_CONFIG'

/**
 * Sidebar constants
 */
export const SIDEBAR_OPENED = 'SIDEBAR_OPENED'
export const MANAGE_SIDE_BAR_MENU = 'MANAGE_SIDE_BAR_MENU'

/**
 * Form constants
 */
export const ON_FORM_CHANGE = 'ON_FORM_CHANGE'
export const CLEAR_FORM = 'CLEAR_FORM'
export const IS_REQUIRED = 'IS_REQUIRED'
export const IS_VALID = 'IS_VALID'
export const SET_FORM_VALUES = 'SET_FORM_VALUES'
export const CLEAR_FILE_UPLOAD = 'CLEAR_FILE_UPLOAD'
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA'
/**
 * Api constants
 */

export const SET_API_DATA = 'SET_API_DATA'
export const SET_FILE_DATA = 'SET_FILE_DATA'
export const RESET_REDUX = 'RESET_REDUX'

/**
 * Error constants
 */

export const SET_ERROR_INFO = 'SET_ERROR_INFO'
export const SET_FORM_ERROR_INFO = 'SET_FORM_ERROR_INFO'

/**
 * Description constants
 */
export const SET_TAB_DESCRIPTION = 'SET_TAB_DESCRIPTION'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const SET_ROW_DATA = 'SET_ROW_DATA'
export const SET_BULK_DATA = 'SET_BULK_DATA'
export const SET_CELL_DATA = 'SET_CELL_DATA'
export const SET_CARD_DETAILS = 'SET_CARD_DETAILS'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'

export const EXPANDED_ROW_INDEX = 'EXPANDED_ROW_INDEX'
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA'

export const SET_CHECK_FLAGS = 'SET_CHECK_FLAGS'
export const SET_VIEW_FLAGS = 'SET_VIEW_FLAGS'
export const SET_PERIOD = 'SET_PERIOD'
export const SET_DISABLED = 'SET_DISABLED'
export const LOCALE = 'LOCALE'

export const SET_TABLE_TITLE = 'SET_TABLE_TITLE'

export const IS_MODAL_TOGGLE = 'IS_MODAL_TOGGLE'

export const SET_COUNT_FLAG = 'SET_COUNT_FLAG'

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'

export const SET_SORT_ORDER = 'SET_SORT_ORDER'

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'

export const SET_SCROLL_VIEW = 'SET_SCROLL_VIEW'

export const EXPANDED_ROW_INDEXES = 'EXPANDED_ROW_INDEXES'

export const SET_PAGINATION = 'SET_PAGINATION'

export const SET_FLAG = 'SET_FLAG'

export const SET_COL_DATA = 'SET_COL_DATA'

export const SET_REVIEW_MODE = 'SET_REVIEW_MODE'

export const SET_FILTER_OPEN = 'SET_FILTER_OPEN'

/* KPI constants */

export const SET_KPI_FILTER_DATA = 'SET_KPI_FILTERS'

export const SET_KPI_FILTER_OPEN = 'SET_KPI_FILTER_OPEN'

/* Job Details constants */
export const SET_DETAIL_GRAPH_DATA = 'SET_DETAIL_GRAPH_DATA'
export const SET_GRAPH_PERIOD = 'SET_GRAPH_PERIOD'
export const SET_GRAPH_VIEW_FLAGS = 'SET_GRAPH_VIEW_FLAGS'
export const SET_GRAPH_CHECK_FLAGS = 'SET_GRAPH_CHECK_FLAGS'
export const SET_GRAPH_EDIT_MODAL_OPEN = 'SET_GRAPH_EDIT_MODAL_OPEN'
export const SET_GRAPH_DELETE_MODAL_OPEN = 'SET_GRAPH_DELETE_MODAL_OPEN'
export const SET_GRAPH_ADD_MODAL_OPEN = 'SET_GRAPH_ADD_MODAL_OPEN'
export const SET_GRAPH_EDIT_HOURS_MODAL_OPEN = 'SET_GRAPH_EDIT_HOURS_MODAL_OPEN'
export const SET_IS_MOVE_MODE = 'SET_IS_MOVE_MODE'
export const SET_GRAPH_MOVE_MODAL_OPEN = 'SET_GRAPH_MOVE_MODAL_OPEN'
export const SET_GRAPH_DELETE_BOOKING_MODAL_OPEN =
  'SET_GRAPH_DELETE_BOOKING_MODAL_OPEN'
