import classNames from 'classnames'
import React from 'react'

import { defaultValue } from '../description/localization.description'
import { importStatus, n, NUMBER, y } from '../utils/constant'
import {
  getDate,
  getFractionalNumber,
  isArray,
  isBool,
  isEmptyString,
  length,
  lowerCase,
  numberLocale,
  ternary,
  typeOf,
} from '../utils/javascript'
import { loadStateFn } from '../utils/localStorage'
import { isDate, isNumber } from '../utils/regex'
import { minusSymbol } from '../utils/symbols'
import { encodeWithComp } from '../utils/window'
import statusColor from './css/statusColor.style'
import RenderLabels from './RenderLabels'
import RPCLink from './RPCLink'
const RenderCustom = ({
  children,
  value,
  isIcon,
  color,
  isConvert = true,
  isTag = false,
  onCell,
  isOriginalId = false,
  needSteps,
  needZero = true,
  firstNameKey = 'firstName',
  lastNameKey = 'lastName',
  isName = false,
}) => {
  const classes = statusColor()
  const locale = loadStateFn('locale') || defaultValue
  const getLink = () =>
    ternary(
      onCell,
      <RPCLink
        href={`/talents?name=${encodeWithComp(value)}`}
        isBlank
        style={{ color: color || 'black' }}
        linkName={value}
      />,
      typeOf(value, NUMBER) ? (
        getFractionalNumber(value)
      ) : (
        <span className={classNames({ [classes.originalId]: isOriginalId })}>
          {value}
        </span>
      ),
    )

  return ternary(
    !isEmptyString(value),
    ternary(
      isBool(value),
      ternary(value, y, n),
      importStatus[value] ? (
        <div className={classes[lowerCase(value)]}>{importStatus[value]}</div>
      ) : isDate(value) ? (
        getDate(value, locale)
      ) : color ? (
        <span style={{ color }}>{getLink()}</span>
      ) : isArray(value) ? (
        isTag ? (
          length(value) ? (
            <RenderLabels {...{ value, classes }} />
          ) : (
            minusSymbol
          )
        ) : length(value) ? (
          value?.filter(Boolean).join(', ')
        ) : (
          minusSymbol
        )
      ) : isConvert && isNumber(value) ? (
        needZero ? (
          numberLocale(value, locale)
        ) : value ? (
          numberLocale(value, locale)
        ) : (
          minusSymbol
        )
      ) : onCell ? (
        getLink()
      ) : needSteps ? (
        value?.current_message
      ) : typeOf(value, NUMBER) ? (
        getFractionalNumber(value, 0)
      ) : isName ? (
        `${value?.[firstNameKey]} ${value?.[lastNameKey]}`
      ) : (
        value
      ),
    ),
    ternary(isIcon, children, minusSymbol),
  )
}

export default RenderCustom
