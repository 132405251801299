import {
  downloadExcel,
  downloadJSON,
  getReplacedData,
} from '../../container/common.container'
import {
  launchpadFormPath,
  launchpadOverviewFormPath,
} from '../../description/formPath.description'
import { userSettingsPath } from '../../description/settings.description'
import api from '../../utils/api'
import {
  errorStatusCodes,
  OBJECT,
  resetData,
  STRING,
} from '../../utils/constant'
import { formData } from '../../utils/formData'
import {
  checkUndefined,
  entries,
  equal,
  isArray,
  isEmptyString,
  keys,
  length,
  lowerCase,
  ternary,
  typeOf,
  values,
} from '../../utils/javascript'
import {
  CLEAR_LOADING_STATE,
  LOADING_CHANGE,
  RESET_REDUX,
  SET_API_DATA,
  SET_ERROR_INFO,
  SET_FORM_ERROR_INFO,
} from '../constants'
import {
  datasetOverview,
  optimizationSettings,
  selectDeleteMethod,
  selectPostMethod,
  selectPutMethod,
} from '../description'
import { setRowData } from './table.action'

export const apiAction =
  ({
    apiDetails,
    formPath,
    removeElements,
    files,
    payload = null,
    loaderFormPath,
    convertToJSON = false,
    responseType,
    trimming = false,
    controller = null,
    customAccept = null,
  }) =>
  async (dispatch, getState) => {
    const state = getState()
    if (loaderFormPath) dispatch(changeLoadingStatus(loaderFormPath, true))
    const { status, data, response, error, statusCode, errorCode, errorData } =
      await api({
        method: apiDetails?.method,
        endPoint: apiDetails?.endpoint,
        data: await formData({
          data: payload ||
            apiDetails?.payload || {
              ...state.form.formData?.[formPath?.parent]?.[formPath?.child],
            },
          removeElements,
          files,
          acceptJson: apiDetails?.acceptJson,
          formAttributeName: apiDetails?.formAttributeName,
          convertToJSON,
          trimming,
        }),
        isToken: apiDetails?.isToken,
        showToastMessage: apiDetails?.showToast,
        toastMessage: apiDetails?.toastMessage,
        responseType,
        ...(!isEmptyString(apiDetails?.showErrorToast) && {
          showErrorToastMessage: apiDetails?.showErrorToast,
        }),
        controller,
        customAccept,
      })

    if (status) {
      if (apiDetails.redirection) {
        dispatch({
          type: apiDetails.type,
          payload: data,
        })
      }
      if (apiDetails.fn)
        await apiDetails.fn({
          dispatch,
          data,
          apiState: state?.api,
          reduxState: state,
        })
      if (loaderFormPath) dispatch(changeLoadingStatus(loaderFormPath, false))
    } else if (loaderFormPath)
      dispatch(changeLoadingStatus(loaderFormPath, false))
    if (errorStatusCodes.includes(statusCode) && apiDetails?.errorFn) {
      await apiDetails?.errorFn({
        dispatch,
        error,
        reduxState: state,
        apiState: state?.api,
        statusCode,
        errorData,
      })
    }

    return { status, data, response, error, errorCode, statusCode }
  }

export const getApiDataAction =
  ({
    formPath,
    apiDetails,
    loaderFormPath = null,
    replaceData = false,
    isOverview = false,
    replaceFormPath = {},
    payload = null,
    isModify = false,
    modifyApiObj,
    reduxState = false,
    storeInitially = true,
    needFormPath = false,
    removeElements,
    files = null,
    convertToJSON = false,
    isClearOldState = false,
    defaultChildStateDataType = null,
    controller = null,
    defaultSortKey = null,
    defaultSort = false,
  }) =>
  async (dispatch, getState) => {
    const { parent, child, childObject, fn } = formPath
    const {
      parent: replaceParent,
      child: replaceChild,
      childObject: replaceChildObject,
    } = replaceFormPath
    const { status, data, error } = await dispatch(
      apiAction({
        apiDetails,
        loaderFormPath,
        payload,
        reduxState,
        ...(needFormPath && { formPath }),
        removeElements,
        files,
        convertToJSON,
        controller,
      }),
    )
    const state = { ...getState().api }
    let clonedParentData = { ...state?.[parent] }

    const dataM = data?.items || data
    let clonedObjectData = data

    const overviewFormPath = {
      parent: launchpadFormPath,
      child: launchpadOverviewFormPath,
    }

    if (fn) {
      clonedParentData = {
        ...clonedParentData,
        ...fn,
      }
    }

    if (isClearOldState) {
      dispatch(
        setApiDataAction(parent, {
          ...clonedParentData,
          [replaceChild || child]: defaultChildStateDataType,
        }),
      )
    }

    if (status) {
      if (defaultSort && isArray(dataM) && defaultSortKey) {
        clonedObjectData = dataM?.sort(
          (a, b) => new Date(b.baselineStart) - new Date(a.baselineStart),
        )
      }
      if (replaceData) {
        const clonedChildData = isArray(state?.[replaceParent]?.[replaceChild])
          ? [...state?.[replaceParent]?.[replaceChild]]
          : { ...state?.[replaceParent]?.[replaceChild] }
        const clonedReplaceObjectData = getReplacedData(clonedChildData, dataM)
        dispatch(setReplaceData(replaceParent, dataM))
        clonedObjectData = clonedReplaceObjectData
      }

      if (isModify && isArray(dataM)) {
        dataM?.map((obj) => {
          obj.time = obj?.timeScore.totalScore
          obj.fit = obj?.fitScore?.totalScore
          return null
        })
      }

      if (modifyApiObj) {
        dataM?.forEach((obj) => {
          if (isArray(modifyApiObj)) {
            modifyApiObj?.forEach(({ modifiedKey, key, labelKey }) => {
              obj[modifiedKey] = obj?.[key]?.map((val) => val?.[labelKey])
            })
          } else {
            obj[modifyApiObj?.modifiedKey] = obj?.[modifyApiObj?.key]?.map(
              (val) => val?.[modifyApiObj?.labelKey],
            )
          }
        })
      }

      if (storeInitially) {
        dispatch(
          setApiDataAction(parent, {
            ...clonedParentData,
            [replaceChild || child]: ternary(
              replaceChildObject || childObject,
              {
                ...clonedParentData[replaceChild || child],
                [replaceChildObject || childObject]: clonedObjectData,
              },
              clonedObjectData || dataM,
            ),
          }),
        )
      }
      if (isOverview) {
        dispatch(
          getApiDataAction({
            formPath: overviewFormPath,
            apiDetails: datasetOverview,
          }),
        )
      }
    } else {
      dispatch(
        setApiErrorAction(child, SET_ERROR_INFO, error?.response?.data, parent),
      )
      if (loaderFormPath) dispatch(changeLoadingStatus(loaderFormPath, false))
    }
  }

export const expandedRowApiDataAction =
  ({
    formPath,
    apiDetails,
    loaderFormPath = null,
    payload = null,
    storeInitially = true,
    removeElements,
  }) =>
  async (dispatch, getState) => {
    const { parent, child, childObject, fn } = formPath

    const { status, data, error } = await dispatch(
      apiAction({
        apiDetails,
        loaderFormPath,
        payload,
        removeElements,
      }),
    )
    const state = { ...getState().api }
    let clonedParentData = { ...state?.[parent] }

    const dataM = data?.items || data

    if (fn) {
      clonedParentData = {
        ...clonedParentData,
        ...fn,
      }
    }
    if (status) {
      if (storeInitially) {
        dispatch(
          setApiDataAction(parent, {
            ...clonedParentData,
            [child]: ternary(
              childObject,
              {
                ...clonedParentData[child],
                [childObject]: data,
              },
              dataM,
            ),
          }),
        )
      }
    } else {
      dispatch(
        setApiErrorAction(child, SET_ERROR_INFO, error?.response?.data, parent),
      )
      if (loaderFormPath) dispatch(changeLoadingStatus(loaderFormPath, false))
    }
  }

export const setApiDataAction = (formPath, data) => async (dispatch) => {
  dispatch({
    type: SET_API_DATA,
    payload: { [formPath]: data },
  })
}

export const addApiDataAction =
  ({
    formPath,
    apiObjSelection,
    handleToggle,
    insertAtFirst = true,
    files = [],
    removeElements = [],
    loaderFormPath = {},
    apiPath,
    active = null,
    queryValue = null,
    trimming = false,
    fileUuid = '',
    responseType,
  }) =>
  async (dispatch, getState) => {
    let { parent, child, formPayload } = formPath
    const { convertToJSON, apiPayload } = formPath

    const clonedGetQueryParam = {
      ...getState().form.queryString[parent]?.[child],
    }
    const clonedFormData = {
      ...getState().form.formData[parent]?.[child],
    }
    const queryParamKey = keys(clonedGetQueryParam)
      .map((key) => {
        if (clonedGetQueryParam[key]) return key
        return null
      })
      .filter(Boolean)
    const queryParamValue = clonedFormData[queryParamKey]

    const { status, data, error } = await dispatch(
      apiAction({
        apiDetails: selectPostMethod(
          queryValue || queryParamValue,
          active,
          fileUuid,
        )[apiObjSelection],
        formPath,
        removeElements,
        files,
        payload:
          formPayload && (apiPayload || getState().form.formData?.[parent]),
        loaderFormPath,
        convertToJSON,
        trimming,
        ...(responseType && { responseType }),
      }),
    )

    formPath = { parent, child, ...apiPath }
    parent = formPath?.parent
    child = formPath?.child
    formPayload = formPath.formPayload
    const { childObject } = formPath
    const clonedFormPath = isArray(getState().api[parent]?.[child])
      ? [...getState().api[parent]?.[child]]
      : { ...getState().api[parent]?.[child] }

    let state =
      childObject &&
      clonedFormPath?.[childObject] &&
      !isArray(childObject) &&
      isArray(clonedFormPath?.[childObject])
        ? [...clonedFormPath?.[childObject]]
        : clonedFormPath

    if (status) {
      if (isArray(state) && insertAtFirst) {
        state.unshift(data)
      }
      if (isArray(childObject)) {
        childObject?.forEach((val) => {
          if (state?.[val]) {
            state?.[val]?.items?.push(data)
          } else {
            state[val] = [data]
          }
        })
      } else if (!isArray(childObject) && isArray(state?.[childObject])) {
        state?.[childObject].push(data)
      }

      if (!childObject) {
        if (!isArray(state)) {
          state = data
        }
      } else if (!isArray(childObject)) {
        state = {
          [childObject]: data,
        }
      }

      dispatch(
        setApiDataAction(parent, {
          ...getState().api[parent],
          [child]: !equal(length(keys(state?.[parent]?.[child])))
            ? isArray(childObject)
              ? { ...state }
              : childObject
              ? {
                  ...getState().api[parent]?.[child],
                  [childObject]: [
                    ...(state?.[childObject] ?? isArray(state) ? state : []),
                  ],
                }
              : isArray(state)
              ? [...state]
              : { ...state }
            : equal(length(keys(state)))
            ? data
            : state,
        }),
      )

      dispatch(changeLoadingStatus(loaderFormPath || formPath, false))

      if (handleToggle) handleToggle()
    } else {
      dispatch(
        setDetailedError(
          parent,
          SET_FORM_ERROR_INFO,
          error?.response?.data?.details,
        ),
      )
      if (loaderFormPath) dispatch(changeLoadingStatus(loaderFormPath, false))
    }
  }

export const setReplaceData = (parent, data) => async (dispatch, getState) => {
  dispatch(
    setApiDataAction(parent, {
      ...getState().api[parent],
      [parent]: data,
    }),
  )
}

export const setDetailedError = (formPath, type, error) => async (dispatch) => {
  const errors = []

  error?.forEach((obj) => {
    values(obj).forEach((val) => errors.push(val))
  })

  dispatch(setApiErrorAction(formPath, type, errors))
}

export const deleteApiDataAction =
  (id, formPath, apiSelection, deleteChild = false, deleteChildPath = '') =>
  async (dispatch, getState) => {
    const { parent, child, childObject, deleteKey } = formPath
    const state = childObject
      ? [...getState()?.api?.[parent]?.[child]?.[childObject]?.items]
      : [...getState()?.api?.[parent]?.[child]]
    const { status } = await dispatch(
      apiAction({
        apiDetails: selectDeleteMethod(id)[apiSelection],
      }),
    )

    if (status) {
      const deletedData = state.findIndex((obj) =>
        equal(obj[deleteKey || 'uuid'], id),
      )
      if (~deletedData) {
        state.splice(deletedData, 1)
      }
      dispatch(
        setApiDataAction(parent, {
          ...getState().api[parent],
          [child]: ternary(
            childObject,
            {
              ...getState().api[parent][child],
              [childObject]: state,
            },
            state,
          ),
        }),
      )
      if (deleteChild) {
        dispatch(
          setApiDataAction(parent, {
            ...getState().api[parent],
            [deleteChildPath]: state,
          }),
        )
      }
    }
  }

export const multipleDeleteApiDataAction =
  (ids, formPath) => async (dispatch, getState) => {
    const { parent, child, childObject } = formPath
    const state = childObject
      ? [...getState().api[parent][child][childObject]]
      : [...getState().api[child]]

    ids.forEach(async (val) => {
      const { status } = await dispatch(
        apiAction({
          apiDetails: selectDeleteMethod(val)[parent],
          payload: ids,
        }),
      )
      if (status) {
        const deletedIndex = state.findIndex((obj) => equal(obj.uuid, val))
        if (~deletedIndex) {
          state.splice(deletedIndex, 1)
        }
        dispatch(
          setApiDataAction(parent, {
            ...getState().api[parent],
            [child]: ternary(
              childObject,
              {
                ...getState().api[parent][child],
                [childObject]: [...state],
              },
              [...state],
            ),
          }),
        )
      }
    })
  }

export const downloadAction =
  ({
    param,
    apiDetails,
    type,
    isReport,
    loaderPath,
    filename,
    payload,
    responseType = 'arraybuffer',
    isDownloadJSON = false,
    customAccept = null,
    fileType,
    toggle = () => {},
  }) =>
  async (dispatch) => {
    toggle()
    const { status, data, response } = await dispatch(
      apiAction({
        apiDetails,
        loaderFormPath: loaderPath,
        payload,
        responseType,
        customAccept,
      }),
    )

    if (customAccept) {
      type = fileType
    }
    if (status && !isDownloadJSON) {
      downloadExcel({ excel: data, filename, param, type, isReport, response })
    }
    if (status && isDownloadJSON) {
      downloadJSON({ json: data, filename, isReport, response })
    }

    dispatch(changeLoadingStatus(loaderPath, false))
  }

export const downloadExcelAction =
  ({
    param,
    apiDetails,
    type,
    isReport,
    loaderPath,
    filename,
    payload,
    responseType = 'arraybuffer',
  }) =>
  async (dispatch) => {
    const { status, data, response } = await dispatch(
      apiAction({
        apiDetails,
        loaderFormPath: loaderPath,
        payload,
        responseType,
      }),
    )
    if (status) {
      downloadExcel({ excel: data, filename, param, type, isReport, response })
    }

    dispatch(changeLoadingStatus(loaderPath, false))
  }

export const setApiErrorAction =
  (formPath, type, error, parent = null) =>
  async (dispatch) => {
    dispatch({
      type,
      payload: {
        [parent || formPath]: {
          [formPath]: {
            error,
          },
        },
      },
    })
  }

export const updateApiAction =
  ({
    formPath,
    apiDetailObject,
    updateVal,
    handleToggle,
    loaderFormPath,
    action,
    active,
    isPlan = false,
  }) =>
  async (dispatch, getState) => {
    const state = {
      ...getState().api,
    }
    let { parent, child } = formPath

    const { childObject, updateId, needId = true } = formPath

    const { apiObject, apiObjectKey, apiPayload } = apiDetailObject

    const clonedFormData = { ...getState()?.form?.formData?.[parent]?.[child] }

    const { status, data, error } = await dispatch(
      apiAction({
        apiDetails: selectPutMethod(
          ternary(
            !checkUndefined(apiObjectKey),
            lowerCase(apiObjectKey),
            needId && updateId,
          ),
          action,
          active,
        )[apiObject],
        formPath,
        payload: apiPayload,
        loaderFormPath,
      }),
    )
    formPath = { childObject, parent, child, ...apiDetailObject }
    parent = formPath?.parent
    child = formPath?.child
    if (status) {
      const clonedFormPath = isArray(state[parent][child])
        ? state[parent][child]
        : state[parent][child]
      const clonedObjectData = childObject
        ? isArray(clonedFormPath[childObject])
          ? [...clonedFormPath[childObject]]
          : { ...clonedFormPath[childObject] }
        : [...clonedFormPath]

      const index = clonedFormPath[childObject]?.items
        ? clonedObjectData?.items?.findIndex((obj) => equal(obj.id, updateId))
        : clonedObjectData?.findIndex((obj) =>
            equal(isPlan ? obj?.uuid : obj.id, updateId),
          )

      const updateValue = (clonedObjectData) => {
        if (typeOf(updateVal, OBJECT)) {
          clonedObjectData[index] = ternary(
            typeOf(data, STRING),
            clonedFormData,
            data,
          )
        } else if (apiObjectKey) {
          clonedObjectData[index][apiObjectKey] = updateVal
        } else {
          clonedObjectData[index] = data
        }
      }

      if (clonedObjectData.items) {
        updateValue(clonedObjectData.items)
      } else {
        updateValue(clonedObjectData)
      }

      dispatch(
        setApiDataAction(parent, {
          ...state[parent],
          [child]: childObject
            ? {
                ...state[parent][child],
                [childObject]: isArray(clonedObjectData)
                  ? [...clonedObjectData]
                  : { ...clonedObjectData },
              }
            : isArray(clonedObjectData)
            ? [...clonedObjectData]
            : { ...clonedObjectData },
        }),
      )
      if (handleToggle) handleToggle()
    } else {
      dispatch(
        setDetailedError(
          parent,
          SET_FORM_ERROR_INFO,
          error?.response?.data?.details,
        ),
      )
    }
  }

export const updateMultipleApiAction =
  ({
    apiPath,
    apiDetailObject,
    action,
    loaderFormPath,
    updateFlag = false,
    closeModal,
    modalPath,
    active,
    callCardData,
    cardApiDetails,
    handleSuccessUpdateAction = () => {},
  }) =>
  async (dispatch, getState) => {
    const state = { ...getState().api }
    const { parent, child, updateId, childObject } = apiPath
    const { apiObject, apiObjectKey, apiPayload, apiModified } = apiDetailObject
    const { status, error, data } = await dispatch(
      apiAction({
        apiDetails: selectPutMethod(lowerCase(apiObjectKey), action, active)[
          apiObject
        ],
        formPath: apiObject,
        payload: apiPayload,
        loaderFormPath,
      }),
    )

    if (status) {
      const clonedFormPath = isArray(state?.[parent]?.[child])
        ? [...state?.[parent]?.[child]]
        : { ...state?.[parent]?.[child] }

      const clonedData = childObject
        ? [
            ...(clonedFormPath?.[childObject]?.items ||
              clonedFormPath?.[childObject]),
          ]
        : clonedFormPath

      updateId?.forEach((ind) => {
        if (updateFlag) {
          clonedData[ind][apiObjectKey] = ternary(
            action,
            equal(action, 'add'),
            !clonedData[ind][apiObjectKey],
          )
        } else if (isArray(data)) {
          data?.forEach((val) =>
            entries(val).forEach(([k1, obj]) => {
              const pathIndex =
                clonedData[
                  clonedData?.findIndex((val) => equal(val?.id, Number(k1)))
                ]
              if (pathIndex?.[apiObject]) {
                pathIndex[apiObject] = obj
                pathIndex[apiModified] = obj?.map((val) => val?.name)
              }
            }),
          )
        }
      })

      dispatch(
        setApiDataAction(parent, {
          ...getState().api[parent],
          [child]: ternary(
            childObject,
            {
              ...getState().api[parent][child],
              [childObject]: getState().api?.[parent]?.[child]?.[childObject]
                ?.items
                ? {
                    ...getState().api?.[parent]?.[child]?.[childObject],
                    items: clonedData,
                  }
                : clonedData,
            },
            clonedData,
          ),
        }),
      )

      handleSuccessUpdateAction()

      if (callCardData) {
        dispatch(getApiDataAction(cardApiDetails))
      }
      if (closeModal) dispatch(setRowData(modalPath, {}))
    } else {
      dispatch(
        setDetailedError(
          parent,
          SET_FORM_ERROR_INFO,
          error?.response?.data?.details,
        ),
      )
    }
    dispatch(changeLoadingStatus(loaderFormPath, false))
  }

export const changeLoadingStatus =
  (formPath, status) => async (dispatch, getState) => {
    if (formPath) {
      const { parent, child, childObject, childObjectIndex } = formPath
      if (parent) {
        dispatch({
          type: LOADING_CHANGE,
          payload: {
            [parent]: {
              ...getState().api?.loader?.[parent],
              [child]: childObject
                ? {
                    ...getState().api?.loader?.[parent]?.[child],
                    [childObject]: childObjectIndex
                      ? {
                          ...getState().api?.loader?.[parent]?.[child]?.[
                            childObject
                          ],
                          [childObjectIndex]: status,
                        }
                      : status,
                  }
                : status,
            },
          },
        })
      }
    }
  }
export const clearLoadingStatus = (formPath) => async (dispatch, getState) => {
  if (formPath) {
    const { parent, child, childObject, childObjectIndex } = formPath
    const cloneLoadingStatus = { ...getState().api?.loader }
    if (parent && !child && !childObject && !childObjectIndex) {
      delete cloneLoadingStatus?.[parent]
    }
    if (parent && child && !childObject && !childObjectIndex) {
      delete cloneLoadingStatus?.[parent]?.[child]
    }
    if (parent && child && childObject && !childObjectIndex) {
      delete cloneLoadingStatus?.[parent]?.[child]?.[childObject]
    }
    if (parent && child && childObject && childObjectIndex) {
      delete cloneLoadingStatus?.[parent]?.[child]?.[childObject]?.[
        childObjectIndex
      ]
    }

    if (parent) {
      dispatch({
        type: CLEAR_LOADING_STATE,
        payload: {
          [parent]: {
            ...cloneLoadingStatus?.[parent],
          },
        },
      })
    } else {
      dispatch({
        type: CLEAR_LOADING_STATE,
        payload: {
          ...cloneLoadingStatus,
        },
      })
    }
  }
}

export const setLabelCategory =
  ({ apiPath, type }) =>
  async (dispatch, getState) => {
    const { parent, child } = apiPath
    dispatch(
      setApiDataAction(parent, {
        ...getState().api?.[parent],
        [child]: type,
      }),
    )
  }

export const changeActiveState =
  ({ formPath, step }) =>
  async (dispatch, getState) => {
    const { parent, child, childObject } = formPath
    dispatch(
      setApiDataAction(parent, {
        ...getState()?.api?.[parent],
        [child]: childObject
          ? {
              ...getState()?.api?.[parent]?.[child],
              [childObject]: step,
            }
          : step,
      }),
    )
  }

export const deleteFromAdmin =
  ({ loaderFormPath, param }) =>
  async (dispatch) => {
    const { apiSelection, ...loaderPath } = loaderFormPath
    await dispatch(
      apiAction({
        apiDetails: selectDeleteMethod(param)[resetData],
        loaderFormPath: loaderPath,
      }),
    )
    const settingsApiParams = {
      formPath: userSettingsPath,
      apiDetails: optimizationSettings,
      loaderFormPath: userSettingsPath,
      needFormPath: true,
    }
    dispatch(getApiDataAction(settingsApiParams))
  }

export const setDataAPIReducer =
  (formPath, data) => async (dispatch, getState) => {
    const { parent, child, childObject } = formPath
    const clonedState = { ...getState()?.api }
    const payload = {
      ...clonedState?.[parent],
      [child]: {
        ...clonedState?.[parent]?.[child],
        [childObject]: data,
      },
    }
    await dispatch(
      setApiDataAction(parent, {
        ...payload,
      }),
    )
  }
export const setKPIDataAPIReducer =
  (formPath, data) => async (dispatch, getState) => {
    const { parent, child } = formPath
    const clonedState = { ...getState()?.api }
    const payload = {
      ...clonedState?.[parent],
      [child]: isArray(data)
        ? data
        : {
            ...clonedState?.[parent]?.[child],
            ...data,
          },
    }
    await dispatch(
      setApiDataAction(parent, {
        ...payload,
      }),
    )
  }

export const clearReduxState = () => async (dispatch) => {
  dispatch({ type: RESET_REDUX })
}

export const updateTableDataAction =
  ({ tablePath, apiDetailObject, uuid }) =>
  async (dispatch) => {
    const { updateId } = tablePath
    const { apiObject, apiPayload } = apiDetailObject
    await dispatch(
      apiAction({
        apiDetails: selectPutMethod(uuid, updateId)[apiObject],
        payload: apiPayload,
      }),
    )
  }

export const updateTableBulkDataAction = async ({ apiDetailObject, uuid }) => {
  const { apiObject, apiPayload } = apiDetailObject
  const { data } = await api({
    endPoint: selectPutMethod(uuid)[apiObject]?.endpoint,
    method: selectPutMethod(uuid)[apiObject]?.method,
    data: apiPayload,
  })

  return { data }
}
