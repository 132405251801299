import { Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import RPCColorPicker from './elements/RPCColorPicker'

const useStyles = makeStyles({
  colorInput: {
    paddingLeft: 16,
    paddingRight: 0,
  },
  colorTab: {
    marginLeft: 0,
    minWidth: 0,
  },
})

const RPCTab = ({ tabColor, ...props }) => {
  const classes = useStyles()

  return tabColor ? (
    <div>
      <RPCColorPicker
        color={tabColor}
        formPath={null}
        onChangeComplete={() => {}}
        className={classes.colorInput}
        forceDisable
      />
      <Tab className={classes.colorTab} {...props} />
    </div>
  ) : (
    <Tab {...props} />
  )
}

export default RPCTab
