import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  link: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
      '& $linkIcon, & span': {
        color: theme.palette.primary.main,
      },
    },
  },
  linkActive: {
    backgroundColor: theme.palette.background.light,
  },
  linkNested: {
    paddingLeft: 0,
    '& div span': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& .MuiListItemText-root': {
      flex: 'inherit',
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    transition: theme.transitions.create('color'),
    width: 24,
    minWidth: 35,
    display: 'flex',
    justifyContent: 'center',
    color: `${theme.palette.text.secondary}`,
    '& svg': {
      width: '15pt',
      height: '15pt',
    },
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    padding: 0,
    color: `#000`,
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Jura',
  },
  linkTextActive: {
    color: theme.palette.primary.main,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 10,
    '& svg': {
      height: 18,
      width: 18,
    },
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: '#D8D8D880',
  },
}))
