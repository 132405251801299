import axios from 'axios'

import {
  defaultType,
  multipartFormDataType,
  STRING,
  successStatus,
  urlEncodedType,
} from './constant'
import { checkIncludes, equal, ternary, typeOf } from './javascript'
import { showToast } from './toastService'

const api = ({
  method,
  endPoint,
  data,
  showToastMessage = false,
  showErrorToastMessage = true,
  toastMessage = '',
  urlencoded = false,
  responseType = '',
  controller = null,
  customAccept = null,
}) =>
  new Promise((resolve) => {
    const headers = {
      'Content-Type': ternary(
        urlencoded,
        urlEncodedType,
        data instanceof FormData ? multipartFormDataType : defaultType,
      ),
      ...(customAccept && { Accept: customAccept }),
    }
    axios({
      method,
      url: `${process.env.REACT_APP_API_URL}/${endPoint}`,
      data,
      headers,
      responseType,
      signal: controller?.signal || null,
    })
      .then((response) => {
        if (showToastMessage && successStatus.includes(response.status)) {
          showToast(
            ternary(!toastMessage, response.data.message, toastMessage),
            'success',
          )
        }
        return resolve({
          data: response.data,
          status: checkIncludes(response.status, successStatus),
          response,
        })
      })
      .catch((error) => {
        if (
          showToastMessage &&
          showErrorToastMessage &&
          typeOf(error?.response?.data?.error_message, STRING)
        ) {
          showToast(
            ternary(
              equal(error?.response?.data?.error_code, 500) ||
                equal(error?.response?.data?.error_code, 500),
              'Server not responding',
              error?.response?.data?.error_message,
            ),
            'error',
          )
        }
        return resolve({
          status: false,
          error:
            error?.response?.data?.error_message || error?.response?.statusText,
          errorCode: error?.response?.data?.error_code,
          statusCode: error?.response?.status,
          errorData: error?.response?.data,
        })
      })
  })

export default api
