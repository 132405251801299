import { memo } from 'react'

import RPCSuspense from '../shared/RPCSuspense'
import RenderRoutes from './RenderRoutes'
import routes from './routes.description'

const Routes = ({ isAuth }) => (
  <RPCSuspense>
    <RenderRoutes routes={routes} isLogin={isAuth} />
  </RPCSuspense>
)

export default memo(Routes)
