import classNames from 'classnames'
import React from 'react'

const RPCImage = ({
  src,
  alt,
  extraClassName,
  onClick,
  style,
  role = 'presentation',
  name,
}) => (
  <img
    className={classNames(extraClassName)}
    {...{
      alt,
      src,
      style,
      onClick,
      role,
      name,
    }}
    onKeyDown={onClick}
    aria-hidden
  />
)
export default RPCImage
