import { combineReducers } from 'redux'

import api from './api.reducer'
import app from './app.reducer'
import form from './form.reducer'
import jobDetails from './jobDetails.reducer'
import kpi from './kpi.reducer'
import modal from './modal.reducer'
import tab from './tab.reducer'
import table from './table.reducer'

export default combineReducers({
  app,
  form,
  api,
  table,
  tab,
  modal,
  kpi,
  jobDetails,
})
