import {
  EXPANDED_ROW_INDEX,
  EXPANDED_ROW_INDEXES,
  SET_BULK_DATA,
  SET_CELL_DATA,
  SET_CHECK_FLAGS,
  SET_COL_DATA,
  SET_COUNT_FLAG,
  SET_FILTER_DATA,
  SET_FILTER_OPEN,
  SET_FILTER_VALUES,
  SET_GRAPH_DATA,
  SET_PAGINATION,
  SET_PERIOD,
  SET_REVIEW_MODE,
  SET_ROW_DATA,
  SET_SCROLL_VIEW,
  SET_SEARCH_VALUE,
  SET_SORT_ORDER,
  SET_TABLE_TITLE,
  SET_VIEW_FLAGS,
} from '../constants'

const initialState = {
  columns: {},
  filterData: {},
  rowData: {},
  filterValues: {},
  graphData: {},
  reOrderCols: {},
}

const table = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: payload,
      }
    case SET_ROW_DATA:
      return {
        ...state,
        rowData: payload,
      }
    case SET_BULK_DATA:
      return {
        ...state,
        bulkData: payload,
      }
    case SET_CELL_DATA:
      return {
        ...state,
        rowData: payload,
      }
    case SET_FILTER_VALUES:
      return {
        ...state,
        filterValues: payload,
      }
    case SET_GRAPH_DATA:
      return {
        ...state,
        graphData: {
          ...state.graphData,
          ...payload,
        },
      }
    case EXPANDED_ROW_INDEX:
    case EXPANDED_ROW_INDEXES:
      return { ...state, ...payload }
    case SET_CHECK_FLAGS:
      return { ...state, checkFlags: payload }
    case SET_PERIOD:
      return { ...state, period: payload }
    case SET_VIEW_FLAGS:
      return { ...state, viewFlags: payload }
    case SET_TABLE_TITLE:
      return { ...state, title: payload }
    case SET_COUNT_FLAG:
      return { ...state, ...payload }
    case SET_SORT_ORDER:
      return { ...state, ...payload }
    case SET_SEARCH_VALUE:
      return { ...state, ...payload }
    case SET_SCROLL_VIEW:
      return { ...state, ...payload }
    case SET_PAGINATION:
      return { ...state, ...payload }
    case SET_COL_DATA:
      return {
        ...state,
        reOrderCols: { ...state.reOrderCols, ...payload },
      }
    case SET_REVIEW_MODE:
      return { ...state, ...payload }
    case SET_FILTER_OPEN:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default table
