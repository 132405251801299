import {
  CLEAR_FORM,
  CLEAR_FORM_DATA,
  IS_REQUIRED,
  IS_VALID,
  ON_FORM_CHANGE,
  SET_DISABLED,
  SET_FILE_DATA,
  SET_FLAG,
  SET_FORM_ERROR_INFO,
  SET_FORM_VALUES,
} from '../constants'

export const initialState = {
  isValid: {},
  formData: {},
  fieldErrors: {},
  fieldRequired: {},
  fieldLocalStorage: {},
  queryString: {},
  pattern: {},
  errors: {},
  formSettled: {},
  nestedForm: {},
}

const formReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ON_FORM_CHANGE:
      return {
        ...state,
        ...payload,
        formData: payload.formData,
        fieldErrors: payload.fieldErrors,
        message: '',
      }

    case SET_FILE_DATA:
      return {
        ...state,
        formData: payload.formData,
        message: '',
      }

    case IS_REQUIRED:
      return {
        ...state,
        fieldRequired: payload.fieldRequired,
        queryString: payload.queryString,
        isValid: payload.updatedIsValid,
        formData: payload.formData,
        fieldDisabled: payload.fieldDisabled,
        fieldErrors: payload.fieldErrors,
        pattern: payload.pattern,
        fieldLocalStorage: payload.fieldLocalStorage,
        formSettled: payload.formSettled,
        nestedForm: payload.nestedForm,
      }

    case CLEAR_FORM:
      return {
        ...state,
        ...payload,
      }

    case SET_FORM_VALUES:
      return {
        ...state,
        formData: payload.clonedFormData,
      }

    case IS_VALID:
      return {
        ...state,
        isValid: payload.isValid,
      }

    case SET_FORM_ERROR_INFO:
      return {
        ...state,
        errors: payload,
      }
    case SET_DISABLED:
      return {
        ...state,
        fieldDisabled: {
          ...state.fieldDisabled,
          ...payload,
        },
      }
    case CLEAR_FORM_DATA:
      return {
        ...state,
        ...payload,
      }
    case SET_FLAG:
      return { ...state, flag: payload }
    default:
      return state
  }
}

export default formReducer
