import { useSelector } from 'react-redux'

import {
  missionDetails,
  missionDetailsPath,
  missionFormPath,
} from '../../description/formPath.description'
import {
  baselineForm,
  BASELINES,
  form,
  localKey,
} from '../../description/missionControl/mission.control.description'
import { useDispatch } from '../../hooks/use-dispatch'
import {
  addApiDataAction,
  updateApiAction,
} from '../../redux/actions/api.action'
import { clearForm } from '../../redux/actions/form.action'
import {
  editBaseline,
  startBaseline,
  startOptimization,
} from '../../utils/constant'
import { setButtons } from '../common.container'
import { apiCallsContainer } from './apiCalls.container'

export const exMissionFormPath = {
  parent: missionFormPath,
  child: missionDetails,
}

const formPath = {
  parent: missionFormPath,
  child: missionFormPath,
  formPayload: true,
}

export const addMissionControlContainer = ({
  handleToggle,
  active,
  rowData,
}) => {
  apiCallsContainer(localKey)
  const { dispatch } = useDispatch()
  const isFormLoading = useSelector(
    (state) => state.api.loader?.mission?.mission,
  )

  const baselineFormData = useSelector(
    (state) => state.form?.formData?.[formPath.parent],
  )

  const isBaselineLoading = useSelector(
    (state) => state?.api?.loader?.mission?.baselineDetails,
  )

  const handleCancel = () => {
    handleToggle()
    dispatch(clearForm())
  }
  const { buttonAttributes } = setButtons(startOptimization, handleCancel)

  const { buttonAttributes: baselineButtonAttributes } = setButtons(
    startBaseline,
    handleCancel,
  )

  const { buttonAttributes: baselineEditButtonAttributes } = setButtons(
    editBaseline,
    handleCancel,
  )

  const onSubmit = (e) => {
    e.preventDefault()
    const apiPath = {
      ...exMissionFormPath,
      ...(active === BASELINES && { child: 'baselineDetails' }),
    }
    const loaderFormPath = {
      parent: missionFormPath,
      child: missionFormPath,
      ...(active === BASELINES && { child: 'baselineDetails' }),
    }
    dispatch(
      addApiDataAction({
        formPath:
          active === BASELINES
            ? {
                ...formPath,
                apiPayload: { ...baselineFormData?.[missionDetailsPath] },
              }
            : formPath,
        apiObjSelection: active === BASELINES ? 'baseline' : missionFormPath,
        handleToggle,
        insertAtFirst: true,
        apiPath,
        loaderFormPath,
        trimming: true,
      }),
    )
  }

  const onEditSubmit = (e) => {
    e.preventDefault()
    const apiPath = {
      ...exMissionFormPath,
      ...(active === BASELINES && { child: 'baselineDetails' }),
    }

    const loaderFormPath = {
      parent: missionFormPath,
      child: missionFormPath,
      ...(active === BASELINES && { child: 'baselineDetails' }),
    }

    const apiDetailObject = {
      apiObject: active === BASELINES ? 'baseline' : missionFormPath,
      apiPayload: { ...baselineFormData?.[missionDetailsPath] },
    }

    dispatch(
      updateApiAction({
        formPath:
          active === BASELINES
            ? {
                ...formPath,
                child: 'baselineDetails',
                updateId: rowData?.rowData?.uuid,
              }
            : formPath,
        apiDetailObject,
        handleToggle,
        loaderFormPath,
        apiPath,
        isPlan: true,
      }),
    )
  }
  return {
    formAttributes: form,
    baselineForm,
    buttonAttributes,
    baselineButtonAttributes,
    baselineEditButtonAttributes,
    missionFormPath,
    onSubmit,
    onEditSubmit,
    isFormLoading,
    isBaselineLoading,
  }
}
