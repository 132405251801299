import { IS_MODAL_TOGGLE } from '../constants'

export default (state = {}, { type, payload }) => {
  switch (type) {
    case IS_MODAL_TOGGLE:
      return { ...state, ...payload }

    default:
      return state
  }
}
