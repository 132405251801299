import { createTheme } from '@material-ui/core'
import toast from 'react-hot-toast'

const theme = createTheme()

export const showToast = (
  message = 'Something went wrong',
  messageType = 'error',
  duration = 5000,
) =>
  toast(message, {
    type: messageType,
    style: {
      border: `1px solid ${theme.palette[messageType].main}`,
      padding: '0 16px',
      color: `${theme.palette[messageType].main}`,
    },
    iconTheme: {
      primary: `${theme.palette[messageType].main}`,
      secondary: `#FFF`,
    },
    duration,
  })
