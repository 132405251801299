import { IconButton } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import classNames from 'classnames'
import React, { memo } from 'react'

import { headerContainer } from '../../../container/layout/header.container'
import { ternary } from '../../../utils/javascript'

const HeaderMenuIcon = memo(({ classes }) => {
  const { changeSidebarOpened, sidebarOpened } = headerContainer()

  return (
    <IconButton
      color="inherit"
      onClick={changeSidebarOpened}
      className={classNames(
        classes.headerMenuButtonSandwich,
        classes.headerMenuButtonCollapse,
      )}
    >
      {ternary(
        sidebarOpened,
        <MenuOpenIcon
          classes={{
            root: classNames(classes.headerIcon, classes.headerIconCollapse),
          }}
          titleAccess="Click to close"
        />,
        <MenuIcon
          classes={{
            root: classNames(classes.headerIcon, classes.headerIconCollapse),
          }}
        />,
      )}
    </IconButton>
  )
})

export default HeaderMenuIcon
