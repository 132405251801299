import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'

import { ternary } from '../../utils/javascript'

const RenderDropdown = ({
  value,
  label,
  index,
  change,
  data,
  defaultOption,
  children,
}) => (
  <>
    <FormControl>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        value={
          children
            ? ternary(value, value, defaultOption)
            : ternary(value, value[children], defaultOption)
        }
        onChange={(e) => change(e?.target?.value, index)}
      >
        <MenuItem value={defaultOption} selected>
          {defaultOption}
        </MenuItem>
        {data?.map((val, i) => (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </>
)

export default RenderDropdown
