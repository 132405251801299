export const COOKIE_CONSENT_KEY = 'cookieConsent'

export const COOKIE_CONSENT_ESSENTIAL_BUTTON_TEXT =
  'Accept Only Essential cookies'

export const COOKIE_CONSENT_NON_ESSENTIAL_BUTTON_TEXT = 'Accept all cookies'

export const COOKIE_CONSENT_EXPIRATION_TIMEOUT = 365

export const COOKIE_CONSENT_TEXT_INTRO =
  'This website uses cookies to enhance the user experience. You can choose to accept only essential cookies or allow all cookies to improve our website and services. We will not activate any non-essential cookies without your consent.'

export const COOKIE_CONSENT_TEXT_TYPES = 'Types of Cookies:'

export const COOKIE_CONSENT_TEXT_ESSENTIAL_TITLE =
  'Essential Cookies (Necessary):'

export const COOKIE_CONSENT_TEXT_ESSENTIAL_DESCRIPTION =
  'These cookies are essential for the proper functioning of our website. They enable basic features such as page navigation and access to secure  areas of the website. The site cannot function properly without these  cookies.'

export const COOKIE_CONSENT_TEXT_OPTIONAL_TITLE =
  'Non-Essential Cookies (Optional):'

export const COOKIE_CONSENT_TEXT_OPTIONAL_DESCRIPTION =
  'We use analytics cookies to collect data about your visit. This data is linked to your user login and helps us understand how you interact with  our website. By consenting to these cookies, you agree to allow us to collect and process your personal data to improve our services and provide a personalized experience.'
