export const admin = 'admin'
export const dataReset = 'data-reset'
export const onlyWO = 'only_wo'
export const deleteAllData =
  'Delete all data (Saved filters are not deleted, they need to be manually deleted from the respective pages, e.g. KPI overview).'
export const deleteOnlyWO = 'Delete only work opportunity data'
export const deleteConfirmMsg = {
  true: 'Are you sure want to delete only work opportunity data?',
  false: 'Are you sure want to delete all data?',
}
export const SelectYourOption = 'Select your option'

export const radioFormData = ({ classes }) => [
  { label: deleteOnlyWO, value: 'true', className: classes.radioBtnView },
  {
    label: deleteAllData,
    value: 'false',
    className: classes.radioBtnView,
    // selected: true,
  },
]

export const formPath = {
  parent: admin,
  child: dataReset,
  childObject: onlyWO,
}

const formAttributes = {
  default1: {
    formPath: {
      parent: admin,
      child: dataReset,
    },
    formAttributes: {
      only_wo: {
        type: 'radio',
        label: [deleteAllData, deleteOnlyWO],
        value: 'true',
        isRequired: true,
        error: false,
      },
    },
  },
}

export const form = [{ type: null, fields: [formAttributes] }]
