import React from 'react'

import { groupNotFound } from '../description/login.description'
import NotFound from './NotFound'

const GroupNotFound = () => (
  <NotFound
    {...{
      needButton: false,
      notFoundMessage: groupNotFound,
      subMessage: [
        'Your account must be configured to use MatchingCore.',
        'Please contact support@aspaara.com to configure your account.',
      ],
      fontBold: true,
    }}
  />
)

export default GroupNotFound
