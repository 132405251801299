import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

import { equal } from '../../utils/javascript'
import { activeDot, dots } from '../description/suggestionHeader.description'

const useStyles = makeStyles((theme) => ({
  dot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    margin: theme.spacing(0.5),
    cursor: 'pointer',
    transition: 'all 0.3s',
  },
  redDot: {
    backgroundColor: '#ff5454',
    opacity: '0.5',
    border: '1px solid transparent',
  },
  greyDot: {
    backgroundColor: '#9f9f9f',
    border: '1px solid transparent',
  },
  greenDot: {
    backgroundColor: '#96fb9f',
    border: '1px solid transparent',
  },
  activeDot: {
    transform: 'scale(1.5)',
    opacity: '1',
    '&$redDot': {
      width: 15,
      height: 15,
      borderColor: '#ffb0b0',
      boxShadow: '0 0 0 2px #fff inset',
    },
    '&$greyDot': {
      width: 15,
      height: 15,
      borderColor: '#a9a9a9',
      boxShadow: '0 0 0 2px #fff inset',
    },
    '&$greenDot': {
      width: 15,
      height: 15,
      borderColor: '#00ff17',
      boxShadow: '0 0 0 2px #fff inset',
    },
  },
}))

const AcceptanceDots = ({
  openConfirmModal,
  data,
  disabledTitle = 'This booking has been deleted and will not be applied when submitting the optimization',
  isHeader = false,
  disabled,
  onChange = () => null,
}) => {
  const classes = useStyles()
  if (disabled) {
    return dots.map((obj, ind) => (
      <span
        className={classNames(classes.dot, classes[obj.className])}
        key={`dots-${ind}`}
        title={`${disabledTitle}`}
        aria-hidden
      />
    ))
  }
  return dots.map((obj, ind) => (
    <span
      className={classNames(classes.dot, classes[obj.className], {
        [classes.activeDot]: equal(activeDot[data?.acceptanceState], obj.type),
      })}
      onClick={() => {
        if (!isHeader) onChange(obj.type)
        openConfirmModal(ind)
      }}
      key={`dots-${ind}`}
      title={`${obj.tooltipTitle}${isHeader ? ' all' : ''}`}
      aria-hidden
    />
  ))
}

export default AcceptanceDots
