import { OBJECT, STRING } from './constant'
import { entries, isArray, keys, ternary, typeOf } from './javascript'

export const formData = async ({
  data = {},
  removeElements = [],
  files,
  acceptJson,
  formAttributeName = null,
  convertToJSON = false,
  trimming = false,
}) => {
  const form = new FormData()
  const trimFunc = (data) => {
    keys(data).forEach((k) => {
      if (typeOf(data?.[k], OBJECT) && !isArray(data?.[k])) {
        trimFunc(data?.[k])
      } else if (data?.[k] && typeOf(data?.[k], STRING)) {
        data[k] = data?.[k]?.trim()
      }
    })
    return data
  }
  if (removeElements) removeElements.forEach((element) => delete data[element])
  if (acceptJson) {
    if (trimming) {
      const modifiedData = { ...data }
      return trimFunc(modifiedData)
    }
    return data
  }
  if (files)
    files.forEach((file) => form.append(formAttributeName || 'files', file))
  entries(data).map(
    ([key, value]) =>
      value &&
      form.append(
        key,
        ternary(
          typeOf(value, OBJECT) && convertToJSON,
          JSON.stringify(value),
          value,
        ),
      ),
  )
  return form
}
