import {
  CLEAR_LOADING_STATE,
  EDIT_CHANGE,
  LOADING_CHANGE,
  RESET_REDUX,
  SET_API_DATA,
  SET_CURRENT_STEP,
  SET_ERROR_INFO,
} from '../constants'

export const initialState = {
  errors: {},
  loader: {},
  edit: {},
  description: {},
  tab: {},
  step: {},
}

const api = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_CHANGE:
      return { ...state, edit: { ...state.edit, ...payload } }
    case LOADING_CHANGE:
      return { ...state, loader: { ...state.loader, ...payload } }
    case CLEAR_LOADING_STATE:
      return { ...state, loader: { ...state.loader, ...payload } }
    case SET_API_DATA:
      return { ...state, ...payload }
    case SET_ERROR_INFO:
      return { ...state, errors: payload }
    case SET_CURRENT_STEP:
      return { ...state, steps: { ...state.step, ...payload } }
    case RESET_REDUX:
      return { ...initialState }
    default:
      return state
  }
}

export default api
