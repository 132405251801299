import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  exportDropdown: {
    '& .MuiMenu-paper': {
      minWidth: 170,
    },
    '& .MuiButtonBase-root': {
      justifyContent: 'space-between',
    },
  },
  exportBtn: {
    verticalAlign: 'middle',
  },

  headerMenu: {
    marginTop: theme.spacing(7),
  },
  setloader: {
    display: 'flex',
    justifyContent: 'flex-end',
    float: 'right',
    textAlign: 'right',
  },
}))

export default useStyles
