import { useEffect } from 'react'
import { getCookieConsentValue } from 'react-cookie-consent'
import ReactPiwik from 'react-piwik'
import { useSelector } from 'react-redux'

import routes, { privateRoutes } from '../../routing/routes.description'
import { HOURS_IN_A_YEAR } from '../../utils/constant'

export const matomoContainer = ({ location }) => {
  const piwik = useSelector((state) => state.app?.piwik)
  const { user, isAuth } = useSelector((state) => state.app)
  const group = useSelector((state) => state.app.group)

  const validRoute = routes.map((route) => route.path).flat()
  const validPrivateRoute = privateRoutes.map((route) => route.path)
  const isCookieConsent = getCookieConsentValue('cookieConsent')

  useEffect(() => {
    if (piwik) {
      ReactPiwik.push(['requireCookieConsent'])
      if (isCookieConsent === 'true') {
        ReactPiwik.push(['rememberCookieConsentGiven', HOURS_IN_A_YEAR])
      }
      if (!validRoute?.includes(location.pathname)) return
      if (!isAuth && validPrivateRoute.includes(location.pathname)) return
      if (isAuth && !validPrivateRoute.includes(location.pathname)) return
      if (
        isAuth &&
        user?.username &&
        group?.name &&
        isCookieConsent === 'true'
      ) {
        ReactPiwik.push(['setUserId', `${user?.username} | ${group?.name}`])
      }
      ReactPiwik.push(['setDocumentTitle', 'MatchingCore Console'])
      if (location.pathname && window && window.location) {
        const currentUrl = window && window.location ? window.location.href : ''
        ReactPiwik.push(['setCustomUrl', currentUrl])

        ReactPiwik.push(['trackPageView'])
      }
    }
  }, [piwik, location.pathname, isCookieConsent])
}
