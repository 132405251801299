import { fullWidth, notEmptyOrNull } from '../utils/constant'
import { loginFormPath } from './formPath.description'

export const formAttributes = {
  default1: {
    formPath: {
      parent: loginFormPath,
      child: loginFormPath,
    },
    formAttributes: {
      username: {
        label: 'Email Address',
        type: 'text',
        isRequired: true,
        fullWidth,
        pattern: notEmptyOrNull,
        error: ['Username is required', 'Blank space not allowed'],
      },
      password: {
        label: 'Password',
        type: 'password',
        isRequired: true,
        fullWidth,
        pattern: notEmptyOrNull,
        error: [
          'Password is required',
          'Password must contain at least eight characters!',
        ],
      },
    },
  },
}

export const form = [
  {
    type: null,
    fields: [{ ...formAttributes }],
  },
]

export const palette = [
  '#000',
  '#3b5998',
  '#ff7400',
  '#59C8B1',
  '#4D83B5',
  '#E3E3E3',
]

export const groupNotFound = 'Login successful.'
