import FlagIcon from '../../shared/icons/FlagIcon'
import TalentIcon from '../../shared/icons/TalentIcon'
import WorkOpportunity from '../../shared/icons/WorkOpportunity'
import {
  accept,
  fullWidth,
  MIN_VALUE,
  notEmptyOrNull,
  yearWithPrefix,
} from '../../utils/constant'
import { gt, ternary } from '../../utils/javascript'
import { launchpadFormPath } from '../formPath.description'
import { actions } from '../table/action.description'
import { options } from '../table/options.description'

export const title = 'Data Import'

export const columns = [
  {
    name: 'id',
    label: 'ID',
    ...options({ filter: false, display: false, isConvert: false }),
  },
  {
    name: 'uuid',
    label: 'UUID',
    ...options({ filter: false, display: false }),
  },

  {
    name: 'importStart',
    label: 'Import Created',
    ...options({ customRender: true, hasFilterOptions: true }),
  },
  {
    name: 'importFinish',
    label: 'Successful Import',
    ...options({ customRender: true, hasFilterOptions: true }),
  },
  {
    name: 'importStatus',
    label: 'Import Status',
    filterName: 'import status is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'progressLog',
    label: 'Progress Message',
    showSteps: true,
    ...options({
      sort: false,
      filter: false,
      customRender: true,
      isConvert: false,
      needSteps: true,
    }),
  },
  {
    name: 'importStep',
    label: 'Import Step',
    ...options({
      display: false,
      filter: false,
      customRender: true,
      customBodyRender: (value) =>
        uploadSteps[ternary(gt(value, MIN_VALUE), value - 1, value)],
    }),
  },
  {
    name: 'createdByUser',
    label: 'Imported By',
    filterName: 'Imported By',
    ...options({
      filter: false,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      customBodyRender: (value) =>
        `${value?.firstName ? value?.firstName : ''} ${value?.lastName}`,
    }),
  },
  { ...actions },
]

export const integrationColumns = [
  {
    name: 'id',
    label: 'ID',
    ...options({ filter: false, display: false, isConvert: false }),
  },
  {
    name: 'uuid',
    label: 'UUID',
    ...options({ filter: false, display: false }),
  },

  {
    name: 'importStart',
    label: 'Import Created',
    ...options({ customRender: true, hasFilterOptions: true }),
  },
  {
    name: 'importFinish',
    label: 'Successful Import',
    ...options({ customRender: true, hasFilterOptions: true }),
  },
  {
    name: 'importStatus',
    label: 'Import Status',
    filterName: 'import status is',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
]

export const dCols = JSON.stringify([...columns])

export const fileFormatList = {
  file_format: [{ label: 'bookingcompilation', value: 'bookingcompilation' }],
}

export const launchpadPath = {
  parent: launchpadFormPath,
  child: launchpadFormPath,
}

export const removeElements = ['fileUpload1', 'fileUpload2', 'fileUpload3']

export const formAttributes = {
  row1: {
    groupTitle: 'Details',
    rowFormMd: 6,
    formPath: launchpadPath,
    formAttributes: {
      name: {
        label: 'Name *',
        type: 'text',
        isRequired: true,
        fullWidth,
        error: ['Name is not valid'],
        pattern: notEmptyOrNull,
      },
      description: {
        label: 'Description',
        type: 'text',
        isRequired: false,
        fullWidth,
        error: ['Description is not valid'],
        pattern: notEmptyOrNull,
      },
      financial_year: {
        label: 'Financial Year e.g. FY[YYYY] *',
        type: 'text',
        isRequired: true,
        fullWidth,
        pattern: yearWithPrefix,
        error: ['Financial Year is required', 'Financial Year is not valid'],
      },
      file_format: {
        type: 'select',
        fullWidth,
        value: 'bookingcompilation',
        isRequired: true,
        error: false,
        pattern: notEmptyOrNull,
        label: 'File format *',
        parentPath: launchpadFormPath,
        selectArray: 'fileFormat',
        disabled: true,
      },
    },
  },
}

export const formAttributes2 = {
  row2: {
    groupTitle: 'Attachments',
    rowFormMd: 6,
    formPath: launchpadPath,
    formAttributes: {
      fileUpload1: {
        label: 'Work Opportunity *',
        type: 'file',
        isRequired: true,
        error: ['File is required'],
        pattern: notEmptyOrNull,
        accept,
        downloadIcon: true,
        fileName: 'bookings',
      },
      fileUpload2: {
        label: 'Talents *',
        type: 'file',
        isRequired: true,
        error: ['File is required'],
        pattern: notEmptyOrNull,
        accept,
        downloadIcon: true,
        fileName: 'employees',
      },
    },
  },
}
export const downloadTitle = 'Download error logs'

export const form = [
  {
    type: 'row',
    fields: [formAttributes, formAttributes2],
  },
]
export const uploadSteps = [
  'Load data from sources',
  'Combine into single dataset',
  'Validation of dataset (1/2)',
  'Convert dataset',
  'Validation of dataset (2/2)',
]

export const mockData = {
  employee: {
    title: 'Total Talents',
    total: 'employeeTotal',
    keys: ['employeeRegular', 'employeeDummy', 'employeeGeneric'],
    subTitle: ['regular', 'placeholder', 'generic'],
    description:
      'Total count of talents added and changes from previous upload',
    background: '#ffe0b2',
    changes: ['positives', 'negatives'],
    changesData: [10, -6],
    icon: <TalentIcon />,
    bodyText: 'No talents available',
    md: 4,
  },
  job: {
    title: 'Total Work Opportunities',
    total: 'jobTotal',
    subTitle: ['engagements', 'jobManager'],
    keys: ['jobEngagements', 'jobManager'],
    description:
      'Total number of work opportunities and changes from previous upload',
    background: '#c8e6c9',
    changes: ['positives', 'negatives'],
    changesData: [2, -3],
    icon: <WorkOpportunity />,
    bodyText: 'No opportunities available',
  },
  additionalFlags: {
    title: 'Additional Flags',
    total: 'addTotal',
    subTitle: ['coreTeam', 'excluded'],
    keys: ['addCoreteam', 'addExcluded'],
    description:
      'Total number of additional flags and changes from previous upload',
    background: '#E6E6E6',
    changes: ['positives', 'negatives'],
    changesData: [5, -7],
    icon: <FlagIcon />,
    bodyText: 'No additional flags available',
  },
}

export const modalKeys = [
  'importStart',
  'importFinish',
  'importStatus',
  'createdByUser',
]

export const TALENT = 'TALENT'

export const WORKOPPORTUNITY = 'WORKOPPORTUNITY'
