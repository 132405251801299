import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

export const useStyles = makeStyles(() => ({
  mainTooltip: {
    boxShadow: '2px 2px 20px 2px rgb(181 176 176);',
    padding: [[6, 10]],
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 500,
  },
  arrowLight: {
    fontSize: 14,
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: 'white',
  },
}))

const RPCTooltip = ({
  children,
  title,
  classes,
  options,
  arrow = true,
  style,
  lightTooltip = false,
}) => {
  const classesForLight = useStyles()
  return lightTooltip ? (
    <Tooltip
      {...{
        classes: {
          ...{
            tooltip: classesForLight.mainTooltip,
            arrow: classesForLight.arrowLight,
          },
          ...classes,
        },
        title: title || '',
        arrow,
        ...options,
        style,
      }}
    >
      {children}
    </Tooltip>
  ) : (
    <Tooltip {...{ classes, title: title || '', arrow, ...options, style }}>
      {children}
    </Tooltip>
  )
}

export default React.memo(RPCTooltip)
