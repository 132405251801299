import React from 'react'

import { adminSettingsContainer } from '../../../container/admin/adminSettings.container'
import Tabs from '../../../shared/blocks/Tabs'
import { settingsTitle } from '../../../shared/description/pageTitle.description'
import PageTitle from '../../../shared/PageTitle'
import MuiCommonTable from '../../../shared/Setting/MuiCommonTable'
import kpisStyle from '../../kpis/css/kpis.style'

const AdminSettings = () => {
  const { rows, isLoading } = adminSettingsContainer()

  const classes = kpisStyle()

  return (
    <>
      <PageTitle title={settingsTitle} />
      <Tabs
        {...{
          active: 'defaults',
          tabs: [{ label: 'Defaults', value: 'defaults' }],
        }}
      />
      <MuiCommonTable
        {...{
          rows,
          classes,
          isLoading,
        }}
      />
    </>
  )
}

export default React.memo(AdminSettings)
