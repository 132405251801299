import './index.css'

import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import store from './redux'
import reportWebVitals from './reportWebVitals'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import themes from './themes'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={themes.default}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// serviceWorkerRegistration.register()
