import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import { ReactComponent as Failed } from '../../assets/svg/failed.svg'

const useStyles = makeStyles(() => ({
  iconStyle: {
    width: `20px !important`,
    height: `20px !important`,
  },
}))
const FailedIcon = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <Failed className={classNames(classes.iconStyle, className)} {...rest} />
  )
}

export default FailedIcon
