import { AppBar, Toolbar } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useStyles from '../css/header.style'
import Header from './Header'
import HeaderProfile from './HeaderProfile'

const Index = () => {
  const classes = useStyles()
  const { hasGroup } = useSelector((state) => state.app)
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {useMemo(
          () => (
            <Header {...{ classes, hasGroup }} />
          ),
          [],
        )}
        {useMemo(
          () => (
            <HeaderProfile {...{ classes, hasGroup }} />
          ),
          [],
        )}
      </Toolbar>
    </AppBar>
  )
}

export default React.memo(Index)
