import moment from 'moment'

import { changeKey } from '../description/analysisDetailedSection.description'
import {
  ASC_SORT,
  BOOLEAN,
  MIN_ONE,
  MIN_VALUE,
  n,
  NUMBER,
  OBJECT,
  RETURN_NEGATIVE,
  RETURN_POSITIVE,
  STRING,
  y,
} from './constant'
import { notEmpty } from './regex'
import { minusSymbol } from './symbols'

export const ternary = (bool, truthy, falsy) => (bool ? truthy : falsy)

export const equal = (obj1, obj2 = 0) => obj1 === obj2

export const head = (obj) => obj?.[0]

export const last = (obj = []) => obj[length(obj) - 1]

export const length = (obj) => obj?.length

export const entries = (obj) => (obj ? Object.entries(obj) : [])

export const values = (object) => (object ? Object.values(object) : [])

export const keys = (object) => (object ? Object.keys(object) : [])

export const isEmpty = (value) => {
  if (typeOf(value, STRING) && isEmptyString(value)) return true
  if (typeOf(value, OBJECT) && equal(length(keys(value)))) return true
  return false
}

export const isEmptyString = (value) =>
  equal(value, '') || checkUndefined(value) || equal(value, null)

export const lowerCase = (value) => value?.toLowerCase()

export const spaceBetween = (value) =>
  value?.replace(/([A-Z|0-9|_])/g, ' $1').trim()

export const getDate = (value, locale = 'en-US', use12Hours = true) => {
  const format = use12Hours ? 'YYYY-MM-DD hh:mm a' : 'YYYY-MM-DD HH:mm'
  return moment.parseZone(value).local(false).locale(locale).format(format)
}

export const isArray = (obj) => Array.isArray(obj)

export const checkVal = (val) => val ?? ''

export const getName = (firstName, lastName) => {
  const name = `${checkVal(firstName)} ${checkVal(lastName)}`
  if (notEmpty(name)) return spaceBetween(name)
  return minusSymbol
}

export const isObjectEmptyOrFlagsUndefined = (project) => {
  // Check if project is empty
  if (!project || Object.keys(project).length === 0) {
    return true
  }

  // Check if all flag values are undefined
  return !Object.keys(project).some((key) => project[key] !== undefined)
}

export const checkUndefined = (obj) => obj === undefined

export const isBool = (value) => typeOf(value, BOOLEAN)

export const notNull = (value) => !equal(value, null)

export const checkIncludes = (value, array) => array.includes(value)

export const modifyNumber = (value) => {
  const number = value?.toString().split('.')
  if (number) {
    if (gte(length(head(number)), 5)) {
      number[0] = head(number).replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }
    if (number[1] && gte(length(number[1]), 5)) {
      number[1] = number[1].replace(/(\d{3})/g, '$1,')
    }
  }
  return number?.join('.')
}
export const generateColors = (n) => {
  const colors = []
  for (let i = 0; i < n; i += 1) {
    const hue = Math.round((360 * i) / n)
    colors.push(`hsl(${hue}, 100%, 50%)`)
  }
  return colors
}
export const capitalizeString = (val) =>
  upperCase(val?.charAt(0)) + lowerCase(val?.slice(1))

export const capitalizeStringWithSpaces = (val) => {
  if (!val) return val // Return unchanged if the input is falsy

  const words = val.split(' ') // Split the input into words
  const capitalizedWords = words.map(
    (word) => upperCase(word.charAt(0)) + lowerCase(word.slice(1)),
  )

  return capitalizedWords.join(' ') // Join the capitalized words back into a string
}

export const replaceChar = (val) => val.replace(/_/g, ' ')

export const getBoolean = (val) => ternary(val, y, n)

export const numberLocale = (num, locale = 'en-US') =>
  Number(num)?.toLocaleString(locale)

export const getMissingNumberArray = (betWeenVal) => {
  const missing = [betWeenVal[0]]
  for (let i = betWeenVal[0]; i <= betWeenVal[1]; i += 1) {
    if (equal(indexOf(betWeenVal, i), -1)) {
      missing.push(i)
    }
  }
  return [...missing, betWeenVal[1]]
}

export const numberArray = (array) => !array.some(isNaN)

export const multiFilter = (array, filters) => {
  const filterKeys = keys(filters)
  return array?.filter((item) =>
    filterKeys?.every((key) => {
      if (!filters[key]?.length) return true
      if (getKeyType(filters[key]) && gt(length(filters[key]), MIN_ONE))
        return filters[key].reduce(
          (cur, prev) => item[key] <= prev && gte(item[key], cur) && item[key],
        )
      return filters[key]?.includes(item[key])
    }),
  )
}
const getKeyType = (array) => {
  const isValidNumber = array.every((element) => typeOf(element, NUMBER))
  if (isValidNumber) return isValidNumber
  if (!isValidNumber) {
    return array.every((element) => {
      const date = new Date(element)
      return date instanceof Date && !Number.isNaN(date.valueOf())
    })
  }
  return false
}

export const handleName = (value, length = 100) => {
  const ary = []
  for (let i = 0; i < value?.length; i + 1) {
    ary.push(value.substr(i, length).trim())
    i += length
  }
  return ary
}

export const getOriginalValueForTimeFTE = {
  '100%': [100],
  '<100% - 80%': [80, 99],
  '<80% - 50%': [50, 79],
  '<50%': [0, 49],
}
export const getTimeFTE = {
  100: '100%',
  '<100% - 80%': [80, 99],
  '<80% - 50%': [50, 79],
  '<50%': [0, 49],
}

export const getSkeletonsLength = (array = []) =>
  length(array) -
  length(
    array
      ?.map(
        ({ options }) =>
          !options.display &&
          !checkUndefined(options.display) &&
          !options.display,
      )
      ?.filter(Boolean),
  )

export const renameKeys = (obj, newKeys) => {
  const keyValues = keys(obj).map((key) => {
    const newKey = changeKey[key] || newKeys[key] || key
    return { [newKey]: obj[key] }
  })
  return Object.assign({}, ...keyValues)
}

export const getMinDate = (arr) =>
  getDate(moment.min(arr?.map((dt) => moment(dt))), null, 'YYYY-MM-DD')

export const getMaxDate = (arr) =>
  getDate(moment.max(arr?.map((dt) => moment(dt))), null, 'YYYY-MM-DD')

export const findTotal = (key, data) =>
  data?.reduce((n, { [key]: totalVal }) => n + totalVal, 0)

export const findUniqueFromArrayObject = (array, key) => [
  ...new Map(array?.map((item) => [item[key], item])).values(),
]

export const findAndReplaceById = (array, replacement) => {
  const index = array.findIndex((item) => equal(item.id, replacement.id))
  if (~index) {
    array[index] = replacement
  }

  return array
}

export const typeOf = (val, type) => equal(typeof val, type)

export const gt = (param1, param2 = 0) => param1 > param2

export const lt = (param1, param2 = 0) => param1 < param2

export const gte = (param1, param2 = 0) => param1 >= param2

export const lte = (param1, param2 = 0) => param1 <= param2

export const upperCase = (value) => value?.toUpperCase()

export const checkPercentageValue = (value) =>
  value && gte(Number(value), 0) && lte(Number(value), 100)

export const indexOf = (string, val) => string?.indexOf(val)

export const getFractionalNumber = (number, fixWith = 2) =>
  ternary(
    !typeOf(number, STRING),
    +ternary(
      !equal(+number % MIN_ONE, MIN_VALUE),
      number?.toFixed(fixWith),
      number,
    ),
    number,
  )

export const returnValue = (val, key) =>
  ternary(typeOf(val, OBJECT), val?.[key], val)

export const takeSubString = (string, from, to) => string?.substr(from, to)

export const getCurrentDate = (format) => moment().format(format)

export const minMaxDateValidation = (value, min, max) =>
  moment(min).valueOf() <= moment(value?.min).valueOf() &&
  moment(max).valueOf() >= moment(value?.max).valueOf()
export const customMinMaxDateValidation = (min, max) =>
  moment(min).valueOf() <= moment(max).valueOf() &&
  moment(max).valueOf() >= moment(min).valueOf()
export const minMaxDateValid = (name, value) =>
  ternary(
    equal(name, 'min'),
    moment(value?.[name]).isBetween(
      moment(value?.max, 'YYYY-MM-DD')
        .subtract(366, 'days')
        .format('YYYY-MM-DD'),
      value?.max,
    ),
    moment(value?.[name]).isBetween(
      value?.min,
      moment(value?.min, 'YYYY-MM-DD').add(366, 'days').format('YYYY-MM-DD'),
    ),
  )

export const truthySome = (param) =>
  isArray(param) ? param?.some((val) => val) : values(param)?.some((val) => val)

export const truthyEvery = (param) =>
  isArray(param)
    ? param?.some((val) => val)
    : values(param)?.every((val) => val)

export const spliceData = (arr, start = MIN_VALUE, end = MIN_ONE) =>
  arr.splice(start, end)

export const sortArrayOfObject = (arr, key, sortDirection = ASC_SORT) =>
  arr?.sort((a, b) =>
    ternary(
      gt(lowerCase(a?.[key]), lowerCase(b?.[key])),
      ternary(equal(sortDirection, ASC_SORT), RETURN_POSITIVE, RETURN_NEGATIVE),
      ternary(
        gt(lowerCase(b?.[key]), lowerCase(a?.[key])),
        ternary(
          equal(sortDirection, ASC_SORT),
          RETURN_NEGATIVE,
          RETURN_POSITIVE,
        ),
        0,
      ),
    ),
  )

export const allFalse = (obj) =>
  Object.values(obj).every((val) => val === false)
export const filterObject = (obj, unneededKey) =>
  Object.keys(obj)
    .filter((key) => key !== unneededKey)
    .reduce((acc, key) => {
      acc[key] = [obj[key]]
      return acc
    }, {})

export const getEmptyValue = (datatype) => {
  switch (datatype) {
    case 'array':
      return []
    case 'object':
      return {}
    case 'string':
      return ''
    case 'number':
      return 0
    case 'boolean':
      return false
    case 'function':
      return () => {}
    default:
      return null // Return null for unknown datatypes
  }
}

export const compareObjects = (obj1 = {}, obj2 = {}) => {
  const keys1 = Object?.keys(obj1)
  const keys2 = Object?.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }
  keys1?.sort()
  keys2?.sort()

  if (JSON.stringify(keys1) !== JSON.stringify(keys2)) {
    return false
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

export const filteredObject = (obj = {}) => {
  const finalObj = {}
  const res = Object?.keys(obj)?.filter(
    (key) => obj[key] !== null && obj[key] !== undefined && obj[key] !== '',
  )

  res.forEach((ele) => {
    finalObj[ele] = obj[ele]
  })

  return finalObj
}

export const getDecimalPart = (num) => {
  if (Number.isInteger(num)) {
    return 0
  }

  const decimalStr = `0.${num?.toString()?.split('.')[1]}`
  return Number(decimalStr)
}

export const customRoundForHeatmap = (number) => {
  const floor = Math.floor(Number(number))
  const decimal = getDecimalPart(Number(number))

  if (decimal === 0) {
    return floor
  }

  const roundedDecimal = Number(Math.ceil(decimal * 10) / 10) // Round up only the decimal part

  return floor + roundedDecimal
}

export const getFallbackDateRange = () => {
  const startOfWeek = moment().startOf('isoweek')
  const endOfWeek = startOfWeek.clone().add(1, 'years')

  return {
    start: startOfWeek?.format('YYYY-MM-DD'),
    end: endOfWeek?.format('YYYY-MM-DD'),
  }
}

export const calculatePercentage = (value, total) => {
  if (typeof value !== 'number' || typeof total !== 'number' || total === 0) {
    return null
  }
  const percentage = (value / total) * 100
  return percentage
}

export const addZeroIfNeeded = (number) => {
  // Check if the number is less than 10
  if (number < 10) {
    // If yes, add a zero before the number and return it as a string
    return `0${number}`
  }
  // If not, return the number as it is
  return number
}

export const convertToSupScript = (dateString) => {
  const dateParts = dateString.split(' ')
  const day = dateParts[0].slice(0, -2)
  return (
    <>
      {getDayWithSuffix(day)}
      {dateParts.slice(1).join(' ')}
    </>
  )
}

export const getDayWithSuffix = (day) => {
  let suffix = 'th'
  if (day.endsWith('1') && !day.endsWith('11')) {
    suffix = 'st'
  }
  if (day.endsWith('2') && !day.endsWith('12')) {
    suffix = 'nd'
  }
  if (day.endsWith('3') && !day.endsWith('13')) {
    suffix = 'rd'
  }
  return (
    <span>
      {addZeroIfNeeded(day)}
      <sup
        style={{
          color: '#000000',
          padding: 0,
          border: 0,
          margin: 0,
          marginRight: 4,
          fontSize: 12,
        }}
      >
        {suffix}
      </sup>
    </span>
  )
}

export const checkIntegration = (integration = 'NONE') => integration !== 'NONE'

export const sortedData = ({ data, primaryKey, secondaryKey }) =>
  data.sort((a, b) => {
    // Primary sort (case-insensitive)
    if (a?.[primaryKey]?.toLowerCase() > b?.[primaryKey]?.toLowerCase())
      return 1
    if (a?.[primaryKey]?.toLowerCase() < b?.[primaryKey]?.toLowerCase())
      return -1

    // Secondary sort (case-insensitive)
    if (secondaryKey) {
      if (a?.[secondaryKey]?.toLowerCase() > b?.[secondaryKey]?.toLowerCase())
        return 1
      if (a?.[secondaryKey]?.toLowerCase() < b?.[secondaryKey]?.toLowerCase())
        return -1
    }

    return 0
  })

export const getDeviceType = () => {
  if (navigator?.userAgentData) {
    const brands = navigator.userAgentData.brands || []
    const { mobile } = navigator.userAgentData

    if (mobile) {
      const isAndroid = brands?.some((brand) => /android/i.test(brand?.brand))
      if (isAndroid) {
        return 'ANDROID'
      }
      const isIOS = brands?.some((brand) => /ios/i.test(brand?.brand))
      if (isIOS) {
        return 'IOS'
      }
      return 'MOBILE'
    }
    return 'WEB'
  }

  // Fallback to userAgent if userAgentData is not supported
  const userAgent = navigator?.userAgent || window?.opera
  if (/android/i.test(userAgent)) {
    return 'ANDROID'
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window?.MSStream) {
    return 'IOS'
  }
  return 'WEB'
}
