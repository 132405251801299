import { useState } from 'react'

import useToggle from '../../hooks/use-toggle'
import { ACCEPTANCE_STATE_ENUM } from '../../utils/constant'
import { indexOf } from '../../utils/javascript'

export const suggestionHeaderContainer = (onChange) => {
  const GREEN = '#96fb9f'
  const RED = '#dc0f14'
  const GREY = 'rgba(160, 160, 160, 1)'
  const mapRowValueToPosition = (suggestion = null) =>
    indexOf(ACCEPTANCE_STATE_ENUM, suggestion)
  const [open, setConfirm] = useToggle(false)
  const [modalActionIndex, setModalActionIndex] = useState()
  const openConfirmModal = (value) => {
    setModalActionIndex(value)
    setConfirm()
  }

  const handleConfirmClick = () => {
    setConfirm()
    onChange(modalActionIndex)
  }
  const colors = [RED, GREY, GREEN]

  return {
    mapRowValueToPosition,
    colors,
    open,
    setConfirm,
    openConfirmModal,
    handleConfirmClick,
    modalActionIndex,
  }
}
