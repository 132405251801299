import React from 'react'

import { uid } from '../../utils/constant'
import RPCTab from '../RPCTab'
import RPCTabs from '../RPCTabs'

const Tabs = ({
  children,
  active,
  tabs,
  changeActiveTab = () => {},
  style,
}) => (
  <RPCTabs
    style={style}
    value={active}
    indicatorColor="primary"
    textColor="primary"
    onChange={(e, value) => {
      changeActiveTab(value)
    }}
  >
    {tabs.map((obj) => (
      <RPCTab
        style={style}
        label={obj.label}
        value={obj.value}
        key={uid()}
        tabColor={obj?.tabColor}
      />
    ))}
    {children && children}
  </RPCTabs>
)

export default React.memo(Tabs)
