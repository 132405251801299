import { notEmptyOrNull } from '../utils/constant'
import { settingsPath } from './formPath.description'

export const languageFilterList = {
  locale: [
    { label: 'English', value: 'en-US' },
    { label: 'Germany', value: 'de-DE' },
    { label: 'Swiss German', value: 'de-CH' },
    { label: 'Austrian German', value: 'de-AT' },
  ],
}

export const formPath = {
  parent: 'settings',
  child: 'locale',
}

export const defaultValue = 'en-US'

export const dropDownFormAttributes = {
  default: {
    formPath: {
      parent: settingsPath,
      child: settingsPath,
    },
    formAttributes: {
      locale: {
        type: 'select',
        isRequired: false,
        fullWidth: true,
        value: defaultValue,
        label: '',
        error: false,
        pattern: notEmptyOrNull,
        disabled: true,
        fromLocalStorage: true,
        storageParam: 'locale',
      },
    },
  },
}

export const form = [
  {
    type: null,
    fields: [dropDownFormAttributes],
  },
]

export const buttonAttributes = (
  handleClick,
  handleSubmit,
  disabled,
  classes,
  errors = '',
) => [
  {
    variant: 'contained',
    color: 'primary',
    size: 'large',
    value: 'Edit',
    type: 'button',
    disableElevation: true,
    onClick: handleClick,
    className: classes.btnEdit,
    disabled: !disabled,
  },
  {
    variant: 'contained',
    color: 'primary',
    size: 'large',
    value: 'Save',
    type: 'button',
    disableElevation: true,
    onClick: handleSubmit,
    disabled: disabled || errors,
    className: classes.btnSave,
  },
]
