import { Chip, Menu } from '@material-ui/core'
import classNames from 'classnames'
import React, { useState } from 'react'

import { palette } from '../../description/labels/label.description'
import { useStyles } from '../../presentation/datasets/css/label.style'
import { uid } from '../../utils/constant'
import RPCButton from '../RPCButton'

const RPCColorPicker = ({
  formPath,
  color,
  onChangeComplete,
  name,
  id = 'default',
  label,
  className,
  forceDisable = false,
}) => {
  const classes = useStyles()
  const [toggleOpen, setToggleOpen] = useState(null)

  const chipDetail = (palette) =>
    palette?.map(({ bgColor, color }) => (
      <span
        className={classes.chipStyle}
        key={uid()}
        data-test-id={`${bgColor}`}
      >
        <Chip
          className={classes.chipSize}
          style={{
            background: bgColor,
            color,
          }}
          onClick={() => {
            onChangeComplete({
              path: formPath,
              name,
              color: bgColor,
              id,
            })
            setToggleOpen(null)
          }}
          label="e"
        />
      </span>
    ))
  return (
    <span className={classNames(classes?.colorPicker, className)}>
      <label htmlFor={name}>{label}</label>
      <RPCButton
        onClick={(e) =>
          !forceDisable && setToggleOpen({ [id]: e.currentTarget })
        }
        style={{ backgroundColor: color }}
        className={classNames(classes.colorButtonStyle, classes.chipSize)}
      />

      <Menu
        anchorEl={toggleOpen?.[id]}
        keepMounted
        open={Boolean(toggleOpen?.[id])}
        onClose={() => setToggleOpen(null)}
        className={classes.marginTop}
        classes={{
          paper: classes.colorPickerMenu,
        }}
      >
        {chipDetail(palette)}
      </Menu>
    </span>
  )
}
export default React.memo(RPCColorPicker)
