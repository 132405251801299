import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { userSettingsPath } from '../../description/settings.description'
import { useDispatch } from '../../hooks/use-dispatch'
import { useRouter } from '../../hooks/use-router'
import useToggle from '../../hooks/use-toggle'
import useStyles from '../../presentation/layout/css/sidebar.style'
import sidebarLinkStyles from '../../presentation/layout/css/sidebarLink.style'
import { getApiDataAction } from '../../redux/actions/api.action'
import {
  manageSideBarMenuAction,
  sidebarOpenedAction,
} from '../../redux/actions/app.action'
import { optimizationSettings } from '../../redux/description'
import { CLOSE_NAVBAR_WIDTH } from '../../utils/constant'
import { checkIntegration, equal, indexOf } from '../../utils/javascript'

export const sidebarContainer = (
  link,
  manageSideBarMenu = {},
  children,
  isActive,
  compClick = null,
  isMainSidebar = true,
) => {
  const classes = useStyles()
  const sidebarMenuClasses = sidebarLinkStyles()
  const { dispatch } = useDispatch()
  const { name, value } = manageSideBarMenu
  const { sidebarOpened } = useSelector((state) => state.app)
  const userSettingsData = useSelector(
    (state) => state?.api?.settings?.optimizationSettings,
  )
  const isIntegration = checkIntegration(userSettingsData?.integrationType)

  const changeSidebarOpened = () => dispatch(sidebarOpenedAction())

  const { pathname } = useRouter()
  const activeChildLink = children?.map((d) => d.link).includes(pathname)
  const [isOpen, toggleCollapse] = useToggle(activeChildLink || false)

  const isLinkActive =
    (link && (equal(pathname, link) || !equal(indexOf(pathname, link), -1))) ||
    isActive
  const showActiveClass =
    link === '/work-opportunities' && pathname?.includes('/job-details')
  const isMobile = window.innerWidth <= CLOSE_NAVBAR_WIDTH

  const onClick = () => {
    toggleCollapse()
    if (name && !compClick) {
      dispatch(manageSideBarMenuAction({ name, value }))
    }

    if (compClick) {
      compClick(name)
    }

    if (!children?.length && isMobile && isMainSidebar) {
      dispatch(sidebarOpenedAction())
    }
  }

  useEffect(() => {
    const apiParams = {
      formPath: userSettingsPath,
      apiDetails: optimizationSettings,
      loaderFormPath: userSettingsPath,
      needFormPath: true,
    }

    dispatch(getApiDataAction(apiParams))
  }, [])

  return {
    classes,
    isPermanent: true,
    sidebarOpened,
    changeSidebarOpened,
    isLinkActive,
    activeChildLink,
    onClick,
    isOpen,
    sidebarMenuClasses,
    showActiveClass,
    isIntegration,
  }
}
