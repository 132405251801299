export const urlSearchParams = (searchParam) => ({
  searchValue: searchValue(searchParam),
  pathName: pathname,
  search,
})

export const { search } = window.location

export const searchValue = (searchParam) =>
  new URLSearchParams(search).get(searchParam)

export const { pathname } = window.location

export const reloadFn = () => window.location.reload()

export const encode = (param) => window.btoa(param)

export const decode = (param) => window.atob(param)

export const windowOpen = (url, target = '_blank') => window.open(url, target)

export const minScreenWidth = 1024

export const encodeWithComp = (param) => encodeURIComponent(param)

export const decodeWithComp = (param) => decodeURIComponent(param)
