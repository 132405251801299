import { IconButton } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import { BASE_EXPORT_OPTIONS } from '../../utils/constant'
import { exportButtonContainer } from '../container/exportButton.container'
import useStyles from '../css/exportButton.style'
import RPCTooltip from '../elements/RPCTooltip'
import DownloadIcon from '../icons/DownloadIcon'
import RPCLoader from '../RPCLoader'
import RPCMenu from '../RPCMenu'
import RPCMenuItem from '../RPCMenuItem'

const ExportButton = ({
  downloadLoaderPath,
  active,
  uuid,
  endPoint,
  filename,
  exportBtnDisable,
  exportBtnTooltip,
  showDropdown,
  exportOptions = BASE_EXPORT_OPTIONS,
  showToast,
  acceptJson,
}) => {
  const classes = useStyles()
  const { isOpen, toggle, handleDownload, downloadLoading } =
    exportButtonContainer({
      downloadLoaderPath,
      active,
      uuid,
      endPoint,
      filename,
      showDropdown,
    })
  return (
    <>
      <RPCTooltip title={(exportBtnDisable && exportBtnTooltip) || 'Export'}>
        <span>
          <IconButton
            onClick={(e) => toggle(e.currentTarget)}
            disabled={exportBtnDisable}
          >
            <DownloadIcon className={classes.exportBtn} />
          </IconButton>
        </span>
      </RPCTooltip>
      <RPCMenu
        id="download"
        open={Boolean(isOpen)}
        anchorEl={isOpen}
        onClose={() => toggle(null)}
        className={classNames(classes.exportDropdown)}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {exportOptions.map(({ key, value, exportBy = '' }, ind) => (
          <RPCMenuItem
            value={key}
            key={ind}
            onClick={() => handleDownload(key, exportBy, showToast, acceptJson)}
          >
            <span>{value}</span>

            {downloadLoading?.[key] && (
              <RPCLoader
                className={classes.setloader}
                isCenterLoader={false}
                color="primary"
                size={15}
                isBtnLoader={false}
              />
            )}
          </RPCMenuItem>
        ))}
      </RPCMenu>
    </>
  )
}
export default ExportButton
