import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import RPCTooltip from '../elements/RPCTooltip'
import InfoIcon from '../icons/InfoIcon'
import RPCButton from '../RPCButton'
import RPCTableCell from '../RPCTableCell'

const useStyle = makeStyles(() => ({
  customTableHeader: {
    top: 0,
    zIndex: 100,
    position: 'sticky',
    backgroundColor: '#fff',
    '& button': {
      textTransform: 'capitalize',
    },
  },
}))

const TableHeaderWithTooltip = ({
  index,
  sortColumn,
  label,
  tooltipTitle,
  sort,
}) => {
  const classes = useStyle()
  return (
    <RPCTableCell key={index} className={classNames(classes.customTableHeader)}>
      <RPCButton
        {...{ ...(sort && { onClick: () => sortColumn(index) }) }}
        applyClass={false}
      >
        <span>{label}</span>
        <RPCTooltip title={tooltipTitle}>
          <span>
            <InfoIcon
              style={{
                width: 15,
                height: 15,
                marginLeft: 4,
                verticalAlign: 'middle',
              }}
            />
          </span>
        </RPCTooltip>
      </RPCButton>
    </RPCTableCell>
  )
}

export default TableHeaderWithTooltip
