import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import { ReactComponent as Download } from '../../assets/svg/download.svg'

const useStyles = makeStyles(() => ({
  iconStyle: {
    width: 22,
    height: 22,
  },
}))
const DownloadIcon = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <Download
      className={classNames(classes.iconStyle, className)}
      {...{ ...rest }}
    />
  )
}

export default DownloadIcon
