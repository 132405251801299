import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { memo } from 'react'

import RPCButton from '../shared/RPCButton'
import RPCTypography from '../shared/RPCTypography'
import { MIN_VALUE } from '../utils/constant'
import { gt, length } from '../utils/javascript'

const useStyles = makeStyles(() => ({
  notFound: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 172px)',
    '& a': {
      display: 'block',
    },
    '& p': {
      marginBottom: 10,
    },
  },
  centerNotFound: {
    justifyContent: 'center',
  },
  fontBold: {
    fontWeight: 'bold',
  },
}))

const NotFound = ({
  history,
  auth,
  redirectTo = '',
  needButton = true,
  notFoundMessage = 'Page Not Found',
  subMessage = [],
  fontBold = false,
}) => {
  const classes = useStyles()
  return (
    <div
      className={classNames(classes.notFound, {
        [classes.centerNotFound]: !auth,
      })}
    >
      <RPCTypography
        variant="body1"
        component="p"
        className={classNames({ [classes.fontBold]: fontBold })}
      >
        {notFoundMessage}
      </RPCTypography>
      {gt(length(subMessage), MIN_VALUE) &&
        subMessage?.map((val) => (
          <RPCTypography variant="body1" component="p">
            {val}
          </RPCTypography>
        ))}
      {needButton && (
        <RPCButton
          onClick={() => history?.replace(redirectTo || '/dashboard/kpis')}
          variant="contained"
          color="primary"
        >
          Go back
        </RPCButton>
      )}
    </div>
  )
}

export default memo(NotFound)
