import { IconButton, makeStyles } from '@material-ui/core'
import React from 'react'

import { footerData } from '../../description/footer.description'
import RPCLink from '../../shared/RPCLink'
import RPCTypography from '../../shared/RPCTypography'
import { copyRight } from '../../utils/constant'

const useStyles = makeStyles(() => ({
  socialIcon: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      '& svg': {
        width: 30,
        height: 30,
        color: '#53585f',
        '& .s0': {
          fill: '#53585f',
        },
      },
      '&:hover': {
        '& svg': {
          color: '#ec691e',
          '& .s0': {
            fill: '#ec691e',
          },
        },
      },
    },
  },
}))

const Footer = ({ classes, token }) => {
  const extraClass = useStyles()

  return (
    <>
      <RPCTypography
        color="secondary"
        className={classes.copyright}
        style={{ fontSize: '0.8rem' }}
      >
        {copyRight()}
        <RPCLink
          href="https://www.aspaara.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          aspaara AG
        </RPCLink>
        . All rights reserved.
      </RPCTypography>
      {token && (
        <div className={extraClass.socialIcon}>
          {footerData.map((val, ind) => (
            <RPCLink href={val?.link} key={ind} target="_blank">
              <IconButton aria-label={val?.name}>{val?.icon}</IconButton>
            </RPCLink>
          ))}
        </div>
      )}
    </>
  )
}

export default React.memo(Footer)
