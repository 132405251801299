import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import { ReactComponent as Plus } from '../../assets/svg/plusIcon.svg'

const useStyles = makeStyles(() => ({
  defaultWidthHeight: {
    height: 23,
    width: 23,
  },
}))

const PlusIcon = ({ className, ...rest }) => {
  const classes = useStyles()
  return (
    <Plus
      className={classNames(className, classes.defaultWidthHeight)}
      {...rest}
    />
  )
}
export default PlusIcon
