import { Box, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import { layoutContainer } from '../../container/layout.container'
import Routes from '../../routing'
import { matomoContainer } from '../../shared/container/matomo.container'
import Scrollbar from '../../shared/Scrollbar'
import Footer from '../login/Footer'
import {
  COOKIE_CONSENT_ESSENTIAL_BUTTON_TEXT,
  COOKIE_CONSENT_EXPIRATION_TIMEOUT,
  COOKIE_CONSENT_KEY,
  COOKIE_CONSENT_NON_ESSENTIAL_BUTTON_TEXT,
  COOKIE_CONSENT_TEXT_ESSENTIAL_DESCRIPTION,
  COOKIE_CONSENT_TEXT_ESSENTIAL_TITLE,
  COOKIE_CONSENT_TEXT_INTRO,
  COOKIE_CONSENT_TEXT_OPTIONAL_DESCRIPTION,
  COOKIE_CONSENT_TEXT_OPTIONAL_TITLE,
  COOKIE_CONSENT_TEXT_TYPES,
} from '../login/login.description'
import useStyles from './css/layout.style'
import Header from './Header'
import Sidebar from './Sidebar'
const Layout = () => {
  const classes = useStyles()
  const { sidebarOpened, isSessionChecked, isAuth, hasGroup } =
    layoutContainer()
  const location = useLocation()

  matomoContainer({ location })
  const CookieInfo = () => {
    const useStyles = makeStyles((theme) => ({
      root: {
        padding: theme.spacing(2),
        maxHeight: '400px', // Set a fixed height for the container
        overflow: 'auto', // Enable scrolling if content exceeds the height
      },
      essential: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
      },
      optional: {
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
      },
      description: {
        marginTop: theme.spacing(1),
      },
      consentText: {
        fontStyle: 'italic',
        color: '#FFD700',
        marginBottom: theme.spacing(2),
      },
      cookieConsentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
      buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    }))
    const classes = useStyles()
    const [showMore, setShowMore] = useState(false)
    return (
      <div className={classes.root}>
        <p>{COOKIE_CONSENT_TEXT_INTRO}</p>
        <button type="button" onClick={() => setShowMore(!showMore)}>
          {!showMore ? 'Show more' : 'Collapse'}
        </button>
        {showMore && (
          <div>
            <p className={classes.consentText}>{COOKIE_CONSENT_TEXT_TYPES}</p>
            <p className={classes.essential}>
              {COOKIE_CONSENT_TEXT_ESSENTIAL_TITLE}
            </p>
            <p className={classes.description}>
              {COOKIE_CONSENT_TEXT_ESSENTIAL_DESCRIPTION}
            </p>
            <p className={classes.essential}>
              {COOKIE_CONSENT_TEXT_OPTIONAL_TITLE}
            </p>
            <p className={classes.description}>
              {COOKIE_CONSENT_TEXT_OPTIONAL_DESCRIPTION}
            </p>
          </div>
        )}
      </div>
    )
  }
  return (
    isSessionChecked && (
      <>
        {isAuth && (
          <>
            <Header />
            {hasGroup && <Sidebar />}
          </>
        )}
        <div className={classes.mainContent}>
          <Scrollbar>
            <div
              className={classNames(classes.content, {
                [classes.contentShift]: sidebarOpened,
              })}
            >
              <div className={classes.fakeToolbar} />

              <Routes {...{ isAuth }} />
              <div className={classes.cookieConsentContainer}>
                <CookieConsent
                  flipButtons
                  cookieName={COOKIE_CONSENT_KEY}
                  expires={COOKIE_CONSENT_EXPIRATION_TIMEOUT}
                  enableDeclineButton
                  declineButtonText={COOKIE_CONSENT_ESSENTIAL_BUTTON_TEXT}
                  buttonText={COOKIE_CONSENT_NON_ESSENTIAL_BUTTON_TEXT}
                  buttonStyle={classes.buttonStyle}
                  buttonWrapperClasses={classes.buttonContainer}
                  containerClasses={classes.buttonContainer}
                  contentClasses={classes.buttonContainer}
                  customContainerAttributes={{
                    lineHeight: 1,
                    padding: 50,
                  }}
                  declineButtonStyle={{
                    backgroundColor: 'grey',
                  }}
                  overlay
                >
                  <CookieInfo />
                </CookieConsent>
              </div>
              {isAuth && (
                <>
                  <div className={classes.vfill} />
                  <Box
                    mt={5}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Footer {...{ classes, token: isAuth }} />
                  </Box>
                </>
              )}
            </div>
          </Scrollbar>
        </div>
      </>
    )
  )
}
export default React.memo(Layout)
